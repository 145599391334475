export const normalizeSms = (codeCountry, nroCellphone, code) => {
    return {
        TipoEnvio: 'T',
        CodigoCiudad: codeCountry,
        OrigenEnvio: 'micuenta.losportales.com.pe',
        NumeroCelular: nroCellphone,
        Mensaje: `Hola, te enviamos el PIN para ingresar a Mi Cuenta Los Portales: ${code}`
    }
}

export const normalizeData = (data) => {
    const { codeOne, codeTwo, codeThree } = data
    return parseInt(`${codeOne}${codeTwo}${codeThree}`)
}