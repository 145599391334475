import { takeLatest } from 'redux-saga/effects'

import {
  TRANSACTION_ORDER,
  fetchTransaction
} from '../modules/order/actions'

export default function* orderWatcherSaga() {
  yield [
    yield takeLatest(TRANSACTION_ORDER, fetchTransaction)
  ]
}