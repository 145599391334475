import React from 'react'
import styled from 'styled-components'

interface IProps {
    label: string
    type: 'button' | 'submit' | 'reset'
    disabled?: boolean
    className?: string
}

const Component = styled.button`
    width: 100%;
    height: 48px;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    border: none;
    background: #782cbf;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    &:disabled {
        background: #E6EAEC;
        color: #82949E;
        border: 1px solid #82949E;
        border-radius: 4px;
    }
`

const Button = ({
    type,
    label,
    disabled,
    className
}: IProps) => {
    return (
        <Component
            type={type}
            disabled={disabled}
            className={className}
        >
            {label}
        </Component>
    )
}

export default Button