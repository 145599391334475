import React from 'react'
import styled from 'styled-components'

import { mediaQuery as media } from '../../utils/mediaQuery'

import visa from '../../assets/images/svg/visa.svg'
import mastercard from '../../assets/images/svg/mastercard.svg'
import amex from '../../assets/images/svg/amex.svg'
import diners from '../../assets/images/svg/diners.svg'

interface IProps {
    open: boolean
    onClose: () => void
    onClick: () => void
    cardType: string
    cardNumber: string
}

const ModalOverlay = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 9;
    background: rgba(54, 57, 61, .7);
`

const ModalContainer = styled.div`
    width: 100%;
    max-width: 318px;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 16px;
    box-sizing: border-box;
    ${media.md`
        max-width: 636px;
        padding: 40px 36px;
    `}
`

const ModalHeader = styled.div``

const Title = styled.div`
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #36393D;
    ${media.md`
        font-size: 24px;
        line-height: 32px;
    `}
`

const ModalBody = styled.div`
    margin-top: 16px;
`

const Paragraph = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #36393D;
    ${media.md`
        font-size: 16px;
        line-height: 24px;
    `}
`

const CardContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 16px;
    ${media.md`
        margin-top: 42px;
    `}
`

const ModalFooter = styled.div`
    margin-top: 24px;
    text-align: right;
    ${media.md`
        margin-top: 40px;
    `}
`

const IconContainer = styled.div`
    display: flex;
`

const Icon = styled.img`
    height: 36px;
    border: 2px solid #e0e0e0;
    border-radius: 5px;
    ${media.md`
        height: 59px;
    `}
`

const NumberContainer = styled.div`
    margin-left: 24px;
`

const Number = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #36393D;
    text-transform: capitalize;
`

const Desaffiliate = styled.button`
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    background: #C200AE;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #C200AE;
    padding: 10px 20px;
    cursor: pointer;
    margin-left: 16px;
    ${media.md`
        margin-left: 24px;
    `}
`

const Cancel = styled.button`
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: #36393D;
    background: #FFFFFF;
    border: 1px solid #36393D;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
`

const Modal = ({
    open,
    onClose,
    onClick,
    cardType,
    cardNumber
}: IProps) => {
    return (
        <React.Fragment>
            {open && (
                <ModalOverlay>
                    <ModalContainer>
                        <ModalHeader>
                            <Title>¿Desafiliar tarjeta al débito automático?</Title>
                        </ModalHeader>
                        <ModalBody>
                            <Paragraph>Si quitas la afiliación al débito automático, ya no se podrá cobrar automáticamente a menos que vuelvas a añadirlo.</Paragraph>
                            <CardContainer>
                                <IconContainer>
                                    <Icon src={
                                        cardType === 'visa' ? visa :
                                            cardType === 'mastercard' ? mastercard :
                                                cardType === 'diners' ? diners :
                                                    amex
                                    } />
                                </IconContainer>
                                <NumberContainer>
                                    <Number>{cardType} **** {cardNumber?.slice(-4)}</Number>
                                </NumberContainer>
                            </CardContainer>
                        </ModalBody>
                        <ModalFooter>
                            <Cancel onClick={onClose}>Cancelar</Cancel>
                            <Desaffiliate onClick={onClick}>Desafiliarme</Desaffiliate>
                        </ModalFooter>
                    </ModalContainer>
                </ModalOverlay>
            )}
        </React.Fragment>
    )
}

export default Modal