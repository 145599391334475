import moment from 'moment'

export const normalizeDebt = (list, idProforma) => {
    return list
        .filter(x => x.pROFORMAField === idProforma && x.eSTADOLETRAField !== 'PAGADA')
        .map(x => x.iMPORTEField)
        .reduce((prev, next) => prev + next, 0);
}

export const normalizeExpiration = (list, idProforma) => {
    return list
        .filter(x => x.pROFORMAField === idProforma && x.eSTADOLETRAField !== 'PAGADA')
        .filter(x => {
            const actual = moment(Date.now())
            const expiration = moment(x.fECHAVENCIMIENTOField)
            return actual.diff(expiration, 'days') > 0
        }).length
}

export const normalizeFilename = (constancia, cliente, proforma) => {
    return `${getRecordName(constancia)}_${cliente}_${proforma}.pdf`.toLocaleLowerCase().replaceAll(' ', '_')
}

const getRecordName = (constancia) => {
    switch (constancia) {
        case '01':
            return 'Constancia no adeudo'
        case '02':
            return 'Constancia de estar al dia'
        case '03':
            return 'Constancia letras canceladas'
        case '04':
            return 'Constancia letras anuladas'
        default:
            return ''
    }
}