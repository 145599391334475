import { takeLatest } from 'redux-saga/effects'

import {
  PROJECT_DEBIT,
  fetchProject
} from '../modules/debit/actions'

export default function* debitWatcherSaga() {
  yield [
    yield takeLatest(PROJECT_DEBIT, fetchProject)
  ]
}