import React from 'react'
import PropTypes from 'prop-types'
import Page from '../../../containers/Debit/InternalPage'

const Debit = () => (
    <React.Fragment>
        <Page />
    </React.Fragment>
)

Debit.propTypes = {
    children: PropTypes.node
}

export default Debit