import { upperCase } from 'lodash'
import moment from 'moment'

const normalizeName = (list) => {
    const header = list
        .map(item => `${item.pROYECTOField} | ${item.pRODUCTOField}`)
        .filter((item, idx, array) => array.indexOf(item) === idx)
    return header
}

export const normalizePending = (list) => {
    const projects = normalizeName(list)
    const header = projects.map(item => {
        return {
            name: item,
            symbol: list[0].mONEDAField,
            amount: list
                .filter(x => `${x.pROYECTOField} | ${x.pRODUCTOField}` === item)
                .map(x => x.iMPORTEField)
                .reduce((prev, next) => prev + next),
            items: list
                .filter(x => `${x.pROYECTOField} | ${x.pRODUCTOField}` === item)
        }
    })
    return header
}

export const normalizeCompleted = (list) => {
    const projects = normalizeName(list)
    const header = projects.map(item => {
        return {
            name: item,
            type: list[0].bLARTField,
            symbol: list[0].mONEDAField,
            amount: list
                .filter(x => `${x.pROYECTOField} | ${x.pRODUCTOField}` === item)
                .map(x => x.ImportePago)
                .reduce((prev, next) => prev + next),
            items: list
                .filter(x => `${x.pROYECTOField} | ${x.pRODUCTOField}` === item)
        }
    })
    return header
}

export const normalizeProject = (list, ubications) => {
    const { t_PRODUCTOSField, t_ESTACCField } = list
    const projects = t_PRODUCTOSField.map(item => {
        return {
            code: item.cOD_PROYECTOField,
            direction: item.pRODUCTOField,
            image: `https://prduncstoragemicuenta.blob.core.windows.net/micuenta/vivienda/proyectos/imagenes/micuenta-${item.cOD_PROYECTOField}.jpg`,
            name: item.pROYECTOField.toUpperCase(),
            money: item.wAERSField,
            proform: item.pROFORMAField,
            location: normalizeDirection(ubications, item),
            ...normalizeLetter(t_ESTACCField, item)
        }
    })
    return projects
}

const normalizeDirection = (list, item) => {
    const filter = list
        .find(x => x.CodProyecto === item.cOD_PROYECTOField)

        if (!filter) {
        return '-'
    }

    return `${filter.Distrito.toLowerCase()} / ${filter.Provincia.toLowerCase()}, ${filter.Departamento.toLowerCase()}`
}

const normalizeLetter = (list, item) => {
    const filter = list
        .filter(x => x.pROFORMAField === item.pROFORMAField)
    
    const pendingList = filter
        .filter(x => x.eSTADOLETRAField === 'PENDIENTE DE PAGO')
    
    const overdue = pendingList
        .filter(x => {
            const actual = moment(Date.now())
            const expiration = moment(x.fECHAVENCIMIENTOField)
            return actual.diff(expiration, 'days') > 0
        })

    const completedList = filter
        .filter(x => x.eSTADOLETRAField === 'PAGADA')

    const partialAmount = pendingList
        .map(x => x.iMPORTEField)
        .reduce((prev, next) => prev + next, 0)

    const totalAmount = list
        .map(x => x.iMPORTEField)
        .reduce((prev, next) => prev + next, 0)

    const data = {
        overdue: overdue.length,
        partialAmount: partialAmount,
        partialLetter: completedList.length,
        totalAmount: totalAmount,
        totalLetter: filter.length,
        type: overdue.length > 0 ? 'deuda' : 
            pendingList.length > 0 ? 'pagando' : 'completado' 
    }
    
    return data
}