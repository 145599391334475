import React from 'react'
import NumberFormat from 'react-number-format'
import styled from 'styled-components'

import { mediaQuery as media } from '../../../utils/mediaQuery'

interface IProps {
    proyect: string
}

const HeaderContainer = styled.div`
    padding-right: 25px;
    cursor: pointer;
    ${media.sm`
        display: flex;
        align-items: center;
        justify-content: space-between;
    `}
`

const InfoContainer = styled.div`
    ${media.sm`
        display: flex;
        align-items: center;
    `}
`

const ProyectContainer = styled.div`
    padding-right: 8px;
    ${media.sm`
        display: flex;
        align-items: center;
    `}
`

const Proyect = styled.div`
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
`

const AmountContainer = styled.div`
    margin-top: 8px;
    ${media.sm`
        margin-top: 0px;
    `}
`

const Amount = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #36393D;
    ${media.sm`
        font-size: 16px;
        line-height: 24px;
    `}
`

const Header = ({
    proyect
}: IProps) => {
    return (
        <HeaderContainer>
            <InfoContainer>
                <ProyectContainer>
                    <Proyect>{proyect}</Proyect>
                </ProyectContainer>
            </InfoContainer>
        </HeaderContainer>
    )
}

export default Header