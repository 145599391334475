import React from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import KRGlue from '@lyracom/embedded-form-glue'
import styled from 'styled-components'

import { RootState } from '../../../store/modules'

import { fetchProject } from '../../../store/modules/debit/actions'
import { fetchHideLoading, fetchShowLoading } from '../../../store/modules/layout/actions'

import useMedia from '../../../hooks/useMedia'
import { mediaQuery as media } from '../../../utils/mediaQuery'
import { normalizeIzipay, normalizeAffiliation } from '../../../utils/schemas/debit'
import { URL_STATIC, APIKEY_ADOLARES, APIKEY_ASOLES } from '../../../utils/constants/debit'

import { izipayServices } from '../../../services/izipayServices'
import { projectServices } from '../../../services/projectServices'

const PaymentWrapper = styled.div``

const PaymentContainer = styled.div`
    width: 100%;
    max-width: fit-content;
    margin: 0px auto;
    box-sizing: border-box;
    padding: 40px 16px 0px;
`

const TitleContainer = styled.div`
    padding-bottom: 24px;
`

const Title = styled.div`
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #36393D;
    text-align: center;
    ${media.md`
        font-size: 24px;
        line-height: 32px;
    `}
`

const ButtonContainer = styled.div``

const Button = styled.button`
    width: 100%;
    border: 1px solid #36393D;
    border-radius: 4px;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #36393D;
    padding: 12px 0px;
    margin-top: 16px;
    cursor: pointer;
`

const DetailContainer = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 8px 24px rgba(40, 79, 171, 0.1);
    border-radius: 8px;
    padding: 12px 12px 32px;
    ${media.md`
        padding: 32px 24px 44px;
    `}
`

const FormContainer = styled.div`
    & .kr-payment-button {
        border: none;
        border: 1px solid #CC26BA !important;
        background: #CC26BA !important;
        border-radius: 4px;
        padding: 12px 0px;
        & span {
            width: 100%;
            font-family: 'Nunito', sans-serif !important;
            font-style: normal;
            font-weight: 800;
            font-size: 16px;
            line-height: 24px;
            color: #FFFFFF;
            cursor: pointer;
            text-transform: capitalize;
        }
    }
`

const PaymentPage = () => {
    const history = useNavigate()
    const dispatch = useDispatch()
    const { isMobile } = useMedia()
    const debit = useSelector((state: RootState) => state.debit)

    React.useEffect(() => {
        dispatch(fetchShowLoading())
        izipayServices.generateAffiliate(normalizeIzipay(debit, isMobile)).then(response => {
            const { answer } = response.data
            const { formToken } = answer
            const apikey = debit.codMoneda === 'USD' ? APIKEY_ADOLARES : APIKEY_ASOLES
            initializeForm(formToken, apikey)
            dispatch(fetchHideLoading())
        }).catch(_ => {
            dispatch(fetchHideLoading())
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const initializeForm = async (formToken, apikey) => {
        await KRGlue.loadLibrary(URL_STATIC, apikey)
            .then(({ KR }) => KR.setFormConfig({
                formToken: formToken
            }))
            .then(({ KR }) => KR.addForm('#myPaymentForm'))
            .then(({ KR, result }) => KR.showForm(result.formId))
            .then(({ KR }) => {
                return KR.onFormReady(() => {
                    dispatch(fetchHideLoading())
                });
            })
            .then(({ KR }) => {
                return KR.onSubmit((response: any) => {
                    if (response.clientAnswer?.orderStatus === 'PAID') {
                        const data = {
                            estado: true,
                            tarjetaRegistrada: response.clientAnswer?.transactions[0]?.transactionDetails.cardDetails.pan,
                            token: response.clientAnswer?.transactions[0]?.paymentMethodToken,
                            fechaRegistro: response.clientAnswer.transactions[0]?.creationDate,
                        }
                        dispatch(fetchShowLoading())
                        projectServices.postAffiliation(normalizeAffiliation({...debit, ...data}, isMobile)).then(response => {
                            dispatch(fetchProject(data))
                            dispatch(fetchHideLoading())
                            history('/success/debit')
                        }).catch(_ => {
                            dispatch(fetchHideLoading())
                        })
                    }
                });
            })
            .catch(_ => dispatch(fetchHideLoading()))
    }

    const handleGoBack = () => {
        history(-1)
    }

    return (
        <React.Fragment>
            <Helmet>
                <link type='text/css' rel='stylesheet' href='https://static.micuentaweb.pe/static/js/krypton-client/V4.0/ext/classic-reset.css' />
                <script src='https://static.micuentaweb.pe/static/js/krypton-client/V4.0/ext/classic.js' />
            </Helmet>
            <PaymentWrapper>
                <PaymentContainer>
                    <TitleContainer>
                        <Title>Afiliación</Title>
                    </TitleContainer>
                    <DetailContainer>
                        <FormContainer id="myPaymentForm" />
                        <ButtonContainer>
                            <Button onClick={handleGoBack}>Regresar</Button>
                        </ButtonContainer>
                    </DetailContainer>
                </PaymentContainer>
            </PaymentWrapper>
        </React.Fragment>
    )
}

export default PaymentPage