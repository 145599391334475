export const PROJECT_HOME_REQUESTED = 'vivienda/login/PROJECT_HOME_REQUESTED'
export const PRODUCT_HOME_REQUESTED = 'vivienda/login/PRODUCT_HOME_REQUESTED'

export function fetchProject(params) {
    return {
        type: PROJECT_HOME_REQUESTED,
        payload: params
    }
}

export function fetchProduct(params) {
    return {
        type: PRODUCT_HOME_REQUESTED,
        payload: params
    }
}