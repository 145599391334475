import React from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { RootState } from '../../store/modules'
import { fetchHideLoading, fetchShowLoading } from '../../store/modules/layout/actions'

import Gallery from '../../components/Gallery'
import Empty from '../../components/Empty'
import Pin from '../../components/Map'

import { API_KEY } from '../../utils/constants/payment'
import { mediaQuery as media } from '../../utils/mediaQuery'
import { normalizeGallery, normalizeLocation } from '../../utils/schemas/admin'

import { projectServices } from '../../services/projectServices'

import waze from '../../assets/images/bt-waze.png'
import gmap from '../../assets/images/bt-map.png'
import wallet from '../../assets/images/svg/ic-wallet.svg'
import arrowDown from '../../assets/images/svg/ic-arrow-down-purple.svg'
import arrowUp from '../../assets/images/svg/ic-arrow-up-white.svg'
import map from '../../assets/images/svg/ic-map-purple.svg'
import mapWhite from '../../assets/images/svg/ic-map-white.svg'
import pin from '../../assets/images/svg/ic-pin-purple.svg'
import pinWhite from '../../assets/images/svg/ic-pin-white.svg'
import print from '../../assets/images/svg/ic-print.svg'
import printWhite from '../../assets/images/svg/ic-print-white.svg'

const InfoContainer = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 8px 24px rgba(42, 55, 67, 0.12);
    border-radius: 8px;
    padding: 24px;
    margin-top: 20px;
    ${media.md`
        margin-top: 0px;
    `}
`

const ProyectContainer = styled.div`
    ${media.sm`
        display: flex;
        align-items: center;
    `}
    ${media.md`
        margin-top: 0px;
    `}
`

const NameContainer = styled.div`
    ${media.sm`
        margin-top: 0px;
        border-right: 1px solid #284FAE;
        padding-right: 16px;
    `}
`

const Name = styled.h2`
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    color: #284FAE;
    margin: 0px;
    text-transform: capitalize;
`

const DirectionContainer = styled.div`
    ${media.sm`
        margin-left: 16px;
    `}
`

const Direction = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #284FAE;
    margin: 4px 0px 0px;
`

const OptionContainer = styled.div`
    margin-top: 12px;
    overflow: auto;
    white-space: nowrap;
`

const OptionWrapper = styled.div``

const ImageContainer = styled.div<{
    active: boolean
}>`
    padding: 10px 32px 10px 8px;
    background: ${props => props.active ? '#772CBD' : '#F1EAF8'};
    border: 1px solid ${props => props.active ? '#772CBD' : '#A06BD2'};
    box-sizing: border-box;
    border-radius: 4px;
    display: inline-block;
    position: relative;
    &:after {
        content: '';
        background: ${props => props.active ? `url(${arrowUp})` : `url(${arrowDown})`};
        width: 16px;
        height: 16px;
        position: absolute;
        top: calc(50% - 8px);
        right: 8px;
    }
`

const Image = styled.div<{
    active: boolean
}>`
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${props => props.active ? '#FFFFFF' : '#A06BD2'};
    & img {
        margin-right: 10px;
    }
`

const MapContainer = styled.div<{
    active: boolean
}>`
    padding: 10px 32px 10px 8px;
    background: ${props => props.active ? '#772CBD' : '#F1EAF8'};
    border: 1px solid ${props => props.active ? '#772CBD' : '#A06BD2'};
    box-sizing: border-box;
    border-radius: 4px;
    display: inline-block;
    margin-left: 24px;
    position: relative;
    &:after {
        content: '';
        background: ${props => props.active ? `url(${arrowUp})` : `url(${arrowDown})`};
        width: 16px;
        height: 16px;
        position: absolute;
        top: calc(50% - 8px);
        right: 8px;
    }
`

const Map = styled.div<{
    active: boolean
}>`
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${props => props.active ? '#FFFFFF' : '#A06BD2'};
    & img {
        margin-right: 10px;
    }
`

const GoogleContainer = styled.div<{
    active: boolean
}>`
    padding: 10px 32px 10px 8px;
    background: ${props => props.active ? '#772CBD' : '#F1EAF8'};
    border: 1px solid ${props => props.active ? '#772CBD' : '#A06BD2'};
    box-sizing: border-box;
    border-radius: 4px;
    display: inline-block;
    margin-left: 24px;
    position: relative;
    &:after {
        content: '';
        background: ${props => props.active ? `url(${arrowUp})` : `url(${arrowDown})`};
        width: 16px;
        height: 16px;
        position: absolute;
        top: calc(50% - 8px);
        right: 8px;
    }
`

const Google = styled.div<{
    active: boolean
}>`
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${props => props.active ? '#FFFFFF' : '#A06BD2'};
    & img {
        margin-right: 10px;
    }
`

const NavigateContainer = styled.div`
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center;
    ${media.sm`
        & > a:last-child {
            margin-left: 10px;
        }
    `}
`

const Icon = styled.img``

const AdvanceWrapper = styled.div``

const AdvanceContainer = styled.div`
    background: #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 8px 24px rgba(42, 55, 67, 0.12);
    border-radius: 8px;
    padding: 19px 12px;
    margin-top: 20px;
    ${media.md`
        padding: 24px;
        margin-top: 40px;
    `}
`

const AdvancePage = () => {
    const { id } = useParams()

    const dispatch = useDispatch()
    const product = useSelector((state: RootState) => state.product)
    const tipoDocumento = useSelector((state: RootState) => state.user.tipoDocumento)
    const nroDocumento = useSelector((state: RootState) => state.user.nroDocumento)
    const productList = useSelector((state: RootState) => state.home.productList)

    const [showPrint, setShowPrint] = React.useState(false)
    const [photos, setPhotos] = React.useState<any>([])

    const [showMap, setShowMap] = React.useState(false)
    const [maps, setMaps] = React.useState(null)

    const [showPin, setShowPin] = React.useState(false)
    const [location, setLocation] = React.useState<any>(null)

    const [project, setProject] = React.useState<any>(null)
    const [advance, setAdvance] = React.useState<any>(null)

    React.useEffect(() => {
        const item = productList.find(x => x.pROFORMAField === id)
        setProject(item)
        setMaps(product.Map)
        setPhotos(normalizeGallery(product.GalleryItems?.split(';')))
        setLocation(normalizeLocation(product.GoogleMap))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        dispatch(fetchShowLoading())
        const item = productList.find(x => x.pROFORMAField === id)
        projectServices.getAdvanceWork(tipoDocumento, nroDocumento, item.cOD_PROYECTOField).then(response => {
            setAdvance(response.data)
            dispatch(fetchHideLoading())
        }).catch(_ => {
            dispatch(fetchHideLoading())
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handlePrint = () => {
        if (photos.length) {
            setShowMap(false)
            setShowPin(false)
            setShowPrint(!showPrint)
        }
    }

    const handleMap = () => {
        if (maps) {
            setShowPin(false)
            setShowPrint(false)
            setShowMap(!showMap)
        }
    }

    const handlePin = () => {
        if (location) {
            setShowPrint(false)
            setShowMap(false)
            setShowPin(!showPin)
        }
    }

    return (
        <React.Fragment>
            <InfoContainer>
                <ProyectContainer>
                    <NameContainer>
                        <Name>{project?.pROYECTOField?.toLowerCase()}</Name>
                    </NameContainer>
                    <DirectionContainer>
                        <Direction>{project?.pRODUCTOField}</Direction>
                    </DirectionContainer>
                </ProyectContainer>
                <OptionContainer>
                    <ImageContainer active={showPrint} onClick={handlePrint} style={{ cursor: 'pointer' }}>
                        <Image active={showPrint}>
                            <Icon src={!showPrint ? print : printWhite} /> {' '} Fotos
                        </Image>
                    </ImageContainer>
                    <MapContainer active={showMap} onClick={handleMap} style={{ cursor: 'pointer' }}>
                        <Map active={showMap}>
                            <Icon src={!showMap ? map : mapWhite} /> Mapa
                        </Map>
                    </MapContainer>
                    <GoogleContainer active={showPin} onClick={handlePin} style={{ cursor: 'pointer' }}>
                        <Google active={showPin}>
                            <Icon src={!showPin ? pin : pinWhite} /> Google maps
                        </Google>
                    </GoogleContainer>
                </OptionContainer>
                <OptionWrapper>
                    {showPrint && (
                        <div style={{ width: '100%', marginTop: '20px' }}>
                            <Gallery
                                layout={[1, 2]}
                                photos={photos}
                                showRemaining={true}
                                width={["60%", "40%"]}
                                height='400px'
                            />
                        </div>
                    )}
                    {showMap && (
                        <div style={{ width: '100%', marginTop: '20px' }}>
                            <img style={{ width: '100%', borderRadius: '8px' }} alt='' src={maps ?? ''} />
                        </div>
                    )}
                    {showPin && (
                        <div style={{ width: '100%', marginTop: '20px', position: 'relative' }}>
                            <Pin
                                googleMapURL={`https://maps.googleapis.com/maps/api/js?v3.exp&key=${API_KEY}`}
                                containerElement={<div style={{ height: '400px' }} />}
                                mapElement={<div style={{ height: '100%' }} />}
                                loadingElement={<p>Cargando...</p>}
                                location={location}
                            />
                            <NavigateContainer>
                                <a rel='noreferrer' target="_blank" href={`https://www.waze.com/ul?ll=${location.lat},${location.lng}&navigate=yes&zoom=16`}>
                                    <img alt='' src={waze} />
                                </a>
                                <a rel='noreferrer' target="_blank" href={`http://www.google.com/maps/place/${location.lat},${location.lng}`}>
                                    <img alt='' src={gmap} />
                                </a>
                            </NavigateContainer>

                        </div>
                    )}
                </OptionWrapper>
            </InfoContainer>
            <AdvanceWrapper>
                <AdvanceContainer>
                    {advance ? (
                        <Gallery
                            layout={[1, 2]}
                            photos={normalizeGallery(advance.imagenesStr?.split(';'))}
                            showRemaining={true}
                            width={["60%", "40%"]}
                            height='315px'
                        />
                    ) : (
                        <Empty
                            icon={wallet}
                            text={['No tienes ningún avance de obra']}
                        />
                    )}
                </AdvanceContainer>
            </AdvanceWrapper>
        </React.Fragment>

    )
}

export default AdvancePage