import { takeLatest } from 'redux-saga/effects'

import {
  UBICATION_LOCATION,
  fetchUbication
} from '../modules/location/actions'

export default function* locationWatcherSaga() {
  yield [
    yield takeLatest(UBICATION_LOCATION, fetchUbication)
  ]
}