import React from 'react'
import PropTypes from 'prop-types'
import Letter from '../../containers/Checkout/LetterPage'
import Cash from '../../containers/Checkout/CashPage'

const Checkout = () => {
    const login = window.localStorage.getItem('login')
    return (
        <React.Fragment>
            {login === 'cash' ? <Cash /> : <Letter />}
        </React.Fragment>
    )
}

Checkout.propTypes = {
    children: PropTypes.node
}

export default Checkout