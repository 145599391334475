import axios from 'axios'

export const API_BASE_URL = process.env.REACT_APP_URL_MICUENTA_INGRESO

console.log(API_BASE_URL)

// API Config
const apiLogin = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
})

export default apiLogin