import React from 'react'
import { useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { RootState } from '../../store/modules'

import { fetchHideLoading, fetchShowLoading } from '../../store/modules/layout/actions'

import { mediaQuery as media } from '../../utils/mediaQuery'
import { normalizeDebt, normalizeExpiration, normalizeFilename } from '../../utils/schemas/record'

import record from '../../assets/json/record.json'

import arrow from '../../assets/images/arrow-down-blue.png'

import { projectServices } from '../../services/projectServices'

const RecordWrapper = styled.div``

const RecordContainer = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 8px 24px rgba(42, 55, 67, 0.12);
    border-radius: 8px;
    padding: 19px;
    ${media.md`
        padding: 24px 24px 52px;
    `}
`

const HeaderContainer = styled.div``

const TitleContainer = styled.div``

const Title = styled.div`
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 26px;
    color: #284FAE;
    ${media.md`
        font-size: 24px;
        line-height: 32px;
    `}
`

const BodyWrapper = styled.div`
    margin-top: 15px;
    ${media.md`
        background: #FFFFFF;
        box-shadow: 0px 8px 24px rgba(42, 55, 67, 0.12);
        border-radius: 8px;
        margin-top: 24px;
        padding: 10px 41px 18px;
    `}
`

const BodyContainer = styled.div`
    min-height: 395px;
`

const InputGroupContainer = styled.div`
    margin-top: 10px;
    ${media.md`
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 17px;
    `}
`

const LabelContainer = styled.div`
    width: 100%;
    max-width: 239px;
    margin-bottom: 12px;
    ${media.md`
        margin-bottom: 0px;
        margin-right: 15px;
    `}
`

const Label = styled.label`
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #3D61B3;
`

const DropdownContainer = styled.div`
    width: 100%;
`

const Dropdown = styled.select`
    width: 100%;
    height: 55px;
    border: 1px solid #284FAE;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 15px 46px 15px 12px;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #284FAE;
    position: relative;
    appearance: none;
    cursor: pointer;
    background: url(${arrow}) no-repeat;
    background-position: calc(100% - 15px) center;
    &:focus {
        outline: none;
    }
`

const Option = styled.option``

const ResultContainer = styled.div`
    margin-top: 20px;
    ${media.md`
        margin-top: 33px;
    `}
`

const ProjectContainer = styled.div``

const Project = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #284FAE;
`

const InputContainer = styled.div`
    width: 100%;
`

const Money = styled(NumberFormat)`
    width: 100%;
    height: 55px;
    display: block;
    border: 1px solid #284FAE;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 15px 12px;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #284FAE;
    position: relative;
    &:focus {
        outline: none;
    }
`

const MessageContainer = styled.div`
    width: 100%;
    text-align: left;
`

const Message = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    color: #284FAE;
`

const Paragraph = styled.p`
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    margin: 10px 0px;
`

const ButtonContainer = styled.div`
    text-align: center;
    margin-top: 20px;
    ${media.md`
        margin-top: 35px;
    `}
`

const Button = styled.button`
    width: 100%;
    height: 48px;
    background: #CC26BA;
    border: 1px solid #CC26BA;
    border-radius: 4px;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    cursor: pointer;
    &:disabled {
        color: #82949E;
        background: #E6EAEC;
        border: 1px solid #82949E;
        cursor:
    }
    &:focus {
        outline: none;
    }
    ${media.md`
        max-width: 364px;
    `}
`

const RecordPage = () => {
    const dispatch = useDispatch()
    const tipoDocumento = useSelector((state: RootState) => state.user.tipoDocumento)
    const nroDocumento = useSelector((state: RootState) => state.user.nroDocumento)
    const projectList = useSelector((state: RootState) => state.home.projectList)
    const productList = useSelector((state: RootState) => state.home.productList)
    const user = useSelector((state: RootState) => state.user)

    const methods = useForm({
        mode: 'onChange',
        reValidateMode: 'onSubmit'
    })
    const { setValue, watch, register } = methods
    
    const [product, setProduct] = React.useState<any>(null)
    const [idProforma, setIdProforma] = React.useState('')
    const [idRecord, setIdRecord] = React.useState('')
    const [showResult, setShowResult] = React.useState(false)

    // Constancia de no adeudo
    const [amountTotal, setAmountTotal] = React.useState(0)
    // Constancia de estar al día en el pago
    const [quantityDebt, setQuantityDebt] = React.useState(0)

    React.useEffect(() => {
        const value = watch('project')
        if (value) handleProjectSelected(value)
        setShowResult(false)
        setIdProforma(value)
        setValue('record', '')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch('project')])

    React.useEffect(() => {
        handleRecordSelected(watch('record'))
        setIdRecord(watch('record'))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch('record')])

    const handleProjectSelected = (idProforma) => {
        const selected = productList.find(x => x.pROFORMAField === idProforma)
        setProduct(selected)
    }

    const handleRecordSelected = (idRecord) => {
        if (idRecord === '01') {
            const total = normalizeDebt(projectList, idProforma)
            setAmountTotal(total)
        } else if (idRecord === '02') {
            const debt = normalizeExpiration(projectList, idProforma)
            setQuantityDebt(debt)
        }
        setShowResult(idRecord)
    }

    const handleDownloadRecord = () => {
        dispatch(fetchShowLoading())
        projectServices.getRecord(tipoDocumento, nroDocumento, idProforma, idRecord, product.cOD_PROYECTOField, product.pROYECTOField, product.pRODUCTOField, user.cLIENTEField).then(response => {
            const fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}))
            const a = document.createElement('a')
            a.download = normalizeFilename(idRecord, user.cLIENTEField, idProforma);
            a.href = fileURL
            a.click()
            a.parentNode?.removeChild(a)
            dispatch(fetchHideLoading())
        }).catch(_ => {
            dispatch(fetchHideLoading())
        })
    }

    return (
        <RecordWrapper>
            <RecordContainer>
                <HeaderContainer>
                    <TitleContainer>
                        <Title>Constancias</Title>
                    </TitleContainer>
                </HeaderContainer>
                <BodyWrapper>
                    <BodyContainer>
                        <InputGroupContainer>
                            <LabelContainer>
                                <Label>Elegir proforma:</Label>
                            </LabelContainer>
                            <DropdownContainer>
                                <Dropdown
                                    {...register('project')}
                                >
                                    <Option value=''>Seleccionar</Option>
                                    {productList.map(item => (
                                        <Option value={item.pROFORMAField}>{item.pROFORMAField} - {item.pROYECTOField}</Option>
                                    ))}
                                </Dropdown>
                            </DropdownContainer>
                        </InputGroupContainer>
                        <InputGroupContainer>
                            <LabelContainer>
                                <Label>Elegir constancia:</Label>
                            </LabelContainer>
                            <DropdownContainer>
                                <Dropdown
                                    disabled={!watch('project')}
                                    {...register('record')}
                                >
                                    <Option value=''>Seleccionar</Option>
                                    {record.map(item => (
                                        <Option value={item.value}>{item.label}</Option>
                                    ))}
                                </Dropdown>
                            </DropdownContainer>
                        </InputGroupContainer>
                        {showResult && (
                            <ResultContainer>
                                <ProjectContainer>
                                    <Project>{product.pROYECTOField}</Project>
                                </ProjectContainer>
                                {idRecord === '01' && (
                                    <>
                                        <InputGroupContainer style={{ alignItems: 'flex-start' }}>
                                            <LabelContainer>
                                                <Label style={{ marginTop: '15px' }}>Saldo capital de deuda:</Label>
                                            </LabelContainer>
                                            <InputContainer>
                                                <Money
                                                    prefix={`${product.wAERSField} `}
                                                    decimalSeparator='.'
                                                    displayType='text'
                                                    type='text'
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    value={amountTotal}
                                                />
                                                <Paragraph>Para obtener la constancia, el saldo capital de deuda debe ser 0.</Paragraph>
                                            </InputContainer>
                                        </InputGroupContainer>
                                        <ButtonContainer>
                                            <Button onClick={handleDownloadRecord} disabled={amountTotal > 0}> Obtener constancia </Button>
                                        </ButtonContainer>
                                    </>
                                )}
                                {idRecord === '02' && (
                                    <>
                                        <InputGroupContainer>
                                            <LabelContainer>
                                                <Label>El status de tus pagos es:</Label>
                                            </LabelContainer>
                                            <MessageContainer>
                                                <Message>
                                                    {quantityDebt ? `¡Lo sentimos! No estás al día en tus pagos` : `¡Felicidades! Estás al día en tus pagos`}
                                                </Message>
                                            </MessageContainer>
                                        </InputGroupContainer>
                                        <ButtonContainer>
                                            <Button onClick={handleDownloadRecord} disabled={quantityDebt > 0}> Obtener constancia </Button>
                                        </ButtonContainer>
                                    </>
                                )}
                                {(idRecord === '03' || idRecord === '04') && (
                                    <ButtonContainer>
                                        <Button onClick={handleDownloadRecord}> Obtener constancia </Button>
                                    </ButtonContainer>
                                )}
                            </ResultContainer>
                        )}
                    </BodyContainer>
                </BodyWrapper>
            </RecordContainer>
        </RecordWrapper>
    )
}

export default RecordPage