import {
    TRANSACTION_ORDER
} from './actions'

const initialState = {
    numberCard: '',
    operationNumber: '',
    operationDate: '',
    montoPagado: 0
}

export function reducer(state = initialState, action: any) {
    switch(action.type) {
        case TRANSACTION_ORDER:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}