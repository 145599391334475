export const normalizeCountry = (list) => (
    list.map((item) => {
        return { label: `${item.dial_code} (${item.code})`, value: item.dial_code }
    })
)

export const getCountryCode = (list, data) => {
    const [{ address_components }] = data.results.filter(({ types }) => types.includes('country'))
    const [{ short_name }] = address_components
    return list.find((item) => item.code === short_name)
}

export const getCountryDial = (list, data) => {
    return list.find((item) => item.dial_code === data)
}