import React from 'react'
import Collapsible from 'react-collapsible'
import NumberFormat from 'react-number-format'
import { useNavigate, NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { RootState } from '../../../store/modules'
import { getCardType, normalizeDebitAutomatic, normalizeDesaffiliation } from '../../../utils/schemas/debit'
import { fetchProject } from '../../../store/modules/debit/actions'
import { fetchHideLoading, fetchShowLoading } from '../../../store/modules/layout/actions'

import Empty from '../../../components/Empty'
import Modal from '../../../components/Modal'

import useMedia from '../../../hooks/useMedia'
import { mediaQuery as media } from '../../../utils/mediaQuery'

import arrow from '../../../assets/images/svg/ic-arrow-left-blue.svg'
import cardSharp from '../../../assets/images/svg/ic-card-sharp.svg'
import wallet from '../../../assets/images/svg/ic-wallet.svg'

import { projectServices } from '../../../services/projectServices'

const DebitWrapper = styled.div``

const LinkContainer = styled.div`
    filter: drop-shadow(0px 8px 24px rgba(42, 55, 67, 0.12));
    background: #ffffff;
    padding: 15px 16px;
    ${media.lg`
        padding: 15px 140px;
    `}
`

const Link = styled(NavLink)`
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-decoration: none;
    color: #284FAE;
    &::before {
        content: '';
        width: 9px;
        height: 16px;
        margin-right: 12px;
        background: url(${arrow})
    }
`

const DebitContainer = styled.div`
    padding: 24px 16px;
    ${media.md`
        padding: 40px 16px;
    `}
    ${media.lg`
        padding: 40px 140px;
    `}
`

const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF;
    box-shadow: 0px 8px 24px rgba(42, 55, 67, 0.12);
    border-radius: 8px;
    padding: 12px;
    ${media.md`
        padding: 24px;
    `}
`

const TitleContainer = styled.div``

const Title = styled.div`
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    color: #284FAE;
`

const KnowContainer = styled.div``

const Know = styled.a`
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline;
    color: #284FAE;
`

const BodyContainer = styled.div`
    margin-top: 24px;
    background: #FFFFFF;
    box-shadow: 0px 8px 24px rgba(42, 55, 67, 0.12);
    border-radius: 8px;
    padding: 12px;
    ${media.sm`
        padding: 24px;
    `}
`

const CollapseContainer = styled.div`
    width: 100%;
    border: 1px solid #93A7D5;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px;
    &:not(:first-child) {
        margin-top: 24px;
    }
    ${media.sm`
        padding: 16px;
    `}
`

const CollapseHeaderContainer = styled.div`
    padding-right: 25px;
    cursor: pointer;
    ${media.sm`
        display: flex;
        align-items: center;
        justify-content: space-between;
    `}
`

const CollapseHeaderWrapper = styled.div`
${media.sm`
    display: flex;
    align-items: center;
`}
`

const ProyectContainer = styled.div`
    ${media.sm`
        padding-right: 16px;
    `}
`

const Proyect = styled.div`
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #284FAE;
`

const ProductContainer = styled.div`
    margin-top: 0px;
    ${media.sm`
        margin-top: 0px;
        padding-left: 16px;
        border-left: 1px solid #284FAE;
    `}
`

const Product = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #284FAE;
`

const AmountContainer = styled.div`
    margin-top: 8px;
    ${media.sm`
        margin-top: 0px;
    `}
`

const Amount = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #36393D;
    ${media.sm`
        font-size: 16px;
        line-height: 24px;
    `}
`

const AmountText = styled.span`
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
`

const CollapseBodyWrapper = styled.div`
    ${media.sm`
        border: 1px solid rgba(0, 104, 172, 0.2);
        padding: 16px 18px;
        border-radius: 8px;
        padding: 0px;
    `}
`

const CollapseBodyContainer = styled.div`
    ${media.sm`
        display: flex;
        align-items: center;
        justify-content: space-between;
    `}
`

const InfoContainer = styled.div<{
    estado: boolean
}>`
    width: 100%;
    justify-content: ${props => props.estado ? 'space-between' : 'flex-end'};
    ${media.sm`
        display: flex;
        align-items: center;
        padding: 18px 16px;
    `}
`

const CardContainer = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    ${media.sm`
        text-align: right;
        padding-bottom: 0px;
    `}
`

const IconContainer = styled.div`
    display: flex;
`

const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    ${media.sm`
        flex-direction: row;
        align-items: center;
    `}
`

const Icon = styled.img``

const CardWrapper = styled.div`
    margin-left: 8px;
`

const TextContainer = styled.div``

const Text = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;  
    color: #82949E;
    ${media.sm`
        text-align: left;
    `}
`

const NumberContainer = styled.div``

const Number = styled.div`
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: #36393D;
    ${media.sm`
        text-align: left;
    `}
`

const DetailContainer = styled.div`
    margin-bottom: 16px;
    ${media.sm`
        margin-bottom: 0px;
    `}
`

const Detail = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #36393D;
`

const ClientContainer = styled.div`
    padding-bottom: 16px;
    ${media.sm`
        padding-bottom: 0px;
    `}
`

const ClientWrapper = styled.div`
    ${media.sm`
        margin-left: 34px;
    `}
`

const NameContainer = styled.div``

const Name = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #36393D;
    text-transform: capitalize;
`

const DesaffiliationContainer = styled.div`
    display: flex;
    padding-top: 16px;
    justify-content: center;
    border-top: 1px solid #82949E;
    ${media.sm`
        padding: 18px 16px;
        border-top: none;
        border-left: 1px solid #82949E;
    `}
`

const Desaffiliation = styled.button`
    width: 100%;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: #36393D;
    padding: 10px 20px;
    border: 1px solid #36393D;
    box-sizing: border-box;
    border-radius: 4px;
    background: #FFFFFF;
    cursor: pointer;
    ${media.lg`
        width: 270px;
    `}
`

const AffiliationContainer = styled.div`
    display: flex;
    padding-top: 16px;
    justify-content: center;
    border-top: 1px solid #82949E;
    ${media.sm`
        padding: 18px 16px;
        border-top: none;
        border-left: 1px solid #82949E;
    `}
`

const Affiliation = styled.button`
    width: 100%;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    padding: 10px 20px;
    border: 1px solid #C200AE;
    box-sizing: border-box;
    border-radius: 4px;
    background: #C200AE;
    cursor: pointer;
    &:disabled {
        color: #82949E;
        cursor: not-allowed;
        background: #E6EAEC;
        border: 1px solid #82949E;
    }
    ${media.lg`
        width: 270px;
    `}
`

const ErrorContainer = styled.div`
    margin-top: 16px;
    background: #F6EEEC;
    border-radius: 2px;
    padding: 8px 16px;
    border-left: 4px solid #970C0C;
`

const ParagraphContainer = styled.div``

const Paragraph = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #36393D;
`

const DebitPage = () => {
    const history = useNavigate()
    const dispatch = useDispatch()
    const { isMobile } = useMedia()
    const tipoDocumento = useSelector((state: RootState) => state.user.tipoDocumento)
    const nroDocumento = useSelector((state: RootState) => state.user.nroDocumento)

    const [open, setOpen] = React.useState(false)
    const [affiliations, setAffiliations] = React.useState<any[]>([])

    const handleModal = (open) => {
        document.body.style.overflow = open ? 'hidden' : 'auto'
        setOpen(open)
    }

    const handleDesaffiliate = (item) => {
        dispatch(fetchShowLoading())
        projectServices.postDessafiliation(normalizeDesaffiliation(item)).then(response => {
            window.location.reload()
        }).catch(_ => {
            dispatch(fetchHideLoading())
        })
    }

    const handleDebit = (item) => {
        dispatch(fetchProject(item))
        history(`/debit/${item.proforma}/confirmation`)
    }

    React.useEffect(() => {
        dispatch(fetchShowLoading())
        projectServices.getLetter(tipoDocumento, nroDocumento).then(response => {
            const { ZWS_CONSULTA_CLIENTEResponse } = response.data
            const { t_ESTACCField } = ZWS_CONSULTA_CLIENTEResponse
            projectServices.getAffiliation(tipoDocumento, nroDocumento).then(response => {
                const affiliations = response.data
                setAffiliations(normalizeDebitAutomatic(affiliations, t_ESTACCField))
                dispatch(fetchHideLoading())
            }).catch(_ => {
                dispatch(fetchHideLoading())
            })
        }).catch(_ => {
            dispatch(fetchHideLoading())
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <React.Fragment>
            <DebitWrapper>
                <LinkContainer>
                    <Link to='/'>
                        {!isMobile && 'Volver atrás'}
                    </Link>
                </LinkContainer>
                <DebitContainer>
                    <HeaderContainer>
                        <TitleContainer>
                            <Title>Débito automático</Title>
                        </TitleContainer>
                        {!isMobile && (
                            <KnowContainer>
                                <Know target='_blank' href='https://www.losportales.com.pe/pago-automatico/'>Conocer más</Know>
                            </KnowContainer>
                        )}
                    </HeaderContainer>
                    <BodyContainer>
                        {affiliations.length ? (
                            affiliations.map(item => (
                                <CollapseContainer key={Math.random()}>
                                    <Collapsible
                                        open={true}
                                        transitionTime={200}
                                        trigger={
                                            <CollapseHeaderContainer>
                                                <CollapseHeaderWrapper>
                                                    <ProyectContainer>
                                                        <Proyect>{item.nombreProyecto}</Proyect>
                                                    </ProyectContainer>
                                                    <ProductContainer>
                                                        <Product>{item.producto}</Product>
                                                    </ProductContainer>
                                                </CollapseHeaderWrapper>
                                                <AmountContainer>
                                                    <Amount>
                                                        <AmountText>Monto mensual: {' '}</AmountText>
                                                        <NumberFormat
                                                            prefix={item.codMoneda === 'USD' ? 'US$' : 'S/'}
                                                            decimalSeparator='.'
                                                            displayType='text'
                                                            type='text'
                                                            thousandSeparator={true}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            value={item.montoCuotaAprox}
                                                        />
                                                    </Amount>
                                                </AmountContainer>
                                            </CollapseHeaderContainer>
                                        }>
                                        <React.Fragment>
                                            <CollapseBodyWrapper>
                                                <CollapseBodyContainer>
                                                    <InfoContainer estado={item.estado}>
                                                        {item.estado && (
                                                            <InfoWrapper>
                                                                <CardContainer>
                                                                    <IconContainer>
                                                                        <Icon src={cardSharp} />
                                                                    </IconContainer>
                                                                    <CardWrapper>
                                                                        <TextContainer>
                                                                            <Text>Tarjeta {getCardType(item.tarjetaRegistrada)}</Text>
                                                                        </TextContainer>
                                                                        <NumberContainer>
                                                                            <Number>**** **** **** {item.tarjetaRegistrada?.slice(-4)}</Number>
                                                                        </NumberContainer>
                                                                    </CardWrapper>
                                                                </CardContainer>
                                                                <ClientContainer>
                                                                    <ClientWrapper>
                                                                        <TextContainer>
                                                                            <Text>A nombre de:</Text>
                                                                        </TextContainer>
                                                                        <NameContainer>
                                                                            <Name>{item.nombreCliente?.toLowerCase()}</Name>
                                                                        </NameContainer>
                                                                    </ClientWrapper>
                                                                </ClientContainer>
                                                            </InfoWrapper>
                                                        )}
                                                        <DetailContainer>
                                                            <Detail>
                                                                <NumberFormat
                                                                    prefix={item.codMoneda === 'USD' ? 'US$' : 'S/'}
                                                                    decimalSeparator='.'
                                                                    displayType='text'
                                                                    type='text'
                                                                    thousandSeparator={true}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale={true}
                                                                    value={item.montoCuotaAprox}
                                                                />
                                                            </Detail>
                                                        </DetailContainer>
                                                    </InfoContainer>
                                                    {item.estado ? (
                                                        <DesaffiliationContainer>
                                                            <Desaffiliation onClick={() => handleModal(true)}>
                                                                Desafiliarme al débito automático
                                                            </Desaffiliation>
                                                        </DesaffiliationContainer>
                                                    ) : (
                                                        <AffiliationContainer>
                                                            <Affiliation onClick={() => handleDebit(item)} disabled={item.overdue > 0}>
                                                                Afiliarme al débito automático
                                                            </Affiliation>
                                                        </AffiliationContainer>
                                                    )}
                                                </CollapseBodyContainer>
                                            </CollapseBodyWrapper>
                                            {(item.overdue > 0) && (
                                                <ErrorContainer>
                                                    <ParagraphContainer>
                                                        <Paragraph>No puedes realizar la afiliación porque cuentas con {item.overdue} letra{`${item.overdue > 1 ? 's' : ''}`} vencida{`${item.overdue > 1 ? 's' : ''}`}. Paga tu letra vencida para que puedas afiliar tu tarjeta
                                                            al débito automático</Paragraph>
                                                    </ParagraphContainer>
                                                </ErrorContainer>
                                            )}
                                            {item.estado && (
                                                <Modal
                                                    open={open}
                                                    onClose={() => handleModal(false)}
                                                    onClick={() => handleDesaffiliate(item)}
                                                    cardType={getCardType(item.tarjetaRegistrada)}
                                                    cardNumber={item.tarjetaRegistrada}
                                                />
                                            )}
                                        </React.Fragment>
                                    </Collapsible>
                                </CollapseContainer>
                            ))
                        ) : (
                            <Empty
                                icon={wallet}
                                text={['No tienes ningún proyecto para afiliar al débito automático']}
                            />
                        )}
                    </BodyContainer>
                </DebitContainer>
            </DebitWrapper>
        </React.Fragment>
    )
}

export default DebitPage