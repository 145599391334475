import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { mediaQuery as media } from '../../utils/mediaQuery'
import { Outlet } from 'react-router-dom'

const LayoutWrapper = styled.div`
    background: #C200AE;
`

const LayoutContainer = styled.div`
    padding-top: 34px;
    padding-bottom: 24px;
    ${media.md`
        padding-top: 64px;
        padding-bottom: 32px;
    `}
`

const StepperWrapper = styled.div`
    width: 100%;
    max-width: 520px;
    margin: 0px auto;
    margin-top: auto;
    display: flex;
    justify-content: space-between;
`

const StepperItem = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    &:not(:first-child)::before {
        content: '';
        position: absolute;
        border-bottom: 2px solid #EDB2E7;
        width: 100%;
        top: 35px;
        left: -50%;
        z-index: 2;
    }
`

const StepperName = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 4px;
`

const StepperCounter = styled.div`
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #C200AE;
    border: 2px solid #EDB2E7;
    margin-bottom: 6px;
    &.active {
        background: #F6A41A;
        border: 2px solid #F6A41A;
    }
`

const LayoutBody = styled.div``

type IProps = {
    children?: React.ReactNode
}

const DebitLayout = ({ children }: IProps) => {
    return (
        <React.Fragment>
            <LayoutWrapper>
                <LayoutContainer>
                    <StepperWrapper>
                        <StepperItem>
                            <StepperName>Confirma</StepperName>
                            <StepperCounter className='active' />
                        </StepperItem>
                        <StepperItem>
                            <StepperName>Afiliación</StepperName>
                            <StepperCounter />
                        </StepperItem>
                    </StepperWrapper>
                </LayoutContainer>
            </LayoutWrapper>
            <LayoutBody>
                <Outlet />
            </LayoutBody>
        </React.Fragment>
    )
}

DebitLayout.propTypes = {
    children: PropTypes.node
}

export default DebitLayout