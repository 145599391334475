export const normalizeIncidence = (tipoDocumento, nroDocumento, data, project, files) => {
    return {
        tipoDocumento: tipoDocumento,
        nroDocumento: nroDocumento,
        proforma: data.project,
        plaza: project.cODPLAZAField,
        codProyecto: project.cOD_PROYECTOField,
        proyecto: project.pROYECTOField,
        celular: data.cellphone,
        correo: data.email,
        observacion: data.observation,
        files: files
            .filter(item => item.base64 !== '')
            .map(item => { 
                return { 
                    base64: item.base64.replace(/^data:image\/[a-z]+;base64,/, ""), 
                    fileName: item.fileName 
                } 
            })
    }
}