import { all, fork } from 'redux-saga/effects'

import homeWatcherSaga from './home'
import debitWatcherSaga from './debit'
import loginWatcherSaga from './login'
import usuarioWatcherSaga from './user'
import locationWatcherSaga from './location'
import orderWatcherSaga from './order'
import productWatcherSaga from './product'

export default function* rootSaga() {
    yield all([
        fork(homeWatcherSaga),
        fork(debitWatcherSaga),
        fork(orderWatcherSaga),
        fork(loginWatcherSaga),
        fork(productWatcherSaga),
        fork(usuarioWatcherSaga),
        fork(locationWatcherSaga),
    ])
}