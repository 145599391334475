import React from 'react'
import moment from 'moment'
import Collapsible from 'react-collapsible'
import NumberFormat from 'react-number-format'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { RootState } from '../../../store/modules'
import { getCardType, normalizeDesaffiliation } from '../../../utils/schemas/debit'
import { fetchProject } from '../../../store/modules/debit/actions'
import { fetchHideLoading, fetchShowLoading, fetchShowNotification } from '../../../store/modules/layout/actions'

import Modal from '../../../components/Modal'

import { projectServices } from '../../../services/projectServices'

import { mediaQuery as media } from '../../../utils/mediaQuery'

import cardSharp from '../../../assets/images/svg/ic-card-sharp.svg'
import affiliate from '../../../assets/images/svg/ic-affiliate.svg'
import alert from '../../../assets/images/svg/ic-alert-circle.svg'


const DebitWrapper = styled.div`
`

const DebitContainer = styled.div`
    background: #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 8px 24px rgba(42, 55, 67, 0.12);
    border-radius: 8px;
    padding: 19px 12px;
    display: flex;
    align-items: center;
    ${media.md`
        padding: 24px;
    `}
`

const CollapseContainer = styled.div`
    width: 100%;
    border: 1px solid #93A7D5;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px;
    ${media.sm`
        padding: 16px;
    `}
`

const HeaderContainer = styled.div`
    padding-right: 25px;
    cursor: pointer;
    ${media.sm`
        display: flex;
        align-items: center;
        justify-content: space-between;
    `}
`

const HeaderWrapper = styled.div`
    ${media.sm`
        display: flex;
        align-items: center;
    `}
`

const ProyectContainer = styled.div`
    ${media.sm`
        padding-right: 16px;
    `}
`

const Proyect = styled.div`
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #284FAE;
`

const ProductContainer = styled.div`
    margin-top: 0px;
    ${media.sm`
        margin-top: 0px;
        padding-left: 16px;
        border-left: 1px solid #284FAE;
    `}
`

const Product = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #284FAE;
`

const AmountContainer = styled.div`
    margin-top: 8px;
    ${media.sm`
        margin-top: 0px;
    `}
`

const Amount = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #36393D;
    ${media.sm`
        font-size: 16px;
        line-height: 24px;
    `}
`

const AmountText = styled.span`
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
`

const BodyWrapper = styled.div`
    border: 1px solid rgba(0, 104, 172, 0.2);
    border-radius: 8px;
    padding: 16px 18px;
    ${media.sm`
        padding: 0px;
    `}
`

const BodyContainer = styled.div`
    ${media.sm`
        display: flex;
        align-items: center;
        justify-content: space-between;
    `}
`

const InfoContainer = styled.div`
    width: 100%;
    ${media.sm`
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 16px;
    `}
`

const CardContainer = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    ${media.sm`
        text-align: right;
        padding-bottom: 0px;
    `}
`

const IconContainer = styled.div`
    display: flex;
`

const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    ${media.sm`
        flex-direction: row;
        align-items: center;
    `}
`

const Icon = styled.img``

const CardWrapper = styled.div`
    margin-left: 8px;
`

const TextContainer = styled.div``

const Text = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;  
    color: #82949E;
    ${media.sm`
        text-align: left;
    `}
`

const NumberContainer = styled.div``

const Number = styled.div`
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: #36393D;
    ${media.sm`
        text-align: left;
    `}
`

const DetailContainer = styled.div`
    margin-bottom: 16px;
    ${media.sm`
        margin-bottom: 0px;
    `}
`

const Detail = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #36393D;
`

const ClientContainer = styled.div`
    padding-bottom: 16px;
    ${media.sm`
        padding-bottom: 0px;
    `}
`

const ClientWrapper = styled.div`
    ${media.sm`
        margin-left: 34px;
    `}
`

const NameContainer = styled.div``

const Name = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #36393D;
    text-transform: capitalize;
`

const DesaffiliationContainer = styled.div`
    display: flex;
    padding-top: 16px;
    justify-content: center;
    border-top: 1px solid #82949E;
    ${media.sm`
        padding: 18px 16px;
        border-top: none;
        border-left: 1px solid #82949E;
    `}
`

const Desaffiliation = styled.button`
    width: 100%;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: #36393D;
    padding: 10px 20px;
    border: 1px solid #36393D;
    box-sizing: border-box;
    border-radius: 4px;
    background: #FFFFFF;
    cursor: pointer;
    ${media.lg`
        width: 270px;
    `}
`

const EmptyContainer = styled.div`
    width: 100%;
    padding: 30px 32px;
`

const ImageContainer = styled.div`
    text-align: center;
`

const Image = styled.img`
    width: 80px;
`

const TitleContainer = styled.div`
    margin-top: 12px;
    text-align: center;
`

const Title = styled.div`
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #82949E;
`

const AffiliateContainer = styled.div`
    margin-top: 24px;
    text-align: center;
`

const Affiliate = styled.button`
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    padding: 10px 20px;
    background: #F6A41A;
    border-radius: 4px;
    border: 1px solid #F6A41A;
    cursor: pointer;
    &:disabled {
        color: #82949E;
        cursor: not-allowed;
        background: #E6EAEC;
        border: 1px solid #82949E;
    }
`

const KnowContainer = styled.div`
    margin-top: 12px;
    text-align: center;
`

const Know = styled.a`
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #284FAE;
`

const ErrorContainer = styled.div`
    display: flex;
    background: #F6EEEC;
    border: 0.5px solid #CEA7A4;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 18px;
    margin-bottom: 24px;
`

const AlertContainer = styled.div``

const Alert = styled.img``

const ParagraphContainer = styled.div`
    max-width: 763px;
    margin-left: 15px;
`

const Paragraph = styled.div`
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: #970C0C;
`

const DebitPage = (props: any) => {
    const { id } = useParams()
    const history = useNavigate()
    const dispatch = useDispatch()
    const tipoDocumento = useSelector((state: RootState) => state.user.tipoDocumento)
    const nroDocumento = useSelector((state: RootState) => state.user.nroDocumento)
    const projectList = useSelector((state: RootState) => state.home.projectList)

    const [open, setOpen] = React.useState(false)
    const [affiliation, setAffiliation] = React.useState<any>(null)
    const [expiration, setExpiration] = React.useState(0)

    React.useEffect(() => {
        dispatch(fetchShowLoading())
        const list = projectList.filter(x => x.pROFORMAField === id && x.eSTADOLETRAField === 'PENDIENTE DE PAGO')
        projectServices.getAffiliation(tipoDocumento, nroDocumento).then(response => {
            const result = response.data
            setExpiration(filterExpiration(list))
            setAffiliation(result.find(x => x.proforma === id))
            dispatch(fetchHideLoading())
        }).catch(_ => {
            dispatch(fetchHideLoading())
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const filterExpiration = (list) => {
        return list.filter(x => {
            const actual = moment(Date.now())
            const expiration = moment(x.fECHAVENCIMIENTOField)
            return actual.diff(expiration, 'days') > 0
        }).length
    }

    const handleModal = (open) => {
        document.body.style.overflow = open ? 'hidden' : 'auto'
        setOpen(open)
    }

    const handleDesaffiliate = () => {
        dispatch(fetchShowLoading())
        projectServices.postDessafiliation(normalizeDesaffiliation(affiliation)).then(_ => {
            dispatch(fetchShowNotification())
            window.location.reload()
        }).catch(_ => {
            dispatch(fetchHideLoading())
        })
    }

    const handleDebit = () => {
        dispatch(fetchProject(affiliation))
        history(`/debit/${id}/confirmation`)
    }

    return (
        <React.Fragment>
            <DebitWrapper>
                {(expiration > 0) && (
                    <ErrorContainer>
                        <AlertContainer>
                            <Alert src={alert} />
                        </AlertContainer>
                        <ParagraphContainer>
                            <Paragraph>No puedes realizar la afiliación porque cuentas con {expiration} letra{`${expiration > 1 ? 's' : ''}`} vencida{`${expiration > 1 ? 's' : ''}`}. Paga tu letra vencida para que puedas afiliar tu tarjeta
                                al débito automático</Paragraph>
                        </ParagraphContainer>
                    </ErrorContainer>
                )}
                <DebitContainer>
                    {affiliation?.estado ? (
                        <CollapseContainer>
                            <Collapsible
                                open={true}
                                transitionTime={200}
                                trigger={
                                    <HeaderContainer>
                                        <HeaderWrapper>
                                            <ProyectContainer>
                                                <Proyect>{affiliation.nombreProyecto}</Proyect>
                                            </ProyectContainer>
                                            <ProductContainer>
                                                <Product>{affiliation.producto}</Product>
                                            </ProductContainer>
                                        </HeaderWrapper>
                                        <AmountContainer>
                                            <Amount>
                                                <AmountText>Monto mensual: {' '}</AmountText>
                                                <NumberFormat
                                                    prefix={affiliation.codMoneda === 'USD' ? 'US$' : 'S/'}
                                                    decimalSeparator='.'
                                                    displayType='text'
                                                    type='text'
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    value={affiliation.montoCuotaAprox}
                                                />
                                            </Amount>
                                        </AmountContainer>
                                    </HeaderContainer>
                                }
                            >
                                <React.Fragment>
                                    <BodyWrapper>
                                        <BodyContainer>
                                            <InfoContainer>
                                                <InfoWrapper>
                                                    <CardContainer>
                                                        <IconContainer>
                                                            <Icon src={cardSharp} />
                                                        </IconContainer>
                                                        <CardWrapper>
                                                            <TextContainer>
                                                                <Text>Tarjeta {getCardType(affiliation.tarjetaRegistrada)}</Text>
                                                            </TextContainer>
                                                            <NumberContainer>
                                                                <Number>**** **** **** {affiliation.tarjetaRegistrada?.slice(-4)}</Number>
                                                            </NumberContainer>
                                                        </CardWrapper>
                                                    </CardContainer>
                                                    <ClientContainer>
                                                        <ClientWrapper>
                                                            <TextContainer>
                                                                <Text>A nombre de:</Text>
                                                            </TextContainer>
                                                            <NameContainer>
                                                                <Name>{affiliation.nombreCliente?.toLowerCase()}</Name>
                                                            </NameContainer>
                                                        </ClientWrapper>
                                                    </ClientContainer>
                                                </InfoWrapper>
                                                <DetailContainer>
                                                    <Detail>
                                                        <NumberFormat
                                                            prefix={affiliation.codMoneda === 'USD' ? 'US$' : 'S/'}
                                                            decimalSeparator='.'
                                                            displayType='text'
                                                            type='text'
                                                            thousandSeparator={true}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            value={affiliation.montoCuotaAprox}
                                                        />
                                                    </Detail>
                                                </DetailContainer>
                                            </InfoContainer>
                                            <DesaffiliationContainer>
                                                <Desaffiliation onClick={() => handleModal(true)}>
                                                    Desafiliarme al débito automático
                                                </Desaffiliation>
                                            </DesaffiliationContainer>
                                        </BodyContainer>
                                    </BodyWrapper>
                                </React.Fragment>
                            </Collapsible>
                        </CollapseContainer>
                    ) : (
                        <EmptyContainer>
                            <ImageContainer>
                                <Image src={affiliate} />
                            </ImageContainer>
                            <TitleContainer>
                                <Title>Aún no tienes una tarjeta afiliada</Title>
                            </TitleContainer>
                            <AffiliateContainer>
                                <Affiliate onClick={handleDebit} disabled={expiration > 0}>Afiliar tarjeta</Affiliate>
                            </AffiliateContainer>
                            <KnowContainer>
                                <Know target='_blank' href='https://www.losportales.com.pe/pago-automatico/'>Conocer más</Know>
                            </KnowContainer>
                        </EmptyContainer>
                    )}
                </DebitContainer>
                {affiliation?.estado && (
                    <Modal
                        open={open}
                        onClose={() => handleModal(false)}
                        onClick={handleDesaffiliate}
                        cardType={getCardType(affiliation.tarjetaRegistrada)}
                        cardNumber={affiliation.tarjetaRegistrada}
                    />
                )}
            </DebitWrapper>
        </React.Fragment>
    )
}

export default DebitPage