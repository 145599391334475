import api, { authHeader } from '../config/api'
import apiLogin from '../config/api/apiLogin'
import { WEBORIGEN_DEPARTAMENTO } from "../utils/constants/login";

export const authServices = {
    authentication: (data = {}) =>
        api.post('/api/Account/Login', data)
    ,
    getCountry: (data={} ) =>
        api.get(`/api/Account/CargarPaises`, { headers: authHeader(), data: data })
    ,
    authenticationWithEmail: (data={}) =>
        apiLogin.post('/v1/login', data)
    ,
    sendEmailForRecoverPassword: (data = {}) =>
        apiLogin.post('/v1/login/recuperar-clave/enviar-correo', data)
    ,
    checkValidIdForRecoverPassword: (id) => 
        apiLogin.get('/v1/login/recuperar-clave/vigencia-enlace/'+ id + '/?origen=' + WEBORIGEN_DEPARTAMENTO)
    ,
    changePassword: (data={}) =>
        apiLogin.post('/v1/login/recuperar-clave/actualizar-clave', data)
    ,
    registerUser: (data={}) =>
        apiLogin.post('/v1/login/registrar-usuario', data)
}