import {
    ERROR_LOGIN,
    LOCATION_LOGIN_REQUESTED,
    LOCATION_LOGIN_SUCCESS,
    DOCUMENTTYPE_LOGIN_REQUESTED,
    COUNTRYCODE_LOGIN_SUCCESS,
    LOAD_COUNTRYCODE_LOGIN
} from './actions'

const initialState = {
    country: {
        name: 'Peru',
        dial_code: '51',
        code: 'PE'
    },
    document: {
        abrev: 'DNI',
        label: 'DNI - Documento Nacional de Identidad',
        value: '01',
        maxLength: 8
    },
    longitude: 0,
    latitude: 0,
    loading: false,
    error: false,
    errorMessage: '',
    listCountry:[]
}

export function reducer(state = initialState, action: any) {
    switch (action.type) {
        case LOCATION_LOGIN_REQUESTED:
            return {
                ...state,
                loading: true,
                ...action.payload
            }
        case LOCATION_LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                country: action.payload
            }
        case DOCUMENTTYPE_LOGIN_REQUESTED:
            return {
                ...state,
                document: action.payload
            }
        case COUNTRYCODE_LOGIN_SUCCESS:
            return {
                ...state,
                country: action.payload
            }
        case ERROR_LOGIN:
            return {
                ...state,
                ...action.payload
            }
       case LOAD_COUNTRYCODE_LOGIN:
            return {
                ...state,
                listCountry:action.payload
           }
        default:
            return state
    }
}