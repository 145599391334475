import * as yup from "yup";
import {
  CODE_DNI,
  CODE_PER,
  CODE_RUC,
  ERROR_CEL_EXT,
  ERROR_CEL_PER,
  ERROR_DNI,
  ERROR_RUC,
} from "../../utils/constants/login";

export const schema = yup.object().shape({
  nroCellphone: yup
    .string()
    .required("El número de teléfono es requerido")
    .matches(/^\d+$/, 'El número de celular debe contener solo dígitos')
    .when("countryCode", {
      is: (countryCode) => countryCode === CODE_PER,
      then: yup.string().length(9, ERROR_CEL_PER)
    })
    .when("countryCode", {
      is: (countryCode) => countryCode !== CODE_PER,
      then: yup.string().max(13, ERROR_CEL_EXT)
    }),
  nroDocumento: yup
    .string()
    .required("El número de documento es requerido")
    .when("tipoDocumento", {
      is: (tipoDocumento) => tipoDocumento === CODE_DNI,
      then: yup.string().matches(/^\d+$/, 'El número de documento debe contener solo dígitos')
        .when("tipoDocumento", {
          is: (tipoDocumento) => tipoDocumento === CODE_DNI,
          then: yup.string().length(8, ERROR_DNI),
        }),
    })
    .when("tipoDocumento", {
      is: (tipoDocumento) => tipoDocumento === CODE_RUC,
      then: yup.string().matches(/^\d+$/, 'El número de documento debe contener solo dígitos')
        .when("tipoDocumento", {
          is: (tipoDocumento) => tipoDocumento === CODE_RUC,
          then: yup.string().length(11, ERROR_RUC),
        }),
    })
    .when("tipoDocumento", {
      is: (tipoDocumento) => tipoDocumento !== CODE_DNI && tipoDocumento !== CODE_RUC,
      then: yup.string().matches(/^[a-zA-Z0-9_-]+$/, 'El número de documento no permite caracteres especiales')
    })
    ,
  nombres: yup.string()
    .required("Los nombres son requeridos")
    .matches(/^'?(?:\p{L}\p{M}*)+(?:['\s](?:\p{L}\p{M}*)+)*'?$/u,'El nombre no permite caracteres especiales o espacios en blanco')
    .max(30, 'Los nombres no pueden tener más de 30 caracteres'),
  apellidoPaterno: yup.string()
    .required("El apellido paterno es necesario")
    .matches(/^'?(?:\p{L}\p{M}*)+(?:['\s](?:\p{L}\p{M}*)+)*'?$/u,'El apellido paterno no permite caracteres especiales o espacios en blanco')
    .max(50, 'El apellido paterno no puede tener más de 50 caracteres'),
  apellidoMaterno: yup.string()
    .required("El apellido materno es necesario")
    .matches(/^'?(?:\p{L}\p{M}*)+(?:['\s](?:\p{L}\p{M}*)+)*'?$/u,'El apellido materno no permite caracteres especiales o espacios en blanco')
    .max(50, 'El apellido materno no puede tener más de 50 caracteres'),
  correo: yup
    .string()
    .required("El correo es necesario")
    .email("Ingrese un correo electrónico válido"),
  clave: yup
    .string()
    .required("La constraseña es requerida")
    .min(8, "La contraseña debe tener al menos 8 caracteres")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>]).*$/,
      'La contraseña debe contener al menos una mayúscula, una minúscula y un carácter especial (!@#$%^&*(),.?":{}|<>).'
    ),
  confirmarClave: yup
    .string()
    .required("Por favor repita su contraseña")
    .test(
      "passwords-match",
      "Las contraseñas deben coincidir",
      function (value) {
        return value === this.parent.clave;
      }
    ),
  aceptaDatosPersonales: yup
    .bool() // use bool instead of boolean
    .oneOf([true], "el check de tratamiento es obligatorio"),
});
