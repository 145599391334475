// import React from 'react'
// import moment from 'moment'
// import NumberFormat from 'react-number-format'
// import { useReactToPrint } from 'react-to-print'
// import styled from 'styled-components'

// import { mediaQuery as media } from '../../../../utils/mediaQuery'

// import success from '../../../../assets/images/svg/ic-success.svg'
// import client from '../../../../assets/images/svg/ic-person.svg'
// import card from '../../../../assets/images/svg/ic-tarjeta.svg'

// interface IProps {
//     user: any
//     document: any
//     transaction: any
//     onHome: () => void
//     onShared: () => void
// }

// const SuccessWrapper = styled.div`
//     padding: 54px 16px 80px;
// `

// const SuccessContainer = styled.div`
//     width: 100%;
//     max-width: 564px;
//     margin: 0px auto;
//     box-sizing: border-box;
// `

// const LogoContainer = styled.div`
//     text-align: center;
// `

// const Logo = styled.img``

// const TitleContainer = styled.div`
//     margin-top: 22px;
// `

// const Title = styled.div`
//     font-style: normal;
//     font-weight: 800;
//     font-size: 16px;
//     line-height: 24px;
//     color: #36393D;
//     text-align: center;
//     ${media.md`
//         font-size: 24px;
//         line-height: 32px;
//     `}
// `

// const SubtitleContainer = styled.div`
//     margin-top: 8px;
// `

// const Subtitle = styled.div`
//     font-style: normal;
//     font-weight: normal;
//     font-size: 12px;
//     line-height: 16px;
//     color: #36393D;
//     text-align: center;
//     ${media.md`
//         font-size: 14px;
//         line-height: 20px;
//     `}
// `

// const PaymentContainer = styled.div`
//     &:after {
//         content: '';
//         background: linear-gradient(-45deg, transparent 18px, #ffffff 0), 
//             linear-gradient(45deg, transparent 18px, #ffffff 0);
//         background-repeat: repeat-x;
//         background-position: left bottom;
//         background-size: 24px 32px;
//         display: block;
//         width: 100%;
//         height: 16px;
//         position: relative;
//         bottom: 0px;
//         left: 0px;
//     }
//     ${media.md`
//         &:after {
//             height: 25px;
//             background-size: 25px 32px;
//         }
//     `}
// `

// const DetailContainer = styled.div`
//     margin-top: 40px;
//     padding: 24px;
//     background: #FFFFFF;
//     border-radius: 8px 8px 0px 0px;
// `

// const HeaderContainer = styled.div`
//     margin-bottom: 24px;
//     display: flex;
//     justify-content: space-between;
// `

// const OperationContainer = styled.div``

// const Operation = styled.div`
//     font-style: normal;
//     font-weight: 800;
//     font-size: 16px;
//     line-height: 24px;
//     color: #36393D;
// `

// const PrintContainer = styled.div`
// `

// const Print = styled.div`
//     font-style: normal;
//     font-weight: bold;
//     font-size: 16px;
//     line-height: 24px;
//     color: #284FAE;
//     text-decoration: underline;
//     cursor: pointer;
// `

// const BodyContainer = styled.div``

// const InfoContainer = styled.div`
//     display: flex;
//     justify-content: space-between;
//     padding-bottom: 12px;
//     border-bottom: 1px solid #BECAE6;
//     &:not(:first-child) {
//         padding-top: 12px;
//     }
//     ${media.sm`
//         padding-bottom: 8px;
//         &:not(:first-child) {
//             padding-top: 12px;
//         }
//     `}
// `

// const LabelContainer = styled.div``

// const Label = styled.div`
//     font-style: normal;
//     font-weight: normal;
//     font-size: 14px;
//     line-height: 20px;
//     color: #6884C4;
//     margin-bottom: 4px;
//     ${media.md`
//         font-size: 16px;
//         line-height: 24px;
//     `}
// `

// const ValueContainer = styled.div`
//     margin-left: 10px;
// `

// const Value = styled.div`
//     font-style: normal;
//     font-weight: 800;
//     font-size: 14px;
//     line-height: 20px;
//     color: #36393D;
//     text-transform: capitalize;
//     ${media.md`
//         font-size: 16px;
//         line-height: 24px;
//     `}
// `

// const AccountContainer = styled.div`
//     margin-top: 40px;
// `

// const Account = styled.div`
//     font-style: normal;
//     font-weight: 800;
//     font-size: 16px;
//     line-height: 24px;
//     color: #36393D;
// `

// const ClientContainer = styled.div`
//     display: flex;
//     align-items: flex-start;
//     margin-top: 12px;
// `

// const Client = styled.div`
//     display: flex;
//     align-items: center;
// `

// const ClientLogo = styled.img``

// const ClientText = styled.div`
//     font-style: normal;
//     font-weight: normal;
//     font-size: 14px;
//     line-height: 20px;
//     color: #36393D;
//     margin-left: 10px;
// `

// const ClientWrapper = styled.div`
//     margin-left: 60px;
//     ${media.md`
//         margin-left: 102px;
//     `}
// `

// const NameContainer = styled.div``

// const NameLabel = styled.div`
//     font-style: normal;
//     font-weight: normal;
//     font-size: 14px;
//     line-height: 20px;
//     color: #6884C4;
// `

// const NameText = styled.div`
//     font-style: normal;
//     font-weight: normal;
//     font-size: 14px;
//     line-height: 20px;
//     margin-top: 4px;
//     color: #36393D;
//     text-transform: capitalize;
// `

// const DocumentContainer = styled.div`
//     margin-top: 12px;
// `

// const DocumentLabel = styled.div`
//     font-style: normal;
//     font-weight: normal;
//     font-size: 14px;
//     line-height: 20px;
//     color: #6884C4;
// `

// const DocumentText = styled.div`
//     font-style: normal;
//     font-weight: normal;
//     font-size: 14px;
//     line-height: 20px;
//     margin-top: 4px;
//     color: #36393D;
// `

// const CardContainer = styled.div`
//     display: flex;
//     align-items: flex-start;
//     margin-top: 24px;
// `

// const Card = styled.div`
//     display: flex;
//     align-items: center;
// `

// const CardLogo = styled.img``

// const CardText = styled.div`
//     font-style: normal;
//     font-weight: normal;
//     font-size: 14px;
//     line-height: 20px;
//     color: #36393D;
//     margin-left: 10px;
// `

// const NumberContainer = styled.div`
//     margin-left: 60px;
//     ${media.md`
//         margin-left: 102px;
//     `}
// `

// const NumberLabel = styled.div`
//     font-style: normal;
//     font-weight: normal;
//     font-size: 14px;
//     line-height: 20px;
//     color: #6884C4;
// `

// const NumberText = styled.div`
//     font-style: normal;
//     font-weight: normal;
//     font-size: 14px;
//     line-height: 20px;
//     color: #36393D;
//     margin-top: 8px;
// `

// const OrderContainer = styled.div`
//     display: flex;
//     margin-top: 48px;
//     padding-top: 32px;
//     position: relative;
//     justify-content: space-between;
//     border-top: 1px dashed #93A7D5;
//     &:before {
//         content: '';
//         width: 20px;
//         height: 20px;
//         border-radius: 50%;
//         background: #F0F0F1;
//         position: absolute;
//         top: -10px;
//         left: -35px;
//     }
//     &:after {
//         content: '';
//         width: 20px;
//         height: 20px;
//         border-radius: 50%;
//         background: #F0F0F1;
//         position: absolute;
//         top: -10px;
//         right: -35px;
//     }
//     ${media.md`
//         &:before {
//             width: 25px;
//             height: 25px;
//         }
//         &:after {
//             width: 25px;
//             height: 25px;
//         }
//     `}
// `

// const OrderWrapper = styled.div``

// const OrderLabel = styled.div`
//     font-style: normal;
//     font-weight: normal;
//     font-size: 14px;
//     line-height: 20px;
//     color: #6884C4;
// `

// const OrderValue = styled.div`
//     font-style: normal;
//     font-weight: bold;
//     font-size: 14px;
//     line-height: 20px;
//     color: #36393D;
//     margin-top: 4px;
// `

// const DateContainer = styled.div`
//     text-align: right;
// `

// const Date = styled.div`
//     font-style: normal;
//     font-weight: normal;
//     font-size: 14px;
//     line-height: 20px;
//     color: #36393D;
// `

// const Time = styled.div`
//     font-style: normal;
//     font-weight: normal;
//     font-size: 14px;
//     line-height: 20px;
//     color: #36393D;
//     margin-top: 4px;
// `

// const ButtonContainer = styled.div`
//     margin-top: 40px;
//     ${media.md`
//         max-width: 364px;
//         margin: 0px auto;
//         margin-top: 48px;
//     `}
// `

// const Submit = styled.button`
//     width: 100%;
//     border: none;
//     border: 1px solid #CC26BA;
//     background: #CC26BA;
//     border-radius: 4px;
//     font-family: 'Nunito', sans-serif;
//     font-style: normal;
//     font-weight: 800;
//     font-size: 16px;
//     line-height: 24px;
//     color: #FFFFFF;
//     padding: 12px 0px;
//     cursor: pointer;
//     &:disabled {
//         color: #82949E;
//         background: #E6EAEC;
//         border: 1px solid #82949E;
//     }
// `

// const Button = styled.button`
//     width: 100%;
//     border: 1px solid #36393D;
//     border-radius: 4px;
//     font-family: 'Nunito', sans-serif;
//     font-style: normal;
//     font-weight: 800;
//     font-size: 16px;
//     line-height: 24px;
//     color: #36393D;
//     padding: 12px 0px;
//     margin-top: 16px;
//     cursor: pointer;
// `

// const ComponentToPrint = styled.div``;

// const SuccessPage = ({
//     user,
//     document,
//     transaction,
//     onHome,
//     onShared
// }: IProps) => {
//     const component = React.useRef<HTMLDivElement>(null);
//     const handlePrint = useReactToPrint({
//         content: () => component.current,
//         pageStyle: () =>
//         "@media print { @page { size: A4; margin: 20; } html, body { margin-top: 80px; } }",
//     });

//     return (
//         <React.Fragment>
//             <SuccessWrapper>
//                 <SuccessContainer>
//                     <ComponentToPrint ref={component}>
//                     <LogoContainer>
//                         <Logo src={success} />
//                     </LogoContainer>
//                     <TitleContainer>
//                         <Title>Tu pago se ha realizado con éxito</Title>
//                     </TitleContainer>
//                     <SubtitleContainer>
//                         <Subtitle>Te hemos enviado un correo de tu pago a {user?.eMAILField?.toLowerCase()} con la copia de tu comprobante</Subtitle>
//                     </SubtitleContainer>
//                     <PaymentContainer>
//                         <DetailContainer>
//                             <HeaderContainer>
//                                 <OperationContainer>
//                                     <Operation>Sobre la operación</Operation>
//                                 </OperationContainer>
//                                 <PrintContainer>
//                                     <Print onClick={handlePrint}>Imprimir</Print>
//                                 </PrintContainer>
//                             </HeaderContainer>
//                             <BodyContainer>
//                                 <InfoContainer>
//                                     <LabelContainer>
//                                         <Label>Cliente</Label>
//                                     </LabelContainer>
//                                     <ValueContainer>
//                                         <Value>Los Portales SAC</Value>
//                                     </ValueContainer>
//                                 </InfoContainer>
//                                 <InfoContainer>
//                                     <LabelContainer>
//                                         <Label>Nro documento</Label>
//                                     </LabelContainer>
//                                     <ValueContainer>
//                                         <Value>{document?.bELNRField}</Value>
//                                     </ValueContainer>
//                                 </InfoContainer>
//                                 <InfoContainer>
//                                     <LabelContainer>
//                                         <Label>Tipo de pago</Label>
//                                     </LabelContainer>
//                                     <ValueContainer>
//                                         <Value>{document?.bLARTField === 'R1' ? 'Separación de lote' : 'Pago Inicial'}</Value>
//                                     </ValueContainer>
//                                 </InfoContainer>
//                                 <InfoContainer>
//                                     <LabelContainer>
//                                         <Label>Proyecto</Label>
//                                     </LabelContainer>
//                                     <ValueContainer>
//                                         <Value>{`${document?.pROYECTOField.toLowerCase()} - ${document?.pRODUCTOField}`}</Value>
//                                     </ValueContainer>
//                                 </InfoContainer>
//                                 <InfoContainer>
//                                     <LabelContainer>
//                                         <Label>Vencimiento</Label>
//                                     </LabelContainer>
//                                     <ValueContainer>
//                                         <Value>{moment(document?.fECHAVENCIMIENTOField).format('DD/MM/YYYY')}</Value>
//                                     </ValueContainer>
//                                 </InfoContainer>
//                                 <InfoContainer>
//                                     <LabelContainer>
//                                         <Label>Monto cancelado</Label>
//                                     </LabelContainer>
//                                     <ValueContainer>
//                                         <Value>
//                                             <NumberFormat
//                                                 prefix={transaction?.transactions[0]?.currency === 'USD' ? 'US$' : 'S/'}
//                                                 decimalSeparator='.'
//                                                 displayType='text'
//                                                 type='text'
//                                                 thousandSeparator={true}
//                                                 decimalScale={2}
//                                                 fixedDecimalScale={true}
//                                                 value={transaction?.transactions[0]?.amount / 100}
//                                             />
//                                         </Value>
//                                     </ValueContainer>
//                                 </InfoContainer>
//                                 <AccountContainer>
//                                     <Account>Sobre tu cuenta</Account>
//                                     <ClientContainer>
//                                         <Client>
//                                             <ClientLogo src={client} />
//                                             <ClientText>Cliente</ClientText>
//                                         </Client>
//                                         <ClientWrapper>
//                                             <NameContainer>
//                                                 <NameLabel>Nombre</NameLabel>
//                                                 <NameText>{user?.cLIENTEField.toLowerCase()}</NameText>
//                                             </NameContainer>
//                                             <DocumentContainer>
//                                                 <DocumentLabel>Documento</DocumentLabel>
//                                                 <DocumentText>{user?.nRODOCUMENTOField}</DocumentText>
//                                             </DocumentContainer>
//                                         </ClientWrapper>
//                                     </ClientContainer>
//                                     <CardContainer>
//                                         <Card>
//                                             <CardLogo src={card} />
//                                             <CardText>Tarjeta</CardText>
//                                         </Card>
//                                         <NumberContainer>
//                                             <NumberLabel>Tarjeta de pago</NumberLabel>
//                                             <NumberText>{transaction?.transactions[0]?.transactionDetails.cardDetails.pan.replace('XXXXXX', '******')}</NumberText>
//                                         </NumberContainer>
//                                     </CardContainer>
//                                 </AccountContainer>
//                                 <OrderContainer>
//                                     <OrderWrapper>
//                                         <OrderLabel>Nro de operación</OrderLabel>
//                                         <OrderValue>#{transaction?.orderDetails.orderId}</OrderValue>
//                                     </OrderWrapper>
//                                     <DateContainer>
//                                         <Date>{moment(transaction?.transactions[0]?.transactionDetails.cardDetails.authorizationResponse.authorizationDate).format('DD/MM/YYYY')}</Date>
//                                         <Time>{moment(transaction?.transactions[0]?.transactionDetails.cardDetails.authorizationResponse.authorizationDate).format('HH:mm A')}</Time>
//                                     </DateContainer>
//                                 </OrderContainer>
//                             </BodyContainer>
//                         </DetailContainer>
//                     </PaymentContainer>
//                     </ComponentToPrint>
//                     <ButtonContainer>
//                         <Submit onClick={onHome}>Volver al inicio</Submit>
//                         <Button onClick={onShared}>Compartir recibo</Button>
//                     </ButtonContainer>
//                 </SuccessContainer>
//             </SuccessWrapper>
//         </React.Fragment>
//     )
// }

// export default SuccessPage

import React from 'react'
import moment from 'moment'
import NumberFormat from 'react-number-format'
import { useReactToPrint } from 'react-to-print'
import styled from 'styled-components'

import useMedia from '../../../../hooks/useMedia'
import { mediaQuery as media } from '../../../../utils/mediaQuery'

import success from '../../../../assets/images/ic-success.png'
import payment from '../../../../assets/images/svg/ic-payment.svg'
// import client from '../../../../assets/images/svg/ic-person.svg'
// import card from '../../../../assets/images/svg/ic-tarjeta.svg'
// import arrow from '../../../../assets/images/svg/ic-arrow-right.svg'

interface IProps {
    user: any
    document: any
    transaction: any
    onHome: () => void
    onShared: () => void
}

const SuccessWrapper = styled.div`
  padding: 54px 16px 80px;
`;

const SuccessContainer = styled.div`
  width: 100%;
  max-width: 564px;
  margin: 0px auto;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
  background: #fff;
`;

const HeaderContainer = styled.div`
  padding: 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: #FF0089;
`

const LogoContainer = styled.div`
  text-align: center;
`;

const Logo = styled.img``

const TitleContainer = styled.div`
  margin-top: 12px;
`

const Title = styled.div`
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  ${media.md`
        font-size: 24px;
        line-height: 32px;
    `}
`

const BodyContainer = styled.div`
  padding: 25px;
`

const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
  ${media.sm`
    padding-bottom: 8px;
  `}
`

const LabelContainer = styled.div`
  width: 40%;
`

const Label = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  margin-bottom: 4px;
  ${media.md`
    font-size: 16px;
    line-height: 24px;
  `}
`

const ValueContainer = styled.div`
  width: 60%;
`

const Value = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #36393d;
  text-transform: uppercase;
  ${media.md`
    font-size: 16px;
    line-height: 24px;
  `}
`

const ClientContainer = styled.div`
  padding: 15px;
  border-radius: 5px;
  background: #F2F3F4;
`

const SubtitleContainer = styled.div`
  margin-top: 15px;
`

const Subtitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #36393d;
  text-align: left;
  ${media.md`
    font-size: 14px;
    line-height: 20px;
  `}
`
const LinkContainer = styled.div`
  text-align: center;
  margin-top: 10px;
`

const Link = styled.a`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #00C9C9;
  text-decoration: underline;
  cursor: pointer;
  ${media.md`
    font-size: 16px;
    line-height: 24px;
  `}
`

// const PaymentContainer = styled.div`
//   &:after {
//     content: "";
//     background: linear-gradient(-45deg, transparent 18px, #ffffff 0),
//       linear-gradient(45deg, transparent 18px, #ffffff 0);
//     background-repeat: repeat-x;
//     background-position: left bottom;
//     background-size: 24px 32px;
//     display: block;
//     width: 100%;
//     height: 16px;
//     position: relative;
//     bottom: 0px;
//     left: 0px;
//   }
//   ${media.md`
//         &:after {
//             height: 25px;
//             background-size: 25px 32px;
//         }
//     `}
// `;

// const DetailContainer = styled.div`
//   margin-top: 40px;
//   padding: 24px;
//   background: #ffffff;
//   border-radius: 8px 8px 0px 0px;
// `;

// const HeaderContainer = styled.div`
//   display: flex;
//   justify-content: space-between;
//   margin-bottom: 24px;
// `;

// const OperationContainer = styled.div``;

// const Operation = styled.div`
//   font-style: normal;
//   font-weight: 800;
//   font-size: 16px;
//   line-height: 24px;
//   color: #36393d;
// `;

// const PrintContainer = styled.div``;

// const Print = styled.div`
//   font-style: normal;
//   font-weight: bold;
//   font-size: 16px;
//   line-height: 24px;
//   color: #284fae;
//   text-decoration: underline;
//   cursor: pointer;
// `;

// const BodyContainer = styled.div``;

// const InfoContainer = styled.div`
//   display: flex;
//   justify-content: space-between;
//   padding-bottom: 12px;
//   border-bottom: 1px solid #becae6;
//   &:not(:first-child) {
//     padding-top: 12px;
//   }
//   ${media.sm`
//         padding-bottom: 8px;
//         &:not(:first-child) {
//             padding-top: 12px;
//         }
//     `}
// `;

// const LabelContainer = styled.div``;

// const Label = styled.div`
//   font-style: normal;
//   font-weight: normal;
//   font-size: 14px;
//   line-height: 20px;
//   color: #6884c4;
//   margin-bottom: 4px;
//   ${media.md`
//         font-size: 16px;
//         line-height: 24px;
//     `}
// `;

// const ValueContainer = styled.div`
//   margin-left: 10px;
// `;

// const Value = styled.div`
//   font-style: normal;
//   font-weight: normal;
//   font-size: 14px;
//   line-height: 20px;
//   color: #36393d;
//   text-transform: capitalize;
//   ${media.md`
//         font-size: 16px;
//         line-height: 24px;
//     `}
// `;

// const AccountContainer = styled.div`
//   margin-top: 40px;
// `;

// const Account = styled.div`
//   font-style: normal;
//   font-weight: 800;
//   font-size: 16px;
//   line-height: 24px;
//   color: #36393d;
// `;

// const ClientContainer = styled.div`
//   display: flex;
//   align-items: flex-start;
//   margin-top: 12px;
// `;

// const Client = styled.div`
//   display: flex;
//   align-items: center;
// `;

// const ClientLogo = styled.img``;

// const ClientText = styled.div`
//   font-style: normal;
//   font-weight: normal;
//   font-size: 14px;
//   line-height: 20px;
//   color: #36393d;
//   margin-left: 10px;
// `;

// const ClientWrapper = styled.div`
//   margin-left: 60px;
//   ${media.md`
//         margin-left: 102px;
//     `}
// `;

// const NameContainer = styled.div``;

// const NameLabel = styled.div`
//   font-style: normal;
//   font-weight: normal;
//   font-size: 14px;
//   line-height: 20px;
//   color: #6884c4;
// `;

// const NameText = styled.div`
//   font-style: normal;
//   font-weight: normal;
//   font-size: 14px;
//   line-height: 20px;
//   margin-top: 4px;
//   color: #36393d;
//   text-transform: capitalize;
// `;

// const DocumentContainer = styled.div`
//   margin-top: 12px;
// `;

// const DocumentLabel = styled.div`
//   font-style: normal;
//   font-weight: normal;
//   font-size: 14px;
//   line-height: 20px;
//   color: #6884c4;
// `;

// const DocumentText = styled.div`
//   font-style: normal;
//   font-weight: normal;
//   font-size: 14px;
//   line-height: 20px;
//   margin-top: 4px;
//   color: #36393d;
// `;

// const CardContainer = styled.div`
//   display: flex;
//   align-items: flex-start;
//   margin-top: 24px;
// `;

// const Card = styled.div`
//   display: flex;
//   align-items: center;
// `;

// const CardLogo = styled.img``;

// const CardText = styled.div`
//   font-style: normal;
//   font-weight: normal;
//   font-size: 14px;
//   line-height: 20px;
//   color: #36393d;
//   margin-left: 10px;
// `;

// const NumberContainer = styled.div`
//   margin-left: 60px;
//   ${media.md`
//         margin-left: 102px;
//     `}
// `;

// const NumberLabel = styled.div`
//   font-style: normal;
//   font-weight: normal;
//   font-size: 14px;
//   line-height: 20px;
//   color: #6884c4;
// `;

// const NumberText = styled.div`
//   font-style: normal;
//   font-weight: normal;
//   font-size: 14px;
//   line-height: 20px;
//   color: #36393d;
//   margin-top: 8px;
// `;

// const OrderContainer = styled.div`
//   display: flex;
//   margin-top: 48px;
//   padding-top: 32px;
//   position: relative;
//   justify-content: space-between;
//   border-top: 1px dashed #93a7d5;
//   &:before {
//     content: "";
//     width: 20px;
//     height: 20px;
//     border-radius: 50%;
//     background: #f0f0f1;
//     position: absolute;
//     top: -10px;
//     left: -35px;
//   }
//   &:after {
//     content: "";
//     width: 20px;
//     height: 20px;
//     border-radius: 50%;
//     background: #f0f0f1;
//     position: absolute;
//     top: -10px;
//     right: -35px;
//   }
//   ${media.md`
//         &:before {
//             width: 25px;
//             height: 25px;
//         }
//         &:after {
//             width: 25px;
//             height: 25px;
//         }
//     `}
// `;

// const OrderWrapper = styled.div``;

// const OrderLabel = styled.div`
//   font-style: normal;
//   font-weight: normal;
//   font-size: 14px;
//   line-height: 20px;
//   color: #6884c4;
// `;

// const OrderValue = styled.div`
//   font-style: normal;
//   font-weight: bold;
//   font-size: 14px;
//   line-height: 20px;
//   color: #36393d;
//   margin-top: 4px;
// `;

// const DateContainer = styled.div`
//   text-align: right;
// `;

// const Date = styled.div`
//   font-style: normal;
//   font-weight: normal;
//   font-size: 14px;
//   line-height: 20px;
//   color: #36393d;
// `;

// const Time = styled.div`
//   font-style: normal;
//   font-weight: normal;
//   font-size: 14px;
//   line-height: 20px;
//   color: #36393d;
//   margin-top: 4px;
// `;

const ButtonContainer = styled.div`
  text-align: center;
  margin-bottom: 25px;
  ${media.md`
    max-width: 364px;
    margin: 0px auto 25px; 
  `}
`

const Submit = styled.button`
  border: none;
  background: #284FAB;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.42857143;
  color: #ffffff;
  padding: 6px 12px;
  cursor: pointer;
  margin-left: 10px;
`

const Print = styled.button`
  border: none;
  background: #f0f0f0;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333333;
  padding: 6px 12px;
  cursor: pointer;
  margin-right: 10px;
`;

const Button = styled.button`
  border: none;
  border-radius: 4px;
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333333;
  padding: 6px 12px;
  cursor: pointer;
  margin-right: 20px;
`;

// const ExchangeContainer = styled.div`
//   margin-bottom: 24px;
//   ${media.md`
//         margin-bottom: 40px;
//     `}
// `;

// const AmountContainer = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   margin-bottom: 8px;
//   ${media.md`
//         margin-bottom: 16px;
//     `}
// `;

// const ExchangeLogoContainer = styled.div``;

// const ExchangeLogo = styled.img``;

// const OriginalContainer = styled.div``;

// const TitleOriginalContainer = styled.div``;

// const TitleOriginal = styled.div`
//   font-style: normal;
//   font-weight: normal;
//   font-size: 14px;
//   line-height: 20px;
//   color: #6884c4;
// `;

// const ValueOriginalContainer = styled.div``;

// const ValueOriginal = styled.div`
//   font-style: normal;
//   font-weight: 800;
//   font-size: 16px;
//   line-height: 24px;
//   color: #36393d;
// `;

// const ChangeContainer = styled.div``;

// const TitleChangeContainer = styled.div``;

// const TitleChange = styled.div`
//   font-style: normal;
//   font-weight: normal;
//   font-size: 14px;
//   line-height: 20px;
//   color: #6884c4;
// `;

// const ValueChangeContainer = styled.div``;

// const ValueChange = styled.div`
//   text-align: right;
//   font-style: normal;
//   font-weight: 800;
//   font-size: 16px;
//   line-height: 24px;
//   color: #36393d;
// `;

// const RateContainer = styled.div``;

// const Rate = styled.div`
//   font-style: normal;
//   font-weight: normal;
//   font-size: 12px;
//   line-height: 16px;
//   color: #36393d;
//   & span {
//     font-weight: bold;
//   }
// `;

const ComponentToPrint = styled.div``;

const SuccessPage = ({
    user,
    document,
    transaction,
    onHome,
    onShared
}: IProps) => {
    const { isMobile } = useMedia()
    const component = React.useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({
        content: () => component.current,
        pageStyle: () =>
            `@media print { 
        @page { size: A4; margin: 20; } 
        html, body { margin-top: 80px; } 
        .header-container { background-color: #FF0089 !important; -webkit-print-color-adjust: exact; } 
        .client-container { background-color: #F2F3F4 !important; -webkit-print-color-adjust: exact; } 
      }`,
    })

    return (
        <React.Fragment>
            <SuccessWrapper>
                <ComponentToPrint ref={component}>
                    <SuccessContainer>
                        <HeaderContainer className='header-container'>
                            <LogoContainer>
                                <Logo width='30' src={success} />
                            </LogoContainer>
                            <TitleContainer>
                                <Title>Tu pago fue realizado con éxito</Title>
                            </TitleContainer>
                        </HeaderContainer>
                        <BodyContainer>
                            <InfoContainer>
                                <LabelContainer style={{ width: '100%', textAlign: 'center' }}>
                                    <Label>{moment(transaction?.transactions[0]?.transactionDetails.cardDetails.authorizationResponse.authorizationDate).format("DD/MM/YYYY HH:mm A")}</Label>
                                </LabelContainer>
                            </InfoContainer>
                            <InfoContainer>
                                <LabelContainer>
                                    <Label>Operación Nro.:</Label>
                                </LabelContainer>
                                <ValueContainer>
                                    <Value style={{ textAlign: 'right' }}>{transaction?.transactions[0]?.transactionDetails.cardDetails.authorizationResponse.authorizationNumber}</Value>
                                </ValueContainer>
                            </InfoContainer>
                            <InfoContainer>
                                <LabelContainer>
                                    <Label>Tarjeta de pago:</Label>
                                </LabelContainer>
                                <ValueContainer>
                                    <Value style={{ textAlign: 'right' }}>{transaction?.transactions[0]?.transactionDetails.cardDetails.pan.replace("XXXXXX", "******")}</Value>
                                </ValueContainer>
                            </InfoContainer>
                            <InfoContainer>
                                <LabelContainer>
                                    <Label>Nro de Pedido:</Label>
                                </LabelContainer>
                                <ValueContainer>
                                    <Value style={{ textAlign: 'right' }}>{transaction?.orderDetails.orderId}</Value>
                                </ValueContainer>
                            </InfoContainer>
                            <ClientContainer className='client-container'>
                                <InfoContainer>
                                    <LabelContainer>
                                        <Label>Cliente:</Label>
                                    </LabelContainer>
                                    <ValueContainer>
                                        <Value style={{ color: '#234899', fontWeight: 'bold' }}>{user?.cLIENTEField.toLowerCase()}</Value>
                                    </ValueContainer>
                                </InfoContainer>
                                <InfoContainer>
                                    <LabelContainer>
                                        <Label>Nro Doc.:</Label>
                                    </LabelContainer>
                                    <ValueContainer>
                                        <Value style={{ color: '#234899', fontWeight: 'bold' }}>{transaction?.transactions[0]?.metadata.NroCuota}</Value>
                                    </ValueContainer>
                                </InfoContainer>
                                <InfoContainer>
                                    <LabelContainer>
                                        <Label>Vencimiento:</Label>
                                    </LabelContainer>
                                    <ValueContainer>
                                        <Value style={{ color: '#234899', fontWeight: 'bold' }}>{document?.fECHAVENCIMIENTOField ? moment(document?.fECHAVENCIMIENTOField).format("DD/MM/YYYY") : ''}</Value>
                                    </ValueContainer>
                                </InfoContainer>
                                {document?.bLARTField === 'R1' && (
                                    <InfoContainer>
                                        <LabelContainer>
                                            <Label>Vencimiento</Label>
                                        </LabelContainer>
                                        <ValueContainer>
                                            <Value>{moment(document?.fECHAVENCIMIENTOField).format('DD/MM/YYYY')}</Value>
                                        </ValueContainer>
                                    </InfoContainer>
                                )}
                                <InfoContainer>
                                    <LabelContainer>
                                        <Label>Monto:</Label>
                                    </LabelContainer>
                                    <ValueContainer>
                                        <Value style={{ color: '#234899', fontWeight: 'bold' }}>
                                            <NumberFormat
                                                prefix={transaction?.transactions[0]?.currency === 'USD' ? 'US$' : 'S/'}
                                                decimalSeparator='.'
                                                displayType='text'
                                                type='text'
                                                thousandSeparator={true}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                value={transaction?.transactions[0]?.amount / 100}
                                            />
                                        </Value>
                                    </ValueContainer>
                                </InfoContainer>
                            </ClientContainer>
                            <SubtitleContainer>
                                <Subtitle>
                                    Hemos enviado una notificación por correo electrónico. {/*{user?.eMAILField.toLowerCase()}*/}
                                </Subtitle>
                            </SubtitleContainer>
                            <LinkContainer>
                                <Link href='https://storagecdnvivienda.blob.core.windows.net/vivienda/sistemas/micuenta/micuenta.politica.privacidad.pdf' target='_blank'>Políticas de Privacidad</Link>
                            </LinkContainer>
                            <LinkContainer>
                                <Link href='https://storagecdnvivienda.blob.core.windows.net/vivienda/sistemas/micuenta/micuenta.terminos.condiciones.pdf' target='_blank'>Términos y Condiciones</Link>
                            </LinkContainer>
                            <div style={{ marginTop: '25px', textAlign: 'center' }}>
                                <img src={payment} alt='' />
                            </div>
                        </BodyContainer>
                        {/* <LogoContainer>
              <Logo src={success} />
            </LogoContainer>
            <TitleContainer>
              <Title>Tu pago se ha realizado con éxito</Title>
            </TitleContainer>
            <SubtitleContainer>
              <Subtitle>
                Te hemos enviado un correo de tu pago{" "}
                {document?.affiliate
                  ? " y la confirmación de tu afiliación al pago automático"
                  : ""}{" "}
                a {user?.eMAILField.toLowerCase()}
              </Subtitle>
            </SubtitleContainer>
            <PaymentContainer>
              <DetailContainer>
                <HeaderContainer>
                  <OperationContainer>
                    <Operation>Sobre la operación</Operation>
                  </OperationContainer>
                  <PrintContainer>
                    <Print onClick={handlePrint}>Imprimir</Print>
                  </PrintContainer>
                </HeaderContainer>
                {document.payment !== 'USD' && (
                  <ExchangeContainer>
                    <AmountContainer>
                      <OriginalContainer>
                        <TitleOriginalContainer>
                          <TitleOriginal>Monto original</TitleOriginal>
                        </TitleOriginalContainer>
                        <ValueOriginalContainer>
                          <ValueOriginal>
                            <NumberFormat
                              prefix='US$'
                              decimalSeparator="."
                              displayType="text"
                              type="text"
                              thousandSeparator={true}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              value={document.iMPORTEField}
                            />
                          </ValueOriginal>
                        </ValueOriginalContainer>
                      </OriginalContainer>
                      <ExchangeLogoContainer>
                        <ExchangeLogo src={arrow} />
                      </ExchangeLogoContainer>
                      <ChangeContainer>
                        <TitleChangeContainer>
                          <TitleChange>Monto cambiado</TitleChange>
                        </TitleChangeContainer>
                        <ValueChangeContainer>
                          <ValueChange>
                            <NumberFormat
                              prefix='S/'
                              decimalSeparator="."
                              displayType="text"
                              type="text"
                              thousandSeparator={true}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              value={exchange.importePEN}
                            />
                          </ValueChange>
                        </ValueChangeContainer>
                      </ChangeContainer>
                    </AmountContainer>
                    <RateContainer>
                      <Rate>
                        Tipo de cambio: <span>S/{exchange}</span>
                      </Rate>
                    </RateContainer>
                  </ExchangeContainer>
                )}
                <BodyContainer>
                  <InfoContainer>
                    <LabelContainer>
                      <Label>Cliente</Label>
                    </LabelContainer>
                    <ValueContainer>
                      <Value>Los Portales SAC</Value>
                    </ValueContainer>
                  </InfoContainer>
                  <InfoContainer>
                    <LabelContainer>
                      <Label>Nro documento</Label>
                    </LabelContainer>
                    <ValueContainer>
                      <Value>{`${document?.bUKRSField}${document?.gJAHRField}${document?.bELNRField}`}</Value>
                    </ValueContainer>
                  </InfoContainer>
                  <InfoContainer>
                    <LabelContainer>
                      <Label>Tipo de pago</Label>
                    </LabelContainer>
                    <ValueContainer>
                      <Value>Letras del lote</Value>
                    </ValueContainer>
                  </InfoContainer>
                  <InfoContainer>
                    <LabelContainer>
                      <Label>Proyecto</Label>
                    </LabelContainer>
                    <ValueContainer>
                      <Value>{`${document?.pROYECTOField.toLowerCase()} - ${document?.pRODUCTOField}`}</Value>
                    </ValueContainer>
                  </InfoContainer>
                  <InfoContainer>
                    <LabelContainer>
                      <Label>Vencimiento</Label>
                    </LabelContainer>
                    <ValueContainer>
                      <Value>
                        {document?.fECHAVENCIMIENTOField ? moment(document?.fECHAVENCIMIENTOField).format("DD/MM/YYYY") : ''}
                      </Value>
                    </ValueContainer>
                  </InfoContainer>
                  <InfoContainer>
                    <LabelContainer>
                      <Label>Monto cancelado</Label>
                    </LabelContainer>
                    <ValueContainer>
                      <Value>
                        <NumberFormat
                          prefix={transaction.transactions[0]?.currency === "USD" ? "US$" : "S/"}
                          decimalSeparator="."
                          displayType="text"
                          type="text"
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          value={transaction.transactions[0]?.amount / 100}
                        />
                      </Value>
                    </ValueContainer>
                  </InfoContainer>
                  <AccountContainer>
                    <Account>Sobre tu cuenta</Account>
                    <ClientContainer>
                      <Client>
                        <ClientLogo src={client} />
                        <ClientText>Cliente</ClientText>
                      </Client>
                      <ClientWrapper>
                        <NameContainer>
                          <NameLabel>Nombre</NameLabel>
                          <NameText>{user?.cLIENTEField.toLowerCase()}</NameText>
                        </NameContainer>
                        <DocumentContainer>
                          <DocumentLabel>Documento</DocumentLabel>
                          <DocumentText>{user?.nRODOCUMENTOField}</DocumentText>
                        </DocumentContainer>
                      </ClientWrapper>
                    </ClientContainer>
                    <CardContainer>
                      <Card>
                        <CardLogo src={card} />
                        <CardText>Tarjeta</CardText>
                      </Card>
                      <NumberContainer>
                        <NumberLabel>Tarjeta de pago</NumberLabel>
                        <NumberText>
                          {transaction?.transactions[0]?.transactionDetails.cardDetails.pan.replace("XXXXXX", "******")}
                        </NumberText>
                      </NumberContainer>
                    </CardContainer>
                  </AccountContainer>
                  <OrderContainer>
                    <OrderWrapper>
                      <OrderLabel>Nro de operación</OrderLabel>
                      <OrderValue>#{transaction?.orderDetails.orderId}</OrderValue>
                    </OrderWrapper>
                    <DateContainer>
                      <Date>{moment(transaction?.transactions[0]?.transactionDetails.cardDetails.authorizationResponse.authorizationDate).format("DD/MM/YYYY")}</Date>
                      <Time>{moment(transaction?.transactions[0]?.transactionDetails.cardDetails.authorizationResponse.authorizationDate).format("HH:mm A")}</Time>
                    </DateContainer>
                  </OrderContainer>
                </BodyContainer>
              </DetailContainer>
            </PaymentContainer> */}
                        <ButtonContainer>
                            {isMobile && (
                                <Button onClick={onShared}>Compartir</Button>
                            )}
                            <Print onClick={handlePrint}>Imprimir</Print>
                            <Submit onClick={onHome}>Continuar</Submit>
                        </ButtonContainer>
                    </SuccessContainer>
                </ComponentToPrint>
            </SuccessWrapper>
        </React.Fragment>
    );
};

export default SuccessPage;