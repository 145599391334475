import React from 'react'
import PropTypes from 'prop-types'
import Page from '../../../containers/Debit/ExternalPage'

const Debit = (props: any) => (
    <React.Fragment>
        <Page {...props} />
    </React.Fragment>
)

Debit.propTypes = {
    children: PropTypes.node
}

export default Debit