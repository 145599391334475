import * as yup from "yup";

export const schema = yup.object().shape({
  password: yup
    .string()
    .required("La constraseña es requerida")
    .required("La constraseña es requerida")
    .min(8, "La contraseña debe tener al menos 8 caracteres")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>]).*$/,
      'La contraseña debe contener al menos una mayúscula, una minúscula y un carácter especial (!@#$%^&*(),.?":{}|<>).'
    ),
  password2: yup
    .string()
    .required("Por favor repita su contraseña")
    .test(
      "passwords-match",
      "Las contraseñas deben coincidir",
      function (value) {
        return value === this.parent.password;
      }
    ),
});
