import React from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'

interface IOption {
    label: string
    value: string
}

interface IProps {
    icon: string
    name: string
    label?: any
    options: IOption[]
    placeholder?: string
    defaultValue?: string
    onChange?: (e) => void
}

const Container = styled.div`
    position: relative;
`

const Input = styled.input`
    width: 100%;
    height: 55px;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #010101;
    padding: 15px 50px 15px 12px;
    border-radius: 4px;
    background: transparent;
    border: 1px solid ${props => props.value || props.defaultValue ? '#BECAE6' : '#BECAE6'} ;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    position: absolute;
    left: 0px;
    &:focus {
        border: 1px solid #FFFFFF;
        outline: none;
    }
    &::placeholder {
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #EDB2E7;
    }
`

const Component = styled.select<{
    label: string
}>`
    width: 100%;
    height: 55px;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: ##00000;
    padding: 15px 50px 15px 12px;
    border-radius: 4px;
    background: transparent;
    border: 1px solid ${props => props.value || props.defaultValue ? '#BECAE6' : '#BECAE6'} ;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    visibility: ${props => props.label ? 'visible' : 'inherit'};
    opacity: ${props => props.label ? 0 : 1};
    cursor: pointer;
    &:focus {
        border: 1px solid #BECAE6;
        outline: none;
    }
    &::placeholder {
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #EDB2E7;
    }
`

const Option = styled.option`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #36393D;
    padding: 10px 0px 10px 24px;
    &:hover {
        color: #284FAE;
    }
`

const Icon = styled.img`
    position: absolute;
    right: 20px;
    top: calc(50% - 5px);
`

const Select = ({
    icon,
    name,
    label,
    options,
    onChange,
    defaultValue
}: IProps) => {

    const { register, setValue } = useFormContext()

    React.useEffect(() => {
        if (name && defaultValue)
            setValue(name, defaultValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChange = (e) => {
        if(onChange) onChange(e.target.value)
        setValue(name, e.target.value)
    }

    return (
        <Container>
            {label && (
                <Input name={label.value} value={label.value} onChange={()=>{}} />
            )}
            <Component
                {...(name && register(name))}
                name={name}
                label={label}
                onChange={handleChange}
            >
                {options?.map((item, idx) => (
                    <Option key={idx} value={item.value}>{item.label}</Option>
                ))}
            </Component>
            <Icon src={icon} />
        </Container>
    )
}

export default Select