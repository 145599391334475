import React from 'react'
import styled from 'styled-components'

interface IProps {
    isShow: boolean
}

const Component = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 10;
    background: rgba(54, 57, 61, .7);
`

const Spin = styled.div`
    width: 60px;
    height: 60px;
    border: 4px solid #FFFFFF;
    border-top-color: #F6A41A;
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
    transition-timing-function: linear;
    @keyframes spinner {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
`

const Spinner = ({
    isShow = false
}: IProps) => {
    return (
        <React.Fragment>
            {isShow && (
                <Component>
                    <Spin />
                </Component>
            )}
        </React.Fragment>
    )
}

export default Spinner