import * as yup from 'yup'

export const schema = yup.object().shape({
    cellphone: yup.string()
        .required('El celular de contacto es requerido')
        .matches(/^\d+$/)
        .min(9, 'El celular de contacto es incorrecto')
        .max(9, 'El celular de contacto es incorrecto'),
    email: yup.string()
        .required('El email de contacto es requerido')
        .email('El email de contacto es incorrecto'),
    observation: yup.string()
        .required('El detalle de observación es requerido'),
    imagelist: yup.mixed()
})