import moment from "moment"
import 'moment/locale/es'

export const normalizeIzipay = (user, document) => {
    return {
        amount: document.iMPORTEField * 100,
        currency: document.mONEDAField,
        orderId: moment().format('DDMMYYHHmmss'),
        customer: {
            billingDetails: {
                identityCode: user.nRODOCUMENTOField,
                firstName: user.cLIENTEField,
                cellPhoneNumber: user.tELEFONOField,
                language: 'ES'
            },
            // email: user.eMAILField,
            reference: user.nRODOCUMENTOField
        },
        metadata: {
            moneda: document.mONEDAField,
            amountPay: document.iMPORTEField * 100,
            fechaVencimiento: document.fECHAVENCIMIENTOField,
            nombres: user.cLIENTEField,
            apellidos: '',
            numeroDocumento: user.nRODOCUMENTOField,
            // email: user.eMAILField,
            codProyecto: document.cOD_PROYECTOField,
            proyecto: document.pROYECTOField,
            codPlaza: document.cODPLAZAField,
            codLetra: '-',
            proforma: '-',
            idTipoDocumento: parseInt(user.tipoDocumento),
            celular: user.tELEFONOField,
            nroCuota: document.bELNRField + document.bUKRSField + document.gJAHRField,
            tipoOperacion: document.bLARTField,
            tienda: 'LOS PORTALES SEPARACIONES/CASH',
            celularIngreso: user.tELEFONOField,
            dispositivo: 'PC',
            monedaOrigen: document.mONEDAField,
            importeOrigen: document.iMPORTEField * 100,
            tipoCambio: 0,
            fechaVenta: '13/05/2018', // Update
            montoCuotaAprox: 0,
            producto: document.pROYECTOField,
            tipoDocumento: user.tIPODOCField

        },
        formAction: 'ASK_REGISTER_PAY'
    }
}

export const normalizeLetter = (user, document, exchange, affiliation) => {
    return {
        amount: ((document.payment === 'USD' ? document.iMPORTEField : exchange.importePEN) * 100).toFixed(0),
        currency: document.payment === 'USD' ? 'USD' : 'PEN',
        orderId: moment().format('DDMMYYHHmmss'),
        customer: {
            billingDetails: {
                identityCode: user.nRODOCUMENTOField,
                firstName: user.cLIENTEField,
                cellPhoneNumber: user.tELEFONOField,
                language: 'ES'
            },
            reference: user.nRODOCUMENTOField
        },
        metadata: {
            moneda: document.payment,
            amountPay: ((document.payment === 'USD' ? document.iMPORTEField : exchange.importePEN) * 100).toFixed(0),
            fechaVencimiento: document.fECHAVENCIMIENTOField,
            nombres: user.cLIENTEField,
            apellidos: '',
            numeroDocumento: user.nRODOCUMENTOField,
            codProyecto: document.cOD_PROYECTOField,
            proyecto: document.pROYECTOField,
            codPlaza: document.cODPLAZAField,
            codLetra: document.nLETRAField,
            proforma: document.pROFORMAField,
            idTipoDocumento: parseInt(user.tipoDocumento),
            celular: user.tELEFONOField,
            nroCuota: document.bELNRField + document.bUKRSField + document.gJAHRField,
            tipoOperacion: 'R3',
            tienda: 'LOS PORTALES LETRAS/PREPAGOS',
            celularIngreso: user.tELEFONOField,
            dispositivo: 'PC',
            monedaOrigen: document.payment,
            importeOrigen: ((document.payment === 'USD' ? document.iMPORTEField : exchange.importePEN) * 100).toFixed(0),
            tipoCambio: exchange.tipoCambio,
            fechaVenta: affiliation.fechaVenta,
            montoCuotaAprox: document.affiliate ? affiliation.montoCuotaAprox : 0,
            producto: document.pROYECTOField,
            tipoDocumento: user.tIPODOCField
        },
        formAction: document.affiliate ? 'REGISTER_PAY' : 'ASK_REGISTER_PAY'
    }
}

export const normalizeAffiliation = (item, device) => {
    return {
        tipoDocumento: item.tipoDocumento,
        nroDocumento: item.nroDocumento,
        nombreCliente: item.nombreCliente,
        correoElectronico: item.correoElectronico,
        codProyecto: item.codProyecto,
        nombreProyecto: item.nombreProyecto,
        proforma: item.proforma,
        producto: item.producto,
        fechaVenta: item.fechaVenta,
        codMoneda: item.codMoneda,
        montoCuotaAprox: item.montoCuotaAprox,
        fechaRegistro: item.fechaRegistro,
        token: item.token,
        estado: item.estado,
        celularIngreso: item.celularIngreso,
        dispositivo: device ? 'Mobile' : 'PC',
        tarjetaRegistrada: item.tarjetaRegistrada,
        fechaVencimiento: item.fechaVencimiento,
        idTipoDocumento: item.idTipoDocumento
    }
}