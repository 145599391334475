import React from 'react'
import moment from 'moment'
import NumberFormat from 'react-number-format'
import styled from 'styled-components'

import { mediaQuery as media } from '../../../utils/mediaQuery'

import document from '../../../assets/images/svg/ic-document.svg'

interface IProps {
    letter: string
    symbol: string
    amount: string
    onClick: () => void
    expirationDate: number,
    order ?: boolean
}

const BodyWrapper = styled.div`
    border-radius: 8px;
    &:not(:last-child) {
        margin-bottom: 16px;
    }
    ${media.sm`
        padding: 0px;
        border: 1px solid rgba(0, 104, 172, 0.2);
    `}
`

const BodyContainer = styled.div`
    ${media.sm`
        display: flex;
        align-items: center;
        justify-content: space-between;
    `}
`

const InfoContainer = styled.div`
    width: 100%;
    ${media.sm`
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 16px;
    `}
`

const LetterContainer = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    ${media.sm`
        text-align: right;
        padding-bottom: 0px;
    `}
`

const IconContainer = styled.div`
    display: flex;
`

const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    ${media.sm`
        flex-direction: row;
        align-items: center;
    `}
`

const Icon = styled.img``

const CodeContainer = styled.div`
    margin-left: 8px;
`

const TextContainer = styled.div``

const Text = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;  
    color: #82949E;
    ${media.sm`
        text-align: left;
    `}
`

const NumberContainer = styled.div``

const Number = styled.div`
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: #36393D;
    ${media.sm`
        text-align: left;
    `}
`

const DetailContainer = styled.div``

const AmountContainer = styled.div`
    display: flex;
    align-items: center;
    ${media.sm`
        padding-bottom: 0px;
    `}
`

const Amount = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #36393D;
`

const PaymentContainer = styled.div`
    padding-top: 16px;
    border-top: 1px solid #82949E;
    ${media.sm`
        padding: 18px 16px;
        border-top: none;
        border-left: 1px solid #82949E;
    `}
`

const Payment = styled.a`
    display: block;
    padding: 10px;
    text-align: center;
    background: #C200AE;
    border-radius: 4px;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    text-decoration: none;
    color: #FFFFFF;
    cursor: pointer;
    ${media.sm`
        width: inherit;
        padding: 10px 20px;
    `}
`

const PaymentDisabled = styled.a`
    display: block;
    padding: 10px;
    text-align: center;
    background: #a29ea2;
    border-radius: 4px;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    text-decoration: none;
    color: #FFFFFF;
    cursor: not-allowed;
    ${media.sm`
        width: inherit;
        padding: 10px 20px;
    `}
`

const DateContainer = styled.div`
    padding-bottom: 16px;
    ${media.sm`
        padding-bottom: 0px;
    `}
`

const DateWrapper = styled.div`
    ${media.sm`
        margin-left: 34px;
    `}
`

const FeeContainer = styled.div``

const Fee = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;  
    color: #82949E;
    text-transform: capitalize;
    ${media.sm`
        text-align: left;
    `}
`

const ExpirationContainer = styled.div``

const Expiration = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #36393D;
    &.one-week {
        &:before {
            content: '';
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #F6A41A;
            display: inline-block;
            margin-right: 8px;
        }
    }
    &.two-week {
        &:before {
            content: '';
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #970C0C;
            display: inline-block;
            margin-right: 8px;
        }
    }
`


const Body = ({
    letter,
    symbol,
    amount,
    onClick,
    expirationDate,
    order
}: IProps) => {
    moment.locale('es')

    const expirationWeek = () => {
        const days = moment(Date.now()).diff(moment(expirationDate), 'days')
        if (days >= 7 && days < 14) {
            return 'one-week'
        } else if (days >= 14) {
            return 'two-week'
        }
        return ''
    }

    return (
        <BodyWrapper>
            <BodyContainer>
                <InfoContainer>
                    <InfoWrapper>
                        <LetterContainer>
                            <IconContainer>
                                <Icon src={document} />
                            </IconContainer>
                            <CodeContainer>
                                <TextContainer>
                                    <Text>Cód. de letra</Text>
                                </TextContainer>
                                <NumberContainer>
                                    <Number>{letter}</Number>
                                </NumberContainer>
                            </CodeContainer>
                        </LetterContainer>
                        <DateContainer>
                            <DateWrapper>
                                {/* <FeeContainer>
                                    <Fee>Cuota: {moment(expirationDate).format('MMM YYYY')}</Fee>
                                </FeeContainer> */}
                                <TextContainer>
                                    <Text style={{ fontWeight: '800', color: '#000' }}>Vencimiento { order }</Text>
                                </TextContainer>
                                <ExpirationContainer>
                                    <Expiration className={expirationWeek()}>{moment(expirationDate).format('DD/MM/YYYY')}</Expiration>
                                </ExpirationContainer>
                            </DateWrapper>
                        </DateContainer>
                    </InfoWrapper>
                    <DetailContainer>
                        <AmountContainer>
                            <Amount>
                                <NumberFormat
                                    prefix={symbol === 'USD' ? 'US$' : 'S/'}
                                    decimalSeparator='.'
                                    displayType='text'
                                    type='text'
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    value={amount}
                                />
                            </Amount>
                        </AmountContainer>
                    </DetailContainer>
                </InfoContainer>
{/*                 
                <PaymentContainer>
                { order
                    ? <Payment onClick={onClick}>Pagar</Payment>
                    : <PaymentDisabled>Pagar</PaymentDisabled>
                }
                </PaymentContainer> */}
                    

            </BodyContainer>
        </BodyWrapper>
    )
}

export default Body