import React from 'react'
import styled from 'styled-components'

// import Adviser from '../Adviser'
// import Exchange from '../Exchange'

import card from '../../assets/images/bg-card.png'
import query from '../../assets/images/bg-queries.png'
import debit from '../../assets/images/svg/ic-arrow-debit.svg'
import consult from '../../assets/images/svg/ic-arrow-consult.svg'

import { mediaQuery as media } from '../../utils/mediaQuery'

interface IProps {
    onQuery: () => void
    onDebit: () => void
    name: string
}

const WelcomeWrapper = styled.div`
    background: #C200AE;
    position: relative;
`

const WelcomeContainer = styled.div``

const InfoWrapper = styled.div`
    padding: 24px 16px 84px;
    ${media.sm`
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    `}
    ${media.lg`
        padding-left: 140px;
        padding-right: 140px;
    `}
`

const InfoContainer = styled.div``

const TitleContainer = styled.div``

const Title = styled.div`
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    color: #FFFFFF;
    margin: 0px;
    text-transform: capitalize;
`

// const ExchangeContainer = styled.div`
//     margin-top: 4px;
// `

// const ConsultantContainer = styled.div`
//     margin-top: 24px;
// `

const OptionWrapper = styled.div`
    width: 80%;
    display: inline-block;
    &:first-child {
        padding-left: 16px;
    }
    &:last-child {
        padding-right: 16px;
    }
    ${media.sm`
        width: 50%;
        &:first-child {
            padding-left: 0px;
        }
        &:last-child {
            padding-right: 0px;
        }
    `}
`

const OptionsContainer = styled.div`
    position: absolute;
    left: 0px;
    bottom: -24px;
    overflow: auto;
    white-space: nowrap;
    width: 100%;
    ${media.sm`
        width: calc(100% - 32px);
        left: 16px;
    `}
    ${media.lg`
        width: calc(100% - 280px);
        left: 140px;
        bottom: -28px;
    `}
`

const AutomaticDebt = styled.div`
    border-radius: 8px;
    background: #5b21b6;
    margin-right: 8px;
    cursor: pointer;
`

const AutomaticContainer = styled.div`
    padding: 16px;
    background: url(${card});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 100%;
    border-radius: 8px;
    ${media.sm`
        position: relative;
        &:after {
            content: '';
            width: 48px;
            height: 48px;
            background: url(${debit});
            position: absolute;
            right: 16px;
            top: calc(50% - 24px)
        }
    `}
`

const Query = styled.div`
    border-radius: 8px;
    background: #d97706;
    margin-left: 8px;
    cursor: pointer;
`

const QueryContainer = styled.div`
    padding: 16px;
    background: url(${query});
    background-size: cover;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 100%;
    border-radius: 8px;
    ${media.sm`
        position: relative;
        &:after {
            content: '';
            width: 48px;
            height: 48px;
            background: url(${consult});
            position: absolute;
            right: 16px;
            top: calc(50% - 24px)
        }
    `}
`

const OptionTitle = styled.h2`
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin: 0px;
    ${media.lg`
        font-size: 24px;
        line-height: 32px;
    `}
`

const OptionSubtitle = styled.p`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
    margin: 8px 0px 0px;
    ${media.lg`
        font-size: 14px;
        line-height: 20px;
    `}
`

const Welcome = ({
    name,
    onDebit,
    onQuery
}: IProps) => {
    return (
        <WelcomeWrapper>
            <WelcomeContainer>
                <InfoWrapper>
                    <InfoContainer>
                        <TitleContainer>
                            <Title>¡Bienvenid@, {name.toLowerCase()}!</Title>
                        </TitleContainer>
                        {/* <ExchangeContainer>
                            <Exchange />
                        </ExchangeContainer> */}
                    </InfoContainer>
                    {/* <ConsultantContainer>
                        <Adviser />
                    </ConsultantContainer> */}
                </InfoWrapper>
                <OptionsContainer>
                    {/* <OptionWrapper>
                        <AutomaticDebt onClick={onDebit}>
                            <AutomaticContainer>
                                <OptionTitle>Débito automático</OptionTitle>
                                <OptionSubtitle>¡Afiliate gratis y olvídate de las fechas de pago!</OptionSubtitle>
                            </AutomaticContainer>
                        </AutomaticDebt>
                    </OptionWrapper> */}
                    <OptionWrapper>
                        <Query onClick={onQuery}>
                            <QueryContainer>
                                <OptionTitle>Mis consultas</OptionTitle>
                                <OptionSubtitle>¡Visualiza tus consultas y dudas!</OptionSubtitle>
                            </QueryContainer>
                        </Query>
                    </OptionWrapper>
                </OptionsContainer>
            </WelcomeContainer>
        </WelcomeWrapper>
    )
}

export default Welcome