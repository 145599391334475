import React from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { RootState } from '../../../store/modules'

import { fetchHideLoading, fetchShowLoading } from '../../../store/modules/layout/actions'

import useMedia from '../../../hooks/useMedia'
import Comment from '../../../components/Comment'

import { mediaQuery as media } from '../../../utils/mediaQuery'

import arrow from '../../../assets/images/svg/ic-arrow-left-blue.svg'

import { projectServices } from '../../../services/projectServices'

const AnotherWrapper = styled.div``

const AnotherContainer = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 8px 24px rgba(42, 55, 67, 0.12);
    border-radius: 8px;
    padding: 19px;
    ${media.md`
        padding: 24px 24px 52px;
    `}
`

const HeaderContainer = styled.div``

const TitleContainer = styled.div``

const Title = styled.div`
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 26px;
    color: #284FAE;
    ${media.md`
        font-size: 24px;
        line-height: 32px;
    `}
`

const BodyWrapper = styled.div`
    margin-top: 15px;
    ${media.md`
        background: #FFFFFF;
        box-shadow: 0px 8px 24px rgba(42, 55, 67, 0.12);
        border-radius: 8px;
        margin-top: 24px;
        padding: 12px 8px;
    `}
`

const BodyContainer = styled.div``

const LinkContainer = styled.div``

const Link = styled(NavLink)`
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-decoration: none;
    color: #284FAE;
    &::before {
        content: '';
        width: 9px;
        height: 16px;
        margin-right: 12px;
        background: url(${arrow})
    }
`

const CommentContainer = styled.div`
`

const DetailPage = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const { isMobile } = useMedia()
    const tipoDocumento = useSelector((state: RootState) => state.user.tipoDocumento)
    const nroDocumento = useSelector((state: RootState) => state.user.nroDocumento)

    const [detailList, setDetailList] = React.useState<any>([])

    React.useEffect(() => {
        dispatch(fetchShowLoading())
        projectServices.getHistoricDetail(tipoDocumento, nroDocumento, id ?? '').then(response => {
            setDetailList(response.data)
            dispatch(fetchHideLoading())
        }).catch(_ => {
            dispatch(fetchHideLoading())
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [])

    return (
        <AnotherWrapper>
            <AnotherContainer>
                <HeaderContainer>
                    <TitleContainer>
                        <Title>Historial otras consultas</Title>
                    </TitleContainer>
                </HeaderContainer>
                <BodyWrapper>
                    <BodyContainer>
                        <LinkContainer>
                            <Link to='/query/historic'>
                                {!isMobile && 'Volver'}
                            </Link>
                        </LinkContainer>
                        <CommentContainer>
                            {detailList.map(item => (
                                <Comment
                                    interlocutor={item.interlocutor === 'CLIENTE' ? "Yo" : "Los Portales"}
                                    date={item.fechaCreacionConsulta}
                                    content={item.contenidoHilo}
                                />
                            ))}
                        </CommentContainer>
                    </BodyContainer>
                </BodyWrapper>
            </AnotherContainer>
        </AnotherWrapper>
    )
}

export default DetailPage