import React from 'react'
import styled from 'styled-components'

import Progress from '../Progress'

import { mediaQuery as media } from '../../utils/mediaQuery'

import pin from '../../assets/images/svg/ic-pin.svg'

interface IProps {
    code: string
    name: string
    money: string
    image: string
    proform: string
    overdue: number
    location: string
    direction: string
    totalAmount: number
    totalLetter: number
    partialLetter: number
    partialAmount: number
    onClick: (e) => void
    type: 'deuda' | 'pagando' | 'completado'
}

const LetterContainer = styled.div`
    cursor: pointer;
    margin: 0px -12px 40px -12px;
    padding-left: 8px;
    padding-right: 12px;
    border-left: 4px solid #F6A41A;
    ${media.md`
        display: flex;
        align-items: center;
    `}
    ${media.lg`
        margin: 0px -24px 40px -24px;
        padding-left: 20px;
        padding-right: 24px;
    `}
`

const ImageContainer = styled.div`
    display: flex;
    position: relative;
`

const Image = styled.img`
    width: 100%;
    border-radius: 8px;
    ${media.md`
        max-width: 340px;
    `}
`

const OverdueContainer = styled.div`
    padding: 4px 12px;
    background: #970C0C;
    border-radius: 4px;
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
    position: absolute;
    top: 12px;
    left: 12px;
`

const CodeContainer = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    position: absolute;
    bottom: 14px;
    left: 12px;
`

const InfoContainer = styled.div`
    ${media.md`
        width: calc(100% - 340px);
        padding-left: 24px;
    `}
`

const TitleContainer = styled.div`
    ${media.sm`
        margin-top: 0px;
        border-right: 1px solid #284FAE;
        padding-right: 16px;
    `}
`

const Title = styled.h2`
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    color: #284FAE;
    margin: 0px;
    text-transform: capitalize;
`

const ProyectContainer = styled.div`
    margin-top: 16px;
    ${media.sm`
        display: flex;
        align-items: center;
    `}
    ${media.md`
        margin-top: 0px;
    `}
`

const DirectionContainer = styled.div`
    ${media.sm`
        margin-left: 16px;
    `}
`

const Direction = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #284FAE;
    margin: 4px 0px 0px;
`

const LocationContainer = styled.div`
    display: flex;
    margin-top: 12px;
`

const Icon = styled.img`
    margin-right: 10px;
`

const Location = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #284FAE;
    text-transform: capitalize;
`

const DebtContainer = styled.div`
    margin-top: 12px;
`

const Debt = styled.div<{
    type: 'deuda' | 'pagando' | 'completado'
}>`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #36393D;
    & span {
        font-style: normal;
        font-weight: 800;
        font-size: 12px;
        line-height: 16px;
        color: ${props =>
        props.type === 'deuda' ? '#C200AE' :
            props.type === 'pagando' ? '#4074FE' : '#2D7738'};
        text-transform: uppercase;
        background: ${props =>
        props.type === 'deuda' ? '#F9E5F7' :
            props.type === 'pagando' ? '#E9EDF7' : '#EBF4EC'};
        border: 1px solid ${props =>
        props.type === 'deuda' ? '#C200AE' :
            props.type === 'pagando' ? '#4074FE' : '#2D7738'};;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 4px 8px;
    }
`

const ProgressContainer = styled.div`
    margin-top: 12px;
`

const Letter = ({
    code,
    name,
    image,
    money,
    proform,
    overdue,
    location,
    direction,
    totalAmount,
    totalLetter,
    partialLetter,
    partialAmount,
    onClick,
    type,
}: IProps) => {
    return (
        <LetterContainer onClick={() => onClick(proform)}>
            <ImageContainer>
                {overdue > 0 && (
                    <OverdueContainer>
                        {overdue} letras vencidas
                    </OverdueContainer>
                )}
                <Image src={image} 
                    onError={({ currentTarget }) => {
                        currentTarget.src="https://micuenta.losportalesdepas.com.pe/images/GCCedif.jpeg"
                        currentTarget.onerror = null // prevents looping
                    }}
                />
                <CodeContainer style={{ display: 'none'}}>
                    Cód. Inmueble: <strong>{code}</strong>
                </CodeContainer>
            </ImageContainer>
            <InfoContainer>
                <ProyectContainer>
                    <TitleContainer>
                        <Title>{name}</Title>
                    </TitleContainer>
                    <DirectionContainer>
                        <Direction>{direction}</Direction>
                    </DirectionContainer>
                </ProyectContainer>
                <LocationContainer>
                    <Icon src={pin} />
                    <Location>{location}</Location>
                </LocationContainer>
                {/* <DebtContainer>
                    <Debt type={type}>Estado: <span>{type}</span></Debt>
                </DebtContainer>
                <ProgressContainer>
                    <Progress
                        type={type}
                        money={money}
                        totalAmount={totalAmount}
                        totalLetter={totalLetter}
                        partialLetter={partialLetter}
                        partialAmount={partialAmount}
                    />
                </ProgressContainer> */}
            </InfoContainer>
        </LetterContainer>
    )
}

export default Letter