import api, { authHeader } from '../config/api'

export const projectServices = {
    getDestinations: (tipoDocumento: string, nroDocumento: string, data = {}) =>
        api.get(`/api/MiCuenta/ObtenerUbicacionProyecto?TipoDocumento=${tipoDocumento}&NroDocumento=${nroDocumento}`, { headers: authHeader(), data: data }),
    getSeparationCash: (tipoDocumento: string, nroDocumento: string, data = {}) =>
        api.get(`/api/MiCuenta/ConsultaClienteAD?TipoDocumento=${tipoDocumento}&NroDocumento=${nroDocumento}`, { headers: authHeader(), data: data }),
    postSeparationCash: (tipoDocumento: string, nroDocumento: string, data = {}) =>
        api.post(`/api/MiCuenta/ConsultaClienteAD_LetterSap?TipoDocumento=${tipoDocumento}&NroDocumento=${nroDocumento}`, data, { headers: authHeader() }),
    getLetter: (tipoDocumento: string, nroDocumento: string, data = {}) =>
        api.get(`/api/MiCuenta/ConsultaClienteLetra?TipoDocumento=${tipoDocumento}&NroDocumento=${nroDocumento}`, { headers: authHeader(), data: data }),
    postLetter: (nroDocumento: string, data = {}) =>
        api.post(`/api/MiCuenta/ConsultaClienteLetra_LetterSap?NroDocumento=${nroDocumento}`, data, { headers: authHeader() }),
    postLetterProduct: (tipoDocumento: string, nroDocumento: string, data = {}) =>
        api.post(`/api/MiCuenta/ConsultaClienteLetra_TProductosField?TipoDocumento=${tipoDocumento}&NroDocumento=${nroDocumento}`, data, { headers: authHeader() }),
    getAffiliation: (tipoDocumento: string, nroDocumento: string, data = {}) =>
        api.get(`/api/MiCuenta/ObtenerProyectoAfiliacion?tipoDocumento=${tipoDocumento}&nroDocumento=${nroDocumento}`, { headers: authHeader(), data: data }),
    getExchangeRate: (tipoDocumento: string, nroDocumento: string, codLetra: string, docLetra: string, data = {}) =>
        api.get(`/api/MiCuenta/SapLetrasTipoCambio?TipoDocumento=${tipoDocumento}&NroDocumento=${nroDocumento}&CodLetra=${codLetra}&NroDocumentoLetra=${docLetra}`, { headers: authHeader(), data: data }),
    getMaximumFee: (tipoDocumento: string, nroDocumento: string, idProforma: string, data = {}) =>
        api.get(`/api/MiCuenta/ConsultaLetraCuotaMaxima?TipoDocumento=${tipoDocumento}&NroDocumento=${nroDocumento}&IdProforma=${idProforma}`, { headers: authHeader(), data: data }),
    getReceipt: (data = {}) =>
        api.post(`/api/MiCuenta/GenerarRecibo`, data, { headers: authHeader(), responseType: 'blob' }),
    getReport: (tipoDocumento: string, nroDocumento: string, idProforma: string, data = {}) =>
        api.get(`/api/MiCuenta/GenerarReporteExcel?NroDocumento=${nroDocumento}&TipoDocumento=${tipoDocumento}&IdProforma=${idProforma}`, { headers: authHeader(), data: data, responseType: 'blob' }),
    getRecord: (tipoDocumento: string, nroDocumento: string, idProforma: string, idConstancia: string, codProyecto: string, proyecto: string, producto: string, cliente: string, data = {}) =>
        api.get(`/api/MiCuenta/ObtenerConstancias?NroDocumento=${nroDocumento}&TipoDocumento=${tipoDocumento}&IdProforma=${idProforma}&IdConstancia=${idConstancia}&CodProyecto=${codProyecto}&Proyecto=${proyecto}&Cliente=${cliente}&Producto=${producto}`, { headers: authHeader(), data: data, responseType: 'blob' }),
    getHistoric: (tipoDocumento: string, nroDocumento: string, data = {}) =>
        api.get(`/api/MisConsultasSAC/GetAllConsultaWebMovilListado?TipoDocumento=${tipoDocumento}&NroDocumento=${nroDocumento}`, { headers: authHeader(), data: data }),
    getHistoricDetail: (tipoDocumento: string, nroDocumento: string, codigoConsulta: string, data = {}) =>
        api.get(`/api/MisConsultasSAC/GetAllConsultaWebMovilListadoXConsulta?TipoDocumento=${tipoDocumento}&NroDocumento=${nroDocumento}&CodigoConsulta=${codigoConsulta}`, { headers: authHeader(), data: data }),
    getAdvanceWork: (tipoDocumento: string, nroDocumento: string, codProyecto: string, data = {}) =>
        api.get(`/api/MiCuenta/ObtenerImagenesAvanceObra?NroDocumento=${nroDocumento}&TipoDocumento=${tipoDocumento}&CodigoProyecto=${codProyecto}`, { headers: authHeader(), data: data }),
    getNotice: (tipoDocumento: string, nroDocumento: string, data = {}) =>
        api.get(`/api/MisConsultasSAC/GetAllBuscaAviso?NroDocumento=${nroDocumento}&TipoDocumento=${tipoDocumento}`, { headers: authHeader(), data: data }),
    postAffiliation: (data = {}) =>
        api.post(`/api/MiCuenta/AfiliacionDebitoAutomatico`, data, { headers: authHeader() }),
    postDessafiliation: (data = {}) =>
        api.post(`/api/MiCuenta/DesafiliacionDebitoAutomatico`, data, { headers: authHeader() }),
    postPrepagoCargarLetras: (data = {}) =>
        api.post(`/api/MiCuenta/PrepagoLetrasCargarLetras`, data, { headers: authHeader() }),
    postSendIncidence: (data = {}) =>
        api.post(`/api/MisConsultasSAC/SendIncidentes`, data, { headers: authHeader() }),
    postSendConsulta: (data = {}) =>
        api.post(`/api/MisConsultasSAC/SendConsultaWebMovil`, data, { headers: authHeader() }),
    postSendHiloConsulta: (data = {}) =>
        api.post(`/api/MisConsultasSAC/SendHiloConsultaWebMovil`, data, { headers: authHeader() }),
    postIPN: (data = {}) =>
        api.post(`/api/MiCuenta/IPN`, data, { headers: authHeader() }),
    postPrepagoCargarLetrasImprimir: (data = {}) =>
        api.post(`/api/MiCuenta/PrepagoLetrasCargarImprimir`, data, { headers: authHeader(), responseType: 'blob'  }),
    postPrepagoCargarLetrasSenEmail: (data = {}) =>
        api.post(`/api/MiCuenta/PrepagoLetrasCargarSendEmail`, data, { headers: authHeader()  }),
}