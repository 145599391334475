import React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)
const Container = styled.div`
    width: 100%;
`

const FileContainer = styled.div`
    width: 100%;
    display: block;
    position: relative;
`

const Component = styled.div`
    width: 100%;
    height: 55px;
    border: 1px solid #284FAE;
    box-sizing: border-box;
    border-radius: 4px;
    position: relative;
    &::after {
        content: 'Adjuntar';
        height: 100%;
        padding: 0px 12px;
        top: -1px;
        right: -1px;
        display: flex;
        align-items: center;
        position: absolute;
        background: #E79A18;
        border: 1px solid #E79A18;
        border-radius: 4px;
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 24px;
        color: #FFFFFF;
    }
`

const Input = styled.input`
    width: 100%;
    height: 55px;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px; 
    right: 0px;
    cursor: pointer;
    opacity: 0;
`

const TagContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
`

const Tag = styled.div`
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #284fab;
`

const Icon = styled.span`
    width: 25px;
    height: 25px;
    display: flex;
    background: #F8F9FB;
    transition: all 0.3s;
    border-radius: 50%;
    font-size: 16px;
    align-items: center;
    justify-content: center;
    color: #284fab;
    cursor: pointer;
`
const textDangerStyle = {
    fontSize: '0.8em'
}

const PhotoList = ({
    onChangeFile
}: any) => {
    const { register, setValue } = useFormContext()
    const { fields, append } = useFieldArray({
        name: 'file'
    })

    const [tagList, setTagList] = React.useState<any>([
        { name: '', visible: false },
        { name: '', visible: false },
        { name: '', visible: false },
        { name: '', visible: false },
        { name: '', visible: false },
    ])
    const [fileList, setFileList] = React.useState<any>([1, 0, 0, 0, 0])
    const config = localStorage.getItem('config') ? JSON.parse(localStorage.getItem('config')!) : { 'pesoMaximoArchivoMB': 10 }

    React.useEffect(() => {
        if (!fields.length)
            append(['file', 'file', 'file', 'file', 'file'])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChange = (e, index) => {
        const value = e.target.files
        console.log(tagList);
        if (value.length) {
            if (value[0].size / 1024 / 1024 > config.pesoMaximoArchivoMB) {
                MySwal.fire({
                    title: <strong>Error!</strong>,
                    html: <i>El archivo supera el límite permitido de {config.pesoMaximoArchivoMB}MB</i>,
                    icon: 'error'
                  });
                return false;
            }
            const fileNameDuplicated = tagList.filter(x => x.name === value[0].name)
            if(fileNameDuplicated.length > 0){
                MySwal.fire({
                    title: <strong>Error!</strong>,
                    html: <i>Ya existe un archivo '{value[0].name}' cargado. Intente renombrando el archivo si está seguro que es otro archivo</i>,
                    icon: 'error'
                  });
                return false;
            }
            handleChangeTagList(index, value[0].name, true)
            handleAddFileList(index)
            onChangeFile(index, value, e)
        }
    }

    const handleRemove = (index) => {
        onChangeFile(index, new DataTransfer().files)
        handleChangeTagList(index, '', false)
        handleRemoveFileList(index)
        setValue(`file.${index}.value`, new DataTransfer().files)
    }

    const handleChangeTagList = (index, name, value) => {
        tagList[index].name = name
        tagList[index].visible = value
        setTagList([...tagList])
    }

    const handleAddFileList = (index) => {
        const next = getIndexByValue(0)
        if (next !== -1) fileList[next] = 1
        fileList[index] = 2
        setFileList([...fileList])
    }

    const handleRemoveFileList = (index) => {
        const actual = getIndexByValue(1)
        if (actual !== -1) fileList[actual] = 0
        fileList[index] = 1
        setFileList([...fileList])
    }

    const getIndexByValue = (value) => {
        return fileList.findIndex(item => item === value)
    }

    return (
        <Container>
            <div style={{ width: '100%' }}>
                {tagList.map((item, index) => {
                    return (
                        <TagContainer key={index} style={{ display: (item.visible) ? 'flex' : 'none' }}>
                            <Tag>{item.name}</Tag><Icon onClick={() => handleRemove(index)}>x</Icon>
                        </TagContainer>
                    )
                })}
            </div>
            <div style={{ width: '100%' }}>
                {fields.map((item, index) => {
                    return (
                        <FileContainer key={item.id} style={{ display: (fileList[index] === 1) ? 'block' : 'none' }}>
                            <Component />
                            <Input
                                type='file'
                                accept='image/jpeg, image/png'
                                {...register(`file.${index}.value`)}
                                onChange={(e) => handleChange(e, index)}
                            />
                        </FileContainer>
                    )
                })}
            </div>
            <span style={textDangerStyle}>Se permiten máximo {config.pesoMaximoArchivoMB}MB</span>
        </Container>
    )
}

export default PhotoList