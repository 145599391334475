import React from 'react'
import Slider from 'react-slick'
import { NavLink, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import useMedia from '../../hooks/useMedia'

import arrowLeft from '../../assets/images/svg/ic-arrow-left-blue.svg'
import arrowRight from '../../assets/images/svg/ic-arrow-right-blue.svg'

import { mediaQuery as media } from '../../utils/mediaQuery'

interface IOption {
    icon: string
    name: string
    redirect: string
}

interface IProps {
    options: IOption[]
    initialSlide?: number
    onBeforeChange?: (e) => void
}

const MenuWrapper = styled.div``

const MenuContainer = styled.div``

const MobileContainer = styled.div`
    padding: 20px;
    border-top: 1px solid #82949E;
`

const DesktopContainer = styled.div``

const Slick = styled(Slider)`
    padding: 12px 0px;
    .slick-prev {
        &:before {
            content: none;
        }
        left: 0px;
        width: 9px;
        height: 16px;
        z-index: 2;
    }
    .slick-next {
        &:before {
            content: none;
        }
        right: 0px;
        width: 9px;
        height: 16px;
        z-index: 2;
    }
`

const ItemContainer = styled.div``

const ItemWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
    ${media.md`
        justify-content: flex-start;
        padding: 0px 16px;
    `}
`

const LogoContainer = styled.div`
    display: flex;
    margin-right: 9px;
`

const Logo = styled.img``

const TextContainer = styled.div``

const Text = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #284FAE;
    text-align: center;
    ${media.md`
        color: #36393D;
        text-align: left;
    `}
`

const MenuList = styled.ul`
    padding: 0px;
    margin: 0px;
    list-style-type: none;
`

const MenuItem = styled.li`
    padding: 16px 0px;
    border-top: 1px solid #BECAE6;
`

const Link = styled(NavLink)`
    display: block;
    text-decoration: none;
    &.isActive {
        border-left: 4px solid #F6A41A;
    }
    &.isActive .linkText {
        color: #284FAE;
    }
`

const ArrowNext = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style }}
            onClick={onClick}
        >
            <img alt='' src={arrowRight} />
        </div>
    )
}

const ArrowPrev = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style }}
            onClick={onClick}
        >
            <img alt='' src={arrowLeft} />
        </div>
    )
}

const Menu = ({
    options,
    initialSlide,
    onBeforeChange
}: IProps) => {
    const { isMobile } = useMedia()
    const { pathname } = useLocation()

    return (
        <React.Fragment>
            <MenuWrapper>
                <MenuContainer>
                    {isMobile ? (
                        <MobileContainer>
                            <Slick
                                dots={false}
                                infinite={true}
                                adaptiveHeight={true}
                                speed={500}
                                slidesToShow={1}
                                slidesToScroll={1}
                                initialSlide={initialSlide}
                                prevArrow={<ArrowPrev />}
                                nextArrow={<ArrowNext />}
                                {...(onBeforeChange) && { beforeChange: (current, next) => onBeforeChange(next) }}
                            >
                                {options?.map((item, idx) => (
                                    <ItemContainer key={Math.random() * idx}>
                                        <ItemWrapper>
                                            <LogoContainer>
                                                <Logo src={item.icon} />
                                            </LogoContainer>
                                            <TextContainer>
                                                <Text>{item.name}</Text>
                                            </TextContainer>
                                        </ItemWrapper>
                                    </ItemContainer>
                                ))}
                            </Slick>
                        </MobileContainer>
                    ) : (
                        <DesktopContainer>
                            <MenuList>
                                {options?.map((item, idx) => (
                                    <MenuItem key={Math.random() * idx}>
                                        <Link to={item.redirect} className={(pathname.includes(item.redirect)) ? 'isActive' : ''}>
                                            <ItemWrapper>
                                                <LogoContainer>
                                                    <Logo src={item.icon} />
                                                </LogoContainer>
                                                <TextContainer>
                                                    <Text className='linkText'>{item.name}</Text>
                                                </TextContainer>
                                            </ItemWrapper>
                                        </Link>
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </DesktopContainer>
                    )}
                </MenuContainer>
            </MenuWrapper>
        </React.Fragment>
    )
}

export default Menu