import { combineReducers, createStore } from 'redux'
import { createRouterReducer } from '@lagunovsky/redux-react-router'

import { browserHistory } from '../../config/router/history'

import { AVAILABLE_LOGOUT_REQUESTED } from './login/actions'

import { reducer as home } from './home/reducer'
import { reducer as layout } from './layout/reducer'
import { reducer as login } from './login/reducer'
import { reducer as user } from './user/reducer'
import { reducer as location } from './location/reducer'
import { reducer as order } from './order/reducer'
import { reducer as debit } from './debit/reducer'
import { reducer as product } from './product/reducer'

const getReducer = () => combineReducers({
    router: createRouterReducer(browserHistory),
    location,
    product,
    layout,
    login,
    order,
    debit,
    home,
    user
})

const reducer = getReducer()

export type RootState = ReturnType<typeof reducer>

const rootReducer = (state, action) => {
    if (action.type === AVAILABLE_LOGOUT_REQUESTED) {
        const newState = createStore(getReducer()).getState()
        return reducer(newState, action)
    }

    return reducer(state, action)
}

export default rootReducer