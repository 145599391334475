import React from 'react'
import PropTypes from 'prop-types'
import Page from '../../../containers/Another/DetailPage'

const Another = () => (
    <React.Fragment>
        <Page />
    </React.Fragment>
)

Another.propTypes = {
    children: PropTypes.node
}

export default Another