import React from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Comment from '../../../components/Comment'

import useMedia from '../../../hooks/useMedia'
import { RootState } from '../../../store/modules'

import { fetchHideLoading, fetchShowLoading } from '../../../store/modules/layout/actions'

import { schema } from './validations'
import { mediaQuery as media } from '../../../utils/mediaQuery'
import { normalizeHilo } from '../../../utils/schemas/request'

import arrow from '../../../assets/images/svg/ic-arrow-left-blue.svg'
import close from '../../../assets/images/svg/ic-close-green.svg'

import { projectServices } from '../../../services/projectServices'

const MySwal = withReactContent(Swal)

const RequestWrapper = styled.div``

const RequestContainer = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 8px 24px rgba(42, 55, 67, 0.12);
    border-radius: 8px;
    padding: 19px;
    ${media.md`
        padding: 24px 24px 52px;
    `}
`

const HeaderContainer = styled.div``

const TitleContainer = styled.div``

const Title = styled.div`
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 26px;
    color: #284FAE;
    ${media.md`
        font-size: 24px;
        line-height: 32px;
    `}
`

const BodyWrapper = styled.div`
    margin-top: 15px;
    ${media.md`
        background: #FFFFFF;
        box-shadow: 0px 8px 24px rgba(42, 55, 67, 0.12);
        border-radius: 8px;
        margin-top: 24px;
        padding: 12px 8px;
    `}
`

const BodyContainer = styled.div``

const LinkContainer = styled.div``

const Link = styled(NavLink)`
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-decoration: none;
    color: #284FAE;
    &::before {
        content: '';
        width: 9px;
        height: 16px;
        margin-right: 12px;
        background: url(${arrow})
    }
`

const CommentContainer = styled.div``

const FormContainer = styled.div`
    padding: 60px 15px 10px;
`

const InputGroupContainer = styled.div`
    margin-top: 10px;
    ${media.md`
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 17px;
    `}
`

const LabelContainer = styled.div`
    width: 100%;
    max-width: 195px;
    margin-bottom: 12px;
    ${media.md`
        margin-bottom: 0px;
    `}
`

const Label = styled.label`
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #3D61B3;
`

const TextAreaContainer = styled.div`
    width: 100%;
`

const TextArea = styled.textarea<{
    error: boolean
}>`
    width: 100%;
    border: 1px solid ${props => props.error ? '#970C0C' : '#284FAE'};
    background: ${props => props.error ? '#F6EEEC' : '#FFFFFF'};
    box-sizing: border-box;
    border-radius: 4px;
    padding: 15px 12px;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #284FAE;
    position: relative;
    &:focus {
        outline: none;
    }
`

const ButtonContainer = styled.div`
    text-align: center;
    margin-top: 20px;
    ${media.md`
        margin-top: 57px;
    `}
`

const Button = styled.button`
    width: 100%;
    height: 48px;
    background: #CC26BA;
    border: 1px solid #CC26BA;
    border-radius: 4px;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    cursor: pointer;
    &:focus {
        outline: none;
    }
    ${media.md`
        max-width: 364px;
    `}
`

const FileWrapper = styled.div`
    width: 100%;
`

const FileContainer = styled.div`
    width: 100%;
    display: block;
    position: relative;
`

const FileLabel = styled.div`
    width: 100%;
    height: 55px;
    border: 1px solid #284FAE;
    box-sizing: border-box;
    border-radius: 4px;
    position: relative;
    &::after {
        content: 'Adjuntar';
        height: 100%;
        padding: 0px 12px;
        top: -1px;
        right: -1px;
        display: flex;
        align-items: center;
        position: absolute;
        background: #E79A18;
        border: 1px solid #E79A18;
        border-radius: 4px;
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 24px;
        color: #FFFFFF;
    }
`

const File = styled.input`
    width: 100%;
    height: 55px;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px; 
    right: 0px;
    cursor: pointer;
    opacity: 0;
`

const TagContainer = styled.div`
    display: flex;
    justify-content: space-between;
`

const Tag = styled.div`
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #284fab;
`

const Icon = styled.span`
    width: 25px;
    height: 25px;
    display: flex;
    background: #F8F9FB;
    transition: all 0.3s;
    border-radius: 50%;
    font-size: 16px;
    align-items: center;
    justify-content: center;
    color: #284fab;
    cursor: pointer;
`

const MessageContainer = styled.div`
    background: #EBF4EC;
    border: 0.5px solid #C1D5C3;
    box-sizing: border-box;
    border-radius: 3px;
    margin-top: 20px;
    position: relative;
    padding: 16px 44px 16px 16px;
    ${media.md`
        width: 50%;
        max-width: 364px;
    `}
`

const Message = styled.div`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: #2D7738;
`

const Close = styled.a`
    position: absolute;
    right: 10px;
    top: calc(50% - 8px);
    cursor: pointer;
`


const MessageContainerError = styled.div`
    background: #F8D8D8;
    border: 0.5px solid #E97070;
    box-sizing: border-box;
    border-radius: 3px;
    margin-top: 20px;
    position: relative;
    padding: 16px 44px 16px 16px;
    ${media.md`
        width: 50%;
        max-width: 364px;
    `}
`
const MessageError = styled.div`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: #E40D0D;
`
const textDangerStyle = {
    fontSize: '0.8em'
}

const DetailPage = () => {
    const { id } = useParams()
    const history = useNavigate()
    const dispatch = useDispatch()
    const { isMobile } = useMedia()
    const tipoDocumento = useSelector((state: RootState) => state.user.tipoDocumento)
    const nroDocumento = useSelector((state: RootState) => state.user.nroDocumento)
    const [errorMessage, setErrorMessage] = React.useState('')
    const [error, setError] = React.useState(false)
    const config = localStorage.getItem('config') ? JSON.parse(localStorage.getItem('config')!) : { 'pesoMaximoArchivoMB': 10 }

    const methods = useForm({
        mode: 'onChange',
        reValidateMode: 'onSubmit',
        resolver: yupResolver(schema)
    })
    const { reset, setValue, register, handleSubmit, formState: { errors } } = methods

    const [nHilo, setNHilo] = React.useState('')
    const [nConsulta, setNConsulta] = React.useState('')
    const [detailList, setDetailList] = React.useState<any>([])
    const [image, setImage] = React.useState<any>(null)
    const [success, setSuccess] = React.useState(false)

    React.useEffect(() => {
        dispatch(fetchShowLoading())
        projectServices.getHistoricDetail(tipoDocumento, nroDocumento, id ?? '').then(response => {
            console.log(response);
            const list = response.data
            console.log("lista-hilo");
            console.log(list);
            setDetailList(list)
            setNHilo(list[0].nHilo)
            setNConsulta(list[0].nConsulta)
            dispatch(fetchHideLoading())
        }).catch(_ => {
            dispatch(fetchHideLoading())
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [])

    const handleChange = (e) => {
        const value = e.target.files
        if (value.length) {
            if (value[0].size / 1024 / 1024 > config.pesoMaximoArchivoMB) {
                e.target.value = ''
                MySwal.fire({
                    title: <strong>Error!</strong>,
                    html: <i>El archivo supera el límite permitido  de {config.pesoMaximoArchivoMB}MB</i>,
                    icon: 'error'
                  });
                return false;
            }
            var reader = new FileReader()
            reader.readAsDataURL(value[0])
            reader.onload = function (upload) {
                const file = upload.target?.result
                setImage({ base64: file, fileName: value[0].name, size: value[0].size })
            };
        }
    }

    const handleRemove = () => {
        setImage(null)
        setValue('file', new DataTransfer().files)
    }

    const onSubmit = (data) => {
        dispatch(fetchShowLoading())
        console.log(image);
        projectServices.postSendHiloConsulta(normalizeHilo(tipoDocumento, nroDocumento, image, data, nHilo, nConsulta)).then(response => {
           console.log(response)
           if(response.data.errorHttp!=null){
            setErrorMessage(response.data.errorHttp);
            setError(true);
            setTimeout(() => {
                setError(false)
            }, 4000)     
        }else{
            reset()
            handleRemove()
            setSuccess(true)
            setTimeout(() => {
                setSuccess(false)
                history('/query/request')
            }, 3000)
        }
            dispatch(fetchHideLoading())
        }).catch(error => {
            if (error.response && error.response.status === 400 ) {                
                MySwal.fire({
                    title: <strong>Error!</strong>,
                    html: <i>{error.response.data && typeof error.response.data === 'string' ? error.response.data : "Los datos enviados no son válidos" }</i>,
                    icon: 'error'
                  });
                dispatch(fetchHideLoading());
                return;
              }

            if (error.message === 'Cannot read properties of undefined (reading \'status\')') {
                MySwal.fire({
                    title: <strong>Error!</strong>,
                    html: <i>{`No se pudo enviar la consulta. Es posible que el archivo cargado supere el límite permitido de ${config.pesoMaximoArchivoMB}MB` }</i>,
                    icon: 'error'
                  });
                dispatch(fetchHideLoading());
                return;
              }
            
              MySwal.fire({
                title: <strong>Error!</strong>,
                html: <i>{`Sucedió un error inesperado. Contacte con soporte técnico` }</i>,
                icon: 'error'
              });
              dispatch(fetchHideLoading());
        })
    }

    return (
        <RequestWrapper>
            <RequestContainer>
                <HeaderContainer>
                    <TitleContainer>
                        <Title>Consulta - Solicitudes</Title>
                    </TitleContainer>
                </HeaderContainer>
                <BodyWrapper>
                    <BodyContainer>
                        <LinkContainer>
                            <Link to='/query/request'>
                                {!isMobile && 'Volver'}
                            </Link>
                        </LinkContainer>
                        <CommentContainer>
                            {detailList.map(item => (
                                <Comment
                                    interlocutor={item.interlocutor === 'CLIENTE' ? "Yo" : "Los Portales"}
                                    date={item.fechaCreacionHilo}
                                    content={item.contenidoHilo}
                                />
                            ))}
                        </CommentContainer>
                        <FormContainer>
                            <FormProvider {...methods}>
                                <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
                                    <BodyContainer>
                                        <InputGroupContainer style={{ alignItems: 'flex-start' }}>
                                            <LabelContainer>
                                                <Label>Responder:</Label>
                                            </LabelContainer>
                                            <TextAreaContainer>
                                                <TextArea
                                                    rows={4}
                                                    error={errors.answer}
                                                    {...register('answer')}
                                                />
                                            </TextAreaContainer>
                                        </InputGroupContainer>
                                        <InputGroupContainer>
                                            <LabelContainer>
                                                <Label>Archivo Adjunto:</Label>
                                            </LabelContainer>
                                            <FileWrapper>
                                                {image ? (
                                                    <div style={{ width: '100%' }}>
                                                        <TagContainer>
                                                            <Tag>{image.fileName}</Tag><Icon onClick={handleRemove}>x</Icon>
                                                        </TagContainer>
                                                    </div>
                                                ) : (
                                                    <div style={{ width: '100%' }}>
                                                        <FileContainer>
                                                            <FileLabel />
                                                            <File
                                                                type='file'
                                                                accept='image/jpeg, image/png'
                                                                {...register('file')}
                                                                onChange={(e) => handleChange(e)}
                                                            />
                                                            <span style={textDangerStyle}>Se permiten máximo {config.pesoMaximoArchivoMB}MB</span>
                                                        </FileContainer>
                                                    </div>
                                                )}
                                            </FileWrapper>
                                        </InputGroupContainer>
                                        <ButtonContainer>
                                            <Button type='submit'>Enviar</Button>
                                        </ButtonContainer>
                                        {success && (
                                            <MessageContainer>
                                                <Message>Se registro la consulta correctamente.</Message>
                                                <Close onClick={() => setSuccess(false)}><img src={close} alt='' /></Close>
                                            </MessageContainer>
                                        )}
                                                                        {error && (
                                    <MessageContainerError>
                                        <MessageError>{errorMessage}</MessageError>
                                        <Close onClick={() => setError(false)}><img src={close} alt='' /></Close>
                                    </MessageContainerError>
                                )}
                                    </BodyContainer>
                                </form>
                            </FormProvider>
                        </FormContainer>
                    </BodyContainer>
                </BodyWrapper>
            </RequestContainer>
        </RequestWrapper>
    )
}

export default DetailPage