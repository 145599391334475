import React from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'

interface IProps {
    name: string
    type?: 'normal' | 'large' | 'password'
    maxLength?: number
    placeholder?: string
    defaultValue?: string
    showCount?: boolean
    className?: string
}

const Container = styled.div``

const Component = styled.input`
    width: 100%;
    height: 100%;
    max-height: 55px;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    background: transparent;
    color: black;
    padding: 15px 12px;
    box-sizing: border-box;
    font-size: ${props => props.type === 'normal' || 'password' ? '16px' : '24px'};
    border-radius: ${props => props.type === 'normal'|| 'password' ? '4px' : '8px'};
    line-height: ${props => props.type === 'normal'|| 'password' ? '24px' : '32px'};
    border: 2px solid ${props => props.value || props.defaultValue ? '#BECAE6' : '#BECAE6'} ;
    &:focus {
        outline: none;
        border: 1px solid #BECAE6;
    }
    &::placeholder {
        color: #bdb8b8;
        font-style: normal;
        font-size: ${props => props.type === 'normal'|| 'password' ? '16px' : '24px'};
        line-height: ${props => props.type === 'normal'|| 'password' ? '24px' : '32px'};
    }
`

const CounterContainer = styled.div`
    text-align: right;
`

const Counter = styled.p`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
`

const Input = ({
    name,
    type = 'normal',
    maxLength,
    placeholder,
    defaultValue,
    showCount = false,
    className = ''
}: IProps) => {

    const { register, getValues, setValue, watch } = useFormContext()

    const inputWatch = watch(name) ?? ''

    React.useEffect(() => {
        if (name && defaultValue) {
            setValue(name, defaultValue)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Container>
            <Component
                {...(name && register(name))}
                name={name}
                type={type}
                defaultValue={defaultValue}
                // {...(name && { value: getValues(name) })}
                {...(maxLength && { maxLength: maxLength })}
                placeholder={placeholder}
                className={className}
            />
            {showCount && (
                <CounterContainer>
                    <Counter>{inputWatch.length}/{maxLength}</Counter>
                </CounterContainer>
            )}
        </Container>
    )
}

export default Input