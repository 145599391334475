import React from 'react'
import styled from 'styled-components'

import useMedia from '../../hooks/useMedia'
import { mediaQuery as media } from '../../utils/mediaQuery'

import logo from '../../assets/images/svg/logo.svg'
import exit from '../../assets/images/svg/ic-exit.svg'
import person from '../../assets/images/svg/ic-person-purple.svg'

interface IProps {
  token: string | null
  onClick: () => void
}

const HeaderContainer = styled.header`
  background: #FFFFFF;
  height: 60px;
  padding: 10px 16px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(130, 148, 158, 0.2);
  &.open {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  ${media.lg`
    height: 72px;
    padding: 16px 140px;
  `}
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const ImageContainer = styled.div``

const Img = styled.img`
  width: 108px;
  ${media.md`
    width: 128px;
  `}
`

const MenuContainer = styled.div``

const Menu = styled.div`
  width: 25px;
  cursor: pointer;
  transition: all 0.5s ease-out;
  &.open {
    transform: rotate(180deg);
  }
  &.open div:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }
  &.open div:nth-child(2) {
    opacity: 0;
  }
  &.open div:nth-child(3) {
    transform: rotate(-45deg) translate(7px, -6px);
  }
`

const Line = styled.div`
  width: 25px;
  height: 3px;
  margin: 0 0 5px 0;
  background: #772CBD;
  transition: all 0.5s ease-out;
`

const PanelContainer = styled.div`
  position: fixed;
  top: 59px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: #FFFFFF;
  z-index: 4;
  overflow: hidden;
  transform: translateX(100%);
  transition: all 0.5s ease-out;
  border-top: 1px solid rgba(130,148,158,0.2);
  &.open {
    transform: translateX(0px);
  }
`

const PanelWrapper = styled.div`
  padding: 0px 16px;
`

const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
`

const Logo = styled.img``

const Profile = styled.div`
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  color: #772CBD;
  margin-left: 10px;
`

const LogoutContainer = styled.div`
  margin-top: 40px;
  ${media.sm`
    margin-top: 0px;
  `}
`

const Logout = styled.button`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  background: #772CBD;
  border-radius: 4px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  width: 100%;
  cursor: pointer;
  ${media.sm`
    width: inherit;
    &:before {
      left: 20px;
    }
  `}
`

const Header = ({
  token,
  onClick
}: IProps) => {
  const { isMobileOnly } = useMedia()

  const [open, setOpen] = React.useState(false)

  const handleOnClick = () => {
    setOpen(!open)
    document.body.style.overflow = open ? 'auto' : 'hidden'
  }

  return (
    <HeaderContainer className={open ? 'open' : ''}>
      <Container>
        <ImageContainer>
          {!open && (
            <Img src={logo} />
          )}
        </ImageContainer>
        {token && (
          <React.Fragment>
            {isMobileOnly ? (
              <MenuContainer>
                <Menu onClick={handleOnClick} className={open ? 'open' : ''}>
                  <Line></Line>
                  <Line></Line>
                  <Line></Line>
                </Menu>
                <PanelContainer className={open ? 'open' : ''}>
                  <PanelWrapper>
                    <ProfileContainer>
                      <Logo src={person} />
                      <Profile>Mi perfil</Profile>
                    </ProfileContainer>
                    <LogoutContainer>
                      <Logout onClick={onClick}>
                        <img alt='' src={exit} style={{ marginRight: '10px' }} /> Salir
                      </Logout>
                    </LogoutContainer>
                  </PanelWrapper>
                </PanelContainer>
              </MenuContainer>
            ) : (
              <LogoutContainer>
                <Logout onClick={onClick}>
                  <img alt='' src={exit} style={{ marginRight: '10px' }} /> Salir
                </Logout>
              </LogoutContainer>
            )}
          </React.Fragment>
        )}
      </Container >
    </HeaderContainer >
  )
}

export default Header