export const SELECTED_PRODUCT = 'vivienda/product/SELECTED_PRODUCT'
export const RESET_PRODUCT = 'vivienda/product/RESET_PRODUCT'

export function fetchProductSelected(data) {
    return {
        type: SELECTED_PRODUCT,
        payload: data
    }
}

export function fetchProductReset() {
    return {
        type: RESET_PRODUCT
    }
}