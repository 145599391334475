import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { RootState } from '../../store/modules'
import { fetchUbication } from '../../store/modules/location/actions'
import { fetchShowLoading, fetchHideLoading } from '../../store/modules/layout/actions'

import { amazonServices } from '../../services/amazonServices'
import { projectServices } from '../../services/projectServices'

import Spinner from '../../components/Spinner'
import Button from '../../components/Button'
import Input from '../../components/Input'
import Alert from '../../components/Alert'

import { schema } from './validations'
import { mediaQuery as media } from '../../utils/mediaQuery'
import { normalizeData, normalizeSms } from '../../utils/schemas/validation'

import sms from '../../assets/images/svg/ic-sms.svg'

const VerificationWrapper = styled.div`
    width: 100vw;
    min-height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    background: #C200AE;
    ${media.sm`
        background: #FFFFFF;
    `}
    ${media.lg`
        height: calc(100vh - 72px);
        min-height: auto;
    `}
`

const VerificationContent = styled.div`
    padding: 24px 16px 42px 16px;
    ${media.sm`
        padding: 24px 36px;
    `}
`

const VerificationContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #C200AE;
    box-sizing: border-box;
    ${media.sm`
        padding: 32px 36px;
        max-width: 436px;
        max-height: 300px;
        border-radius: 8px;
        margin-top: 24px;
    `}
`

const TitleContainer = styled.div`
    text-align: center;
`

const Title = styled.h2`
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    color: #FFFFFF;
    margin: 0px;
    ${media.sm`
        color: #284FAE;
    `}
`

const SubtitleContainer = styled.div`
    margin-top: 16px;
    text-align: center;
    ${media.sm`
        margin-top: 0px;
    `}
`

const Subtitle = styled.p`
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin: 0px;
`

const FormContainer = styled.div`
    margin-top: 48px;
    ${media.sm`
        margin-top: 24px;
    `}
`

const CodeContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 40px);
    grid-template-rows: 44px;
    column-gap: 20px;
    justify-content: center;
`

const LinkContainer = styled.div`
    margin-top: 24px;
    text-align: center;
`

const Link = styled.a`
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #F6A41A;
    text-decoration: underline;
    cursor: pointer;
`

const Redirect = styled(NavLink)`
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #F6A41A;
    text-decoration: underline;
    ${media.sm`
        color: #284FAE;
    `}
`

const ButtonContainer = styled.div`
    margin-top: 24px;
`

const LogoContainer = styled.div``

const Img = styled.img``

const ParagraphContainer = styled.div`
    margin-top: 24px;
    text-align: center;
`

const Paragraph = styled.p`
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #E6EAEC;
    margin: 0px;
`

const ErrorContainer = styled.div`
    width: 100%;
    padding: 0px 16px;
    margin-top: 16px;
    box-sizing: border-box;
    ${media.sm`
        margin-top: 0px;
        margin-bottom: 16px;
        padding: 0px;
        max-width: 436px;
    `}
`

const VerificationPage = () => {
    const methods = useForm({
        mode: 'onChange',
        reValidateMode: 'onSubmit',
        resolver: yupResolver(schema)
    })
    const { setFocus, watch, handleSubmit, formState: { isValid } } = methods

    const history = useNavigate()
    const dispatch = useDispatch()
    const nroCellphone = useSelector((state: RootState) => state.user.nroCellphone)
    const tipoDocumento = useSelector((state: RootState) => state.user.tipoDocumento)
    const nroDocumento = useSelector((state: RootState) => state.user.nroDocumento)
    const countryCode = useSelector((state: RootState) => state.user.countryCode)

    const [counter, setCounter] = React.useState(60)
    const [errorMessage, setErrorMessage] = React.useState('')
    const [code, setCode] = React.useState(parseInt((Math.random() * (999 - 100) + 100).toString(), 10))
    
    const login = window.localStorage.getItem('login')

    const isBrowser = () => typeof window !== 'undefined'
    const setVerification = (value) => window.localStorage.setItem('verification', value)

    React.useEffect(() => {
        dispatch(fetchShowLoading())
        console.log(countryCode);
        amazonServices.sendSms(normalizeSms(countryCode, nroCellphone, code)).then(_ => {
            dispatch(fetchHideLoading())
        }).catch(_ => {
            dispatch(fetchHideLoading())
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        if (counter) {
            var timer = setTimeout(() => {
                setCounter(counter - 1)
            }, 1000)
        }
        return () => clearTimeout(timer)
    }, [counter])

    React.useEffect(() => {
        setFocus('codeOne')
        if (watch('codeOne')) setFocus('codeTwo')
        if (watch('codeTwo')) setFocus('codeThree')
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch('codeOne'), watch('codeTwo')])

    const resetCounter = () => {
        const token = parseInt((Math.random() * (999 - 100) + 100).toString(), 10)
        dispatch(fetchShowLoading())
        amazonServices.sendSms(normalizeSms(countryCode, nroCellphone, token)).then(_ => {
            setCounter(3)
            setCode(token)
            dispatch(fetchHideLoading())
        }).catch(_ => {
            dispatch(fetchHideLoading())
        })
    }

    const onSubmit = (data) => {
        const form = normalizeData(data)
        dispatch(fetchShowLoading())
        if(form === code) {
            projectServices.getDestinations(tipoDocumento, nroDocumento).then(response => {
                dispatch(fetchUbication(response.data))
                dispatch(fetchHideLoading())
                if(isBrowser() && response.data) {
                    setVerification(true)
                    history('/')
                }
            }).catch(error => {
                dispatch(fetchHideLoading())
                setErrorMessage('Lo sentimos. Ocurrio un error.')
            })
        } else {
            setTimeout(() => {
                dispatch(fetchHideLoading())
                setErrorMessage('El codigo ingresado es incorrecto. Intentalo nuevamente')
            }, 3000)
        }
    }

    return (
        <React.Fragment>
            <Spinner isShow={false} />
            <VerificationWrapper>
                <VerificationContent>
                    <TitleContainer>
                        <Title>Introduce el código de autenticación</Title>
                    </TitleContainer>
                    {errorMessage && (
                        <ErrorContainer>
                            <Alert type='error' message={errorMessage} />
                        </ErrorContainer>
                    )}
                    <VerificationContainer>
                        <SubtitleContainer>
                            <Subtitle>Introduce el código de verificación generado por su teléfono que acaba en <strong>+xx xxx xxx x{nroCellphone.slice(-2)}</strong></Subtitle>
                        </SubtitleContainer>
                        <FormContainer>
                            <FormProvider {...methods}>
                                <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
                                    <CodeContainer>
                                        <LogoContainer>
                                            <Img src={sms} />
                                        </LogoContainer>
                                        <Input
                                            type='large'
                                            name='codeOne'
                                            placeholder='0'
                                            maxLength={1}
                                        />
                                        <Input
                                            type='large'
                                            name='codeTwo'
                                            placeholder='0'
                                            maxLength={1}
                                        />
                                        <Input
                                            type='large'
                                            name='codeThree'
                                            placeholder='0'
                                            maxLength={1}
                                        />
                                    </CodeContainer>
                                    {counter ? (
                                        <ParagraphContainer>
                                            <Paragraph>Espera {counter} segundos para reenviar</Paragraph>
                                        </ParagraphContainer>
                                    ) : (
                                        <LinkContainer>
                                            <Link onClick={resetCounter}>Reenviar código</Link>
                                        </LinkContainer>
                                    )}
                                    <ButtonContainer>
                                        <Button
                                            label='Verificar'
                                            type='submit'
                                            disabled={!isValid}
                                        />
                                    </ButtonContainer>

                                </form>
                            </FormProvider>
                        </FormContainer>
                    </VerificationContainer>
                    <LinkContainer>
                        <Redirect to={`/login?type=${login}`}>Volver atrás</Redirect>
                    </LinkContainer>
                </VerificationContent>
            </VerificationWrapper>
        </React.Fragment>
    )
}

export default VerificationPage