import * as yup from 'yup'

export const schema = yup.object().shape({
    subject: yup.string()
        .required('El asunto es requerido'),
    project: yup.string()
        .required('El proyecto es requerido'),
    query: yup.string()
        .required('La consulta es requerida'),
    file: yup.mixed()
})