import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'

const PrivateRoute = () => {
    const login = window.localStorage.getItem('login') ?? 'cash'
    const token = window.localStorage.getItem('token')

    return token ? <Outlet /> : <Navigate to={`/login?type=${login}`} />
}

export default PrivateRoute