import React from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { mediaQuery as media } from '../../utils/mediaQuery'

interface IProps {
    name: string
    value: string
    subtitle?: string
    children: React.ReactNode
}

const Container = styled.div``

const Component = styled.input`
    width: 19px;
    height: 19px;
    visibility: visible;
    position: absolute;
    opacity: 0;
    margin: 3px 0px 0px;
`

const Label = styled.label`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #36393D;
    position: relative;
    padding-left: 30px;
    margin-top: 0px;
    display: inline-block;
    cursor: pointer;
    & a {
        color: #284FAE;
        font-weight: 800;
        text-decoration: none;
    }
    ${media.sm`
        padding-top: 3px;
        padding-bottom: 3px;
    `}
`

const Small = styled.small`
    width: 19px;
    height: 19px;
    left: 0px;
    top: 3px;
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    border-radius: 50%;
    border: 1px solid #36393D;
    box-sizing: border-box;
    cursor: pointer;
    &.active {
        background: #284FAE;
        border-color: #284FAE;
        &::after {
            content: '';
            width: 8px;
            height: 3px;
            display: block;
            border: 2px solid #fff;
            transform: rotate(-45deg);
            border-right: 0;
            border-top: 0;
            position: absolute;
            left: 0;
            top: -3px;
            right: 0;
            bottom: 0;
            margin: auto;
        }
    }
`

const Subtitle = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #36393D;
    padding-left: 30px;
`

const Radio = ({
    name,
    value,
    subtitle,
    children
}: IProps) => {

    const { register, watch } = useFormContext()

    const inputWatch = watch(name) ?? ''

    return (
        <Container>
            <Component
                id={`radio-${value}`}
                type='radio'
                {...(name && register(name))}
                value={value}
            />
            <Label htmlFor={`radio-${value}`}>
                <Small className={inputWatch === value ? 'active' : ''} />
                {children}
            </Label>
            {subtitle && (
                <Subtitle>{subtitle}</Subtitle>
            )}
        </Container>
    )
}

export default Radio