import {
    PROJECT_HOME_REQUESTED,
    PRODUCT_HOME_REQUESTED
} from './actions'

const initialState = {
    productList: [],
    projectList: [],
    loading: false,
    error: false,
    errorMessage: ''
}

export function reducer(state = initialState, action: any) {
    switch (action.type) {
        case PROJECT_HOME_REQUESTED:
            return {
                ...state,
                projectList: action.payload
            }
        case PRODUCT_HOME_REQUESTED:
            return {
                ...state,
                productList: action.payload
            }
        default:
            return state
    }
}