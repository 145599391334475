import { 
    PROJECT_HOME_REQUESTED,
    PRODUCT_HOME_REQUESTED
} from '../modules/home/actions'
import { take, all } from 'redux-saga/effects'

export default function* homeWatcherSaga() {
    yield all([
        take(PROJECT_HOME_REQUESTED),
        take(PRODUCT_HOME_REQUESTED)
    ])
}