import React from "react";
import styled from "styled-components";
import { mediaQuery as media } from "../../utils/mediaQuery";
import { FormProvider, useForm } from "react-hook-form";
import Input from "../../components/Input";
import Button from "../../components/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  fetchHideLoading,
  fetchShowLoading,
} from "../../store/modules/layout/actions";
import { authServices } from "../../services/authServices";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { schema } from "./validations";
import { ErrorMessage } from "@hookform/error-message";
import Alert from "../../components/Alert";
import successIcon from '../../assets/images/svg/ic-success-green.svg'
import errorIcon from '../../assets/images/svg/ic-error-red.svg'

import { WEBORIGEN_DEPARTAMENTO } from "../../utils/constants/login";

const MySwal = withReactContent(Swal)


const LoginWrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background: #ffffff;
  ${media.lg`
        height: calc(100vh - 72px);
        min-height: auto;
    `}
`;

const LoginContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  -webkit-box-shadow: 0px 0px 2px 0px #000000;
  box-shadow: 0px 0px 2px 0px #000000;
  box-sizing: border-box;
  padding: 24px 16px 42px 16px;
  ${media.sm`
        padding: 24px 36px;
        max-width: 436px;
        max-height: 480px;
        border-radius: 8px;
    `}
`;

const TitleContainer = styled.div`
  text-align: center;
`;

const Title = styled.h2`
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  color: #284fab;
  margin: 0px;
`;
const FormContainer = styled.div`
  margin-top: 24px;
`;

const DocumentContainer = styled.div``;

const ButtonContainer = styled.div`
  margin-top: 18px;
`;

const Label = styled.label`
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  display: block;
  margin-bottom: 7px;
`;

const ErrorContainer = styled.div`
  width: 100%;
  padding: 0px 16px;
  margin-top: 16px;
  box-sizing: border-box;
  ${media.sm`
        margin-top: 0px;
        margin-bottom: 16px;
        padding: 0px;
        max-width: 436px;
    `}
`;



const Error = ({ errors, name }) => (
  <ErrorMessage
    errors={errors}
    name={name}
    render={({ message }) => (
      <ErrorContainer>
        <Alert type="error" message={message} />
      </ErrorContainer>
    )}
  />
);

const RecuperarContrasena = () => {
  const [isValidId, setIsValidId] = React.useState(true);

  const dispatch = useDispatch();
  const history = useNavigate()
  const { id } = useParams();

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    formState: { errors, isValid },
  } = methods;

  const onSubmit = (data) => {
    if (data.password !== data.password2) {
      MySwal.fire({
        title: <strong>Las contraseñas deben ser las mismas</strong>,
        html: <i>Las contraseñas deben ser iguales</i>,
        icon: 'error'
      });
      return 
    }

    dispatch(fetchShowLoading());
    authServices
      .changePassword({
        codigoRecuperarClave: id,
        clave: data.password,
        confirmarClave: data.password2,
        origen: WEBORIGEN_DEPARTAMENTO
      })
      .then((response) => {
        console.log(response)
        MySwal.fire({
          title: <strong>La contraseña se cambio correctamente</strong>,
          html: <i>{response.data.response}</i>,
          iconHtml: <img src={successIcon} alt="Success Icon" />
        });

        dispatch(fetchHideLoading());

        setTimeout(() => {
          history(`/login`)
        }, 2000)
      })
      .catch((e) => {
        MySwal.fire({
          title: <strong>No se pudo cambiar la contraseña</strong>,
          html: <i>{e.response.data.title}</i>,
          iconHtml: <img src={errorIcon} alt="Error Icon" />
        });
        dispatch(fetchHideLoading());
      });
  };

  const checkValidId = () => {
    dispatch(fetchShowLoading());
    authServices
      .checkValidIdForRecoverPassword(id)
      .then((response) => {
        dispatch(fetchHideLoading());
        setIsValidId(true)
      })
      .catch((e) => {
        MySwal.fire({
          title: <strong>El enlace caducó</strong>,
          html: <i>{e.response.data.title}</i>,
          iconHtml: <img src={errorIcon} alt="Error Icon" />
        });
        setIsValidId(false)
        setTimeout(() => {
          history(`/login`)
        }, 2000)
        dispatch(fetchHideLoading());
      });
  };

  React.useEffect(() => {
    checkValidId()
  }, [])

  if(!isValidId) {
    return (
      <React.Fragment>
        <LoginWrapper>
          <LoginContainer>
            <TitleContainer>
              <Title className="text-3xl font-bold">Te estamos redireccionando</Title>
            </TitleContainer>
          </LoginContainer>
        </LoginWrapper>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <LoginWrapper>
        <LoginContainer>
          <TitleContainer>
            <Title className="text-3xl font-bold">Recuperar contraseña</Title>
          </TitleContainer>
          <Error errors={errors} name="password" />
          <Error errors={errors} name="password2" />
          <FormContainer>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <DocumentContainer>
                  <div>
                    <Label>Nueva contraseña</Label>
                    <Input
                      showCount={true}
                      name="password"
                      placeholder="Nueva contraseña"
                      type="password"
                    />
                  </div>
                </DocumentContainer>
                <DocumentContainer>
                  <div>
                    <Label>Vuelve a escribir tu contraseña</Label>
                    <Input
                      showCount={true}
                      name="password2"
                      type="password"
                      placeholder="Vuelve a escribir tu contraseña"
                    />
                  </div>
                </DocumentContainer>
                <ButtonContainer>
                  <Button
                    label="Confirmar"
                    type="submit"
                    className="bg-purple-heart-500 text-white disabled:bg-pigeon-post-400 disabled:text-chicago-500"
                    disabled={!isValid}
                  />
                </ButtonContainer>
              </form>
            </FormProvider>
          </FormContainer>
        </LoginContainer>
      </LoginWrapper>
    </React.Fragment>
  );
};

export default RecuperarContrasena;
