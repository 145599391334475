import {
    PROJECT_DEBIT
} from './actions'

const initialState = {
    tipoDocumento: "",
    nroDocumento: "",
    nombreCliente: "",
    correoElectronico: "",
    codProyecto: "",
    nombreProyecto: "",
    proforma: "",
    producto: "",
    fechaVenta: "",
    codMoneda: "",
    montoCuotaAprox: 0,
    fechaRegistro: "",
    token: null,
    estado: false,
    celularIngreso: "",
    dispositivo: null,
    tarjetaRegistrada: null,
    fechaVencimiento: "",
    idTipoDocumento: ""
}

export function reducer(state = initialState, action: any) {
    switch (action.type) {
        case PROJECT_DEBIT:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}