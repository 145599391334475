import {
    UBICATION_LOCATION
} from './actions'

const initialState = {
    ubications: []
}

export function reducer(state = initialState, action: any) {
    switch (action.type) {
        case UBICATION_LOCATION:
            return {
                ...state,
                ubications: action.payload
            }
        default:
            return state
    }
}