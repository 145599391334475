import { createRouterMiddleware } from '@lagunovsky/redux-react-router'
import createSagaMiddleware from 'redux-saga'

import { browserHistory } from '../../config/router/history'
import { AVAILABLE_LOGOUT_REQUESTED } from '../modules/login/actions'

const isBrowser = () => typeof window !== 'undefined'
const setLogin = (key: string) => isBrowser() && window.localStorage.setItem('login', key)

const localStorageMiddleware = store => next => action => {
    if(action.type === AVAILABLE_LOGOUT_REQUESTED) {
        const login = window.localStorage.getItem('login') ?? ''
        window.localStorage.clear()
        setLogin(login)
    }
    store.getState()
    next(action)
    store.getState()
}

export const sagaMiddleware = createSagaMiddleware()

const rootMiddleware = [
    createRouterMiddleware(browserHistory),
    sagaMiddleware,
    localStorageMiddleware
]

export default rootMiddleware