import { compose } from 'redux';
import logger from 'redux-logger'
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, REGISTER, PURGE } from 'redux-persist'
import localstorage from 'redux-persist/lib/storage'
import { encryptTransform } from 'redux-persist-transform-encrypt'

import rootSaga from './sagas'
import rootReducer from './modules'
import rootMiddleware from './middleware'

import { configureStore } from '@reduxjs/toolkit'

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
    }
}

const encryptor = encryptTransform({
    secretKey: 'VUE5MzxxLHtpWj0uIX4wMm10JyF6SQ==',
    onError: function (error) {
        console.log(error)
    },
})

const persistConfig = {
    key: 'root',
    storage: localstorage,
    transforms: [encryptor],
    blacklist: ['login', 'router'],
    debug: true
}

export const sagas = rootSaga

const createStore = () => {
    const middleware = (getDefaultMiddleware) => [
        ...getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, REGISTER, PURGE]
            }
        }),
        ...rootMiddleware,
        ...(process.env.NODE_ENV === 'development' ? [logger] : [])
    ]

    return {
        ...configureStore({
            reducer: persistReducer(persistConfig, rootReducer),
            middleware,
            devTools: process.env.NODE_ENV === 'development'
        })
    }
}

type ConfiguredStore = ReturnType<typeof createStore>
type StoreGetState = ConfiguredStore["getState"]
export type RootState = ReturnType<StoreGetState>
export type AppDispatch = ConfiguredStore["dispatch"]

export default createStore