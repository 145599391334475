import api from '../api'
import { push } from '@lagunovsky/redux-react-router'
import { fetchAvailableLogout } from '../../store/modules/login/actions'

const isBrowser = () => typeof window !== 'undefined'
const login = isBrowser() && window.localStorage.getItem('login')

const run = (store) => {
    api.interceptors.response.use(function (response) {
        return response
    }, function (error) {
        if(error.response.status === 401) {
            store.dispatch(fetchAvailableLogout())
            push(`/login?type=${login}`)
        }
        return Promise.reject(error)
    })
}

const rootInterceptor = { run }

export default rootInterceptor