import React from 'react'
import PropTypes from 'prop-types'
import Page from '../../containers/AdvancePage'

const Advance = () => (
    <React.Fragment>
        <Page />
    </React.Fragment>
)

Advance.propTypes = {
    children: PropTypes.node
}

export default Advance