import React from 'react'
import styled from 'styled-components'

interface IProps {
    name: string
}

const ProfileWrapper = styled.div``

const ProfileContainer = styled.div`
    display: flex;
    align-items: center;
`

const LogoContainer = styled.div`
    width: 44px;
    height: 44px;
    background: #C200AE;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Logo = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: #FFFFFF;
`

const InfoContainer = styled.div`
    margin-left: 8px;
`

const TextContainer = styled.div``

const Text = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #93A7D5;
`

const NameContainer = styled.div``

const Name = styled.div`
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #36393D;
    text-transform: capitalize;
`

const Profile = ({
    name
}: IProps) => {
    return (
        <ProfileWrapper>
            <ProfileContainer>
                <LogoContainer>
                    <Logo>{name?.charAt(0)}</Logo>
                </LogoContainer>
                <InfoContainer>
                    <TextContainer>
                        <Text>Mi perfil</Text>
                    </TextContainer>
                    <NameContainer>
                        <Name>{name?.toLowerCase()}</Name>
                    </NameContainer>
                </InfoContainer>
            </ProfileContainer>
        </ProfileWrapper>
    )
}

export default Profile