import React from 'react'
import styled from 'styled-components'

import { mediaQuery as media } from '../../utils/mediaQuery'

interface IProps {
    icon: string
    text: string[]
    title?: string
    onClick?: () => void
}

const EmptyContainer = styled.div`
    padding-top: 14px;
`

const EmptyWrapper = styled.div`
    text-align: center;
    padding: 49px 45px;
    ${media.sm`
        box-shadow: 0px 8px 24px rgba(42, 55, 67, 0.12);
        border-radius: 8px;
    `}
`

const IconContainer = styled.div``

const Icon = styled.img`
    width: 80px;
`

const TitleContainer = styled.div`
    margin-top: 12px;
`

const Title = styled.div`
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #82949E;
    margin-top: 0px;
    margin-bottom: 0px;
`

const ButtonContainer = styled.div`
    margin-top: 24px;
    text-align: center;
`

const Button = styled.button`
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    padding: 10px 20px;
    background: #F6A41A;
    border-radius: 4px;
    border: 1px solid #F6A41A;
    cursor: pointer;
    &:disabled {
        color: #82949E;
        cursor: not-allowed;
        background: #E6EAEC;
        border: 1px solid #82949E;
    }
`

const Empty = ({
    icon,
    text,
    title,
    onClick
}: IProps) => {
    return (
        <EmptyContainer>
            <EmptyWrapper>
                <IconContainer>
                    <Icon src={icon} />
                </IconContainer>
                <TitleContainer>
                    {text.map(item => (
                        <Title>{item}</Title>
                    ))}
                </TitleContainer>
                {title && (
                    <ButtonContainer>
                        <Button onClick={onClick}>{title}</Button>
                    </ButtonContainer>
                )}
            </EmptyWrapper>
        </EmptyContainer>
    )
}

export default Empty