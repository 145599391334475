import React from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { RootState } from '../../../store/modules'

import { mediaQuery as media } from '../../../utils/mediaQuery'

import success from '../../../assets/images/svg/ic-success.svg'
import client from '../../../assets/images/svg/ic-person.svg'
import card from '../../../assets/images/svg/ic-tarjeta.svg'

const SuccessWrapper = styled.div`
    padding: 54px 16px 80px;
`

const SuccessContainer = styled.div`
    width: 100%;
    max-width: 564px;
    margin: 0px auto;
    box-sizing: border-box;
`

const LogoContainer = styled.div`
    text-align: center;
`

const Logo = styled.img``

const TitleContainer = styled.div`
    margin-top: 22px;
`

const Title = styled.div`
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #36393D;
    text-align: center;
    ${media.md`
        font-size: 24px;
        line-height: 32px;
    `}
`

const SubtitleContainer = styled.div`
    margin-top: 8px;
`

const Subtitle = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #36393D;
    text-align: center;
    ${media.md`
        font-size: 14px;
        line-height: 20px;
    `}
`

const PaymentContainer = styled.div`
    &:after {
        content: '';
        background: linear-gradient(-45deg, transparent 18px, #ffffff 0), 
            linear-gradient(45deg, transparent 18px, #ffffff 0);
        background-repeat: repeat-x;
        background-position: left bottom;
        background-size: 24px 32px;
        display: block;
        width: 100%;
        height: 16px;
        position: relative;
        bottom: 0px;
        left: 0px;
    }
    ${media.md`
        &:after {
            height: 25px;
            background-size: 25px 32px;
        }
    `}
`

const DetailContainer = styled.div`
    margin-top: 40px;
    padding: 24px;
    background: #FFFFFF;
    border-radius: 8px 8px 0px 0px;
`

const HeaderContainer = styled.div`
    margin-bottom: 24px;
    ${media.md`
        display: flex;
        justify-content: space-between;
    `}
`

const OperationContainer = styled.div``

const Operation = styled.div`
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #36393D;
`

const PrintContainer = styled.div`
    margin-top: 8px;
`

const Print = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #284FAE;
    text-decoration: underline;
`

const BodyContainer = styled.div``

const InfoContainer = styled.div`
    padding-bottom: 12px;
    border-bottom: 1px solid #BECAE6;
    &:not(:first-child) {
        padding-top: 12px;
    }
    ${media.sm`
        display: flex;
        justify-content: space-between;
        padding-bottom: 8px;
        &:not(:first-child) {
            padding-top: 12px;
        }
    `}
`

const LabelContainer = styled.div``

const Label = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #6884C4;
    margin-bottom: 4px;
    ${media.md`
        font-size: 16px;
        line-height: 24px;
    `}
`

const ValueContainer = styled.div``

const Value = styled.div`
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: #36393D;
    text-transform: capitalize;
    ${media.md`
        font-size: 16px;
        line-height: 24px;
    `}
`

const AccountContainer = styled.div`
    margin-top: 40px;
`

const Account = styled.div`
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #36393D;
`

const ClientContainer = styled.div`
    margin-top: 12px;
    ${media.md`
        display: flex;
        align-items: flex-start;
    `}
`

const Client = styled.div`
    display: flex;
    align-items: center;
`

const ClientLogo = styled.img``

const ClientText = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #36393D;
    margin-left: 10px;
`

const ClientWrapper = styled.div`
    ${media.md`
        margin-left: 102px;
    `}
`

const NameContainer = styled.div`
    margin-top: 10px;
    ${media.md`
        margin-top: 0px;
    `}
`

const NameLabel = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #6884C4;
`

const NameText = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    margin-top: 4px;
    color: #36393D;
    text-transform: capitalize;
`

const CardContainer = styled.div`
    margin-top: 24px;
    ${media.md`
        display: flex;
        align-items: flex-start;
    `}
`

const Card = styled.div`
    display: flex;
    align-items: center;
`

const CardLogo = styled.img``

const CardText = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #36393D;
    margin-left: 10px;
`

const NumberContainer = styled.div`
    margin-top: 10px;
    ${media.md`
        margin-top: 0px;
        margin-left: 102px;
    `}
`

const NumberLabel = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #6884C4;
`

const NumberText = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #36393D;
    margin-top: 8px;
`

const OrderContainer = styled.div`
    display: flex;
    margin-top: 48px;
    padding-top: 32px;
    position: relative;
    justify-content: space-between;
    border-top: 1px dashed #93A7D5;
    &:before {
        content: '';
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #F0F0F1;
        position: absolute;
        top: -10px;
        left: -35px;
    }
    &:after {
        content: '';
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #F0F0F1;
        position: absolute;
        top: -10px;
        right: -35px;
    }
    ${media.md`
        &:before {
            width: 25px;
            height: 25px;
        }
        &:after {
            width: 25px;
            height: 25px;
        }
    `}
`

const DateContainer = styled.div`
    text-align: left;
`

const Date = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #36393D;
`

const Time = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #36393D;
    margin-top: 4px;
`

const ButtonContainer = styled.div`
    margin-top: 40px;
    ${media.md`
        max-width: 364px;
        margin: 0px auto;
        margin-top: 48px;
    `}
`

const Submit = styled.button`
    width: 100%;
    border: none;
    border: 1px solid #CC26BA;
    background: #CC26BA;
    border-radius: 4px;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    padding: 12px 0px;
    cursor: pointer;
    &:disabled {
        color: #82949E;
        background: #E6EAEC;
        border: 1px solid #82949E;
    }
`

const Button = styled.button`
    width: 100%;
    border: 1px solid #36393D;
    border-radius: 4px;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #36393D;
    padding: 12px 0px;
    margin-top: 16px;
    cursor: pointer;
`

const SuccessPage = () => {
    const history = useNavigate()
    const proforma = useSelector((state: RootState) => state.debit.proforma)
    const correoCliente = useSelector((state: RootState) => state.debit.correoElectronico)
    const nombreCliente = useSelector((state: RootState) => state.debit.nombreCliente)
    const locacionProyecto = useSelector((state: RootState) => state.debit.nombreProyecto)
    const ubicacionProyecto = useSelector((state: RootState) => state.debit.producto)
    const montoPagado = useSelector((state: RootState) => state.debit.montoCuotaAprox)
    const moneda = useSelector((state: RootState) => state.debit.codMoneda)
    const nroTarjeta = useSelector((state: RootState) => state.debit.tarjetaRegistrada)
    const fechaOperacion = useSelector((state: RootState) => state.debit.fechaRegistro)

    const handleGoAffiliation = () => {
        history(`/property/${proforma}/debit`)
    }

    const handleGoHome = () => {
        history('/')
    }

    return (
        <React.Fragment>
            <SuccessWrapper>
                <SuccessContainer>
                    <LogoContainer>
                        <Logo src={success} />
                    </LogoContainer>
                    <TitleContainer>
                        <Title>Afiliación exitosa</Title>
                    </TitleContainer>
                    <SubtitleContainer>
                        <Subtitle>Te hemos enviado un correo con la confirmación de tu afiliación al débito automático a {correoCliente.toLowerCase()}</Subtitle>
                    </SubtitleContainer>
                    <PaymentContainer>
                        <DetailContainer>
                            <HeaderContainer>
                                <OperationContainer>
                                    <Operation>Sobre el proyecto</Operation>
                                </OperationContainer>
                                <PrintContainer>
                                    <Print>Imprimir</Print>
                                </PrintContainer>
                            </HeaderContainer>
                            <BodyContainer>
                                <InfoContainer>
                                    <LabelContainer>
                                        <Label>Proyecto</Label>
                                    </LabelContainer>
                                    <ValueContainer>
                                        <Value>{locacionProyecto.toLowerCase()}</Value>
                                    </ValueContainer>
                                </InfoContainer>
                                <InfoContainer>
                                    <LabelContainer>
                                        <Label>Locación</Label>
                                    </LabelContainer>
                                    <ValueContainer>
                                        <Value>{ubicacionProyecto}</Value>
                                    </ValueContainer>
                                </InfoContainer>
                                <InfoContainer>
                                    <LabelContainer>
                                        <Label>Débito de monto mensual</Label>
                                    </LabelContainer>
                                    <ValueContainer>
                                        <Value>
                                            <NumberFormat
                                                prefix={moneda === 'USD' ? 'US$' : 'S/'}
                                                decimalSeparator='.'
                                                displayType='text'
                                                type='text'
                                                thousandSeparator={true}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                value={montoPagado}
                                            />
                                        </Value>
                                    </ValueContainer>
                                </InfoContainer>
                                <AccountContainer>
                                    <Account>Sobre tu tarjeta de afiliación</Account>
                                    <ClientContainer>
                                        <Client>
                                            <ClientLogo src={client} />
                                            <ClientText>Cliente</ClientText>
                                        </Client>
                                        <ClientWrapper>
                                            <NameContainer>
                                                <NameLabel>Nombre</NameLabel>
                                                <NameText>{nombreCliente.toLowerCase()}</NameText>
                                            </NameContainer>
                                        </ClientWrapper>
                                    </ClientContainer>
                                    <CardContainer>
                                        <Card>
                                            <CardLogo src={card} />
                                            <CardText>Tarjeta</CardText>
                                        </Card>
                                        <NumberContainer>
                                            <NumberLabel>Tarjeta de pago</NumberLabel>
                                            <NumberText>{nroTarjeta.replace('XXXXXX', '******')}</NumberText>
                                        </NumberContainer>
                                    </CardContainer>
                                </AccountContainer>
                                <OrderContainer>
                                    <DateContainer>
                                        <Date>{moment(fechaOperacion).format('DD/MM/YYYY')}</Date>
                                        <Time>{moment(fechaOperacion).format('HH:mm A')}</Time>
                                    </DateContainer>
                                </OrderContainer>
                            </BodyContainer>
                        </DetailContainer>
                    </PaymentContainer>
                    <ButtonContainer>
                        <Submit onClick={handleGoAffiliation}>Ver mi afiliación</Submit>
                        <Button onClick={handleGoHome}>Regresar al inicio</Button>
                    </ButtonContainer>
                </SuccessContainer>
            </SuccessWrapper>
        </React.Fragment>
    )
}

export default SuccessPage