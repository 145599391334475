import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate, NavLink, useLocation, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { RootState } from '../../store/modules'

import Menu from '../../components/Menu'
import Profile from '../../components/Profile'

import useMedia from '../../hooks/useMedia'

import { mediaQuery as media } from '../../utils/mediaQuery'

import creditCard from '../../assets/images/svg/ic-credit-card.svg'
import debit from '../../assets/images/svg/ic-debit.svg'
import file from '../../assets/images/svg/ic-file.svg'
import folder from '../../assets/images/svg/ic-folder.svg'
import eye from '../../assets/images/svg/ic-eye.svg'
import arrow from '../../assets/images/svg/ic-arrow-left-blue.svg'

type IProps = {
    children?: React.ReactNode
}

const LayoutWrapper = styled.div``

const LayoutContainer = styled.div`
    padding: 24px 16px;
    ${media.md`
        padding: 40px 16px;
        display: flex;
        align-items: stretch;
    `}
    ${media.lg`
        padding: 40px 140px;
    `}
`

const LinkContainer = styled.div`
    filter: drop-shadow(0px 8px 24px rgba(42, 55, 67, 0.12));
    background: #ffffff;
    padding: 15px 16px;
    ${media.lg`
        padding: 15px 140px;
    `}
`

const Link = styled(NavLink)`
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-decoration: none;
    color: #284FAE;
    &::before {
        content: '';
        width: 9px;
        height: 16px;
        margin-right: 12px;
        background: url(${arrow})
    }
`

const LayoutHeader = styled.div`
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 8px 24px rgba(42, 55, 67, 0.12);
    border-radius: 8px;
    ${media.md`
        max-width: 324px;
        max-height: 420px;
    `}
`

const HeaderContainer = styled.div`
    padding: 12px 12px 0px;
    ${media.md`
        padding: 0px;
    `}
`

const ProfileContainer = styled.div`
    padding-bottom: 24px;
    ${media.md`
        display: flex;
        justify-content: center;
        padding-top: 24px;
    `}
`

const MenuContainer = styled.div`
    margin: 0px -12px;
    ${media.md`
        margin: 0px;
    `}
`

const LayoutBody = styled.div`
    margin-top: 24px;
    ${media.md`
        width: 100%;
        margin-top: 0px;
        margin-left: 36px;
    `}
`

const BodyContainer = styled.div`
    width: 100%;
    height: 100%;
`

const QueryLayout = ({
    children
}: IProps) => {
    const { isMobile } = useMedia()
    const history = useNavigate()
    const { pathname } = useLocation()
    const nombreCliente = useSelector((state: RootState) => state.user.nombreCliente)

    const options = [
        {
            icon: debit,
            name: 'Constancias',
            redirect: `/query/record`
        },
        {
            icon: file,
            name: 'Observación sobre acabados de mi inmueble',
            redirect: `/query/note`
        },
        {
            icon: eye,
            name: 'Consulta - Solicitudes',
            redirect: `/query/request`
        },
        {
            icon: folder,
            name: 'Historial otras consultas',
            redirect: `/query/historic`
        }
    ]

    const getInitialSlide = () => {
        return options.findIndex(x => x.redirect === pathname)
    }

    const onBeforeChange = (next) => {
        history(options[next].redirect)
    }

    return (
        <React.Fragment>
            <LayoutWrapper>
                <LinkContainer>
                    <Link to='/'>
                        {!isMobile && 'Volver atrás'}
                    </Link>
                </LinkContainer>
                <LayoutContainer>
                    <LayoutHeader>
                        <HeaderContainer>
                            <ProfileContainer>
                                <Profile name={nombreCliente} />
                            </ProfileContainer>
                            <MenuContainer>
                                <Menu
                                    options={options}
                                    initialSlide={getInitialSlide()}
                                    onBeforeChange={(e) => onBeforeChange(e)}
                                />
                            </MenuContainer>
                        </HeaderContainer>
                    </LayoutHeader>
                    <LayoutBody>
                        <BodyContainer>
                            <Outlet />
                        </BodyContainer>
                    </LayoutBody>
                </LayoutContainer>
            </LayoutWrapper>
        </React.Fragment>
    )
}

QueryLayout.propTypes = {
    children: PropTypes.node
}

export default QueryLayout