import React from 'react'
import PropTypes from 'prop-types'
import Page from '../../containers/NotePage'

const Note = () => (
    <React.Fragment>
        <Page />
    </React.Fragment>
)

Note.propTypes = {
    children: PropTypes.node
}

export default Note