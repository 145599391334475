import React from 'react'
import styled from 'styled-components'

import { mediaQuery as media } from '../../utils/mediaQuery'

import close from '../../assets/images/svg/ic-close.svg'

interface IProps {
    text: string
    icon: string
    show: boolean
    onClose: () => void
}

const Component = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #EBF4EC;
    padding: 12px 16px;
    position: relative;
    ${media.lg`
        padding: 12px 140px;
    `}
`

const IconContainer = styled.div`
    display: flex;
`

const Icon = styled.img``

const TextContainer = styled.div`
    margin-left: 8px;
`

const Text = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #36393D;
`

const CloseContainer = styled.div`
    top: calc(50% - 8px);
    right: 16px;
    cursor: pointer;
    position: absolute;
    ${media.lg`
        right: 140px;
    `}
`

const Notification = ({
    icon,
    show,
    text,
    onClose
}: IProps) => {
    return (
        <React.Fragment>
            {show && (
                <Component>
                    <IconContainer>
                        <Icon src={icon} />
                    </IconContainer>
                    <TextContainer>
                        <Text>{text}</Text>
                    </TextContainer>
                    <CloseContainer onClick={onClose}>
                        <Icon src={close} />
                    </CloseContainer>
                </Component>
            )}
        </React.Fragment>
    )
}

export default Notification