import React from 'react'
import styled from 'styled-components'

import { mediaQuery as media } from '../../utils/mediaQuery'

interface IProps {
    interlocutor: string
    date: string
    content: string
}

const CommentContainer = styled.div`
    margin-top: 23px;
`

const CommentWrapper = styled.div`
    background: #FFFFFF;
    border: 1px solid #93A7D5;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 17px 10px;
`

const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    position: relative;
`

const TitleContainer = styled.div`
    padding-right: 8px;
`

const Title = styled.div`
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: #36393D;
    ${media.lg`
        font-size: 16px;
        line-height: 24px;
    `}
`

const SubtitleContainer = styled.div`
    padding-left: 8px;
    border-left: 1px solid #36393D;
`

const Subtitle = styled.div`
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #36393D;
`

const BodyContainer = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 8px 24px rgba(42, 55, 67, 0.12);
    border-radius: 8px;
    padding: 15px;
    margin-top: 23px;
`

const Content = styled.p`
    margin: 0px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #36393D;
`

const Comment = ({
    interlocutor,
    content,
    date
}: IProps) => {
    return (
        <CommentContainer>
            <CommentWrapper>
                <HeaderContainer>
                    <TitleContainer>
                        <Title>{interlocutor}</Title>
                    </TitleContainer>
                    <SubtitleContainer>
                        <Subtitle>{date}</Subtitle>
                    </SubtitleContainer>
                </HeaderContainer>
                <BodyContainer>
                    <Content>
                        {content.split('\n').map((line, i) => (
                            <span key={i}>
                                {line}
                                <br />
                            </span>
                        ))}
                    </Content>
                </BodyContainer>
            </CommentWrapper>
        </CommentContainer>
    )
}

export default Comment