import React from 'react'
import { Route, Routes } from 'react-router-dom'

import MainLayout from '../../layouts/Main'
import AdminLayout from '../../layouts/Admin'
import DebitLayout from '../../layouts/Debit'
import QueryLayout from '../../layouts/Query'

import Login from '../../pages/Login'
import LoginMiCuenta from '../../pages/LoginMiCuenta'
import RecuperarContrasena from '../../pages/RecuperarContrasena'
import Register from '../../pages/Register'
import Home from '../../pages/Home'
import Notice from '../../pages/Notice'
import Payment from '../../pages/Payment'
import Historic from '../../pages/Historic'
import Document from '../../pages/Document'
import Verification from '../../pages/Verification'
import Checkout from '../../pages/Checkout'
import DInternal from '../../pages/Debit/Internal'
import DExternal from '../../pages/Debit/External'
import DConfirmation from '../../pages/Debit/Confirmation'
import DPayment from '../../pages/Debit/Payment'
import DSuccess from '../../pages/Debit/Success'
import Prepayment from '../../pages/Prepayment'
import AList from '../../pages/Another/List'
import ADetail from '../../pages/Another/Detail'
import RList from '../../pages/Request/List'
import RNew from '../../pages/Request/New'
import RDetail from '../../pages/Request/Detail'
import Record from '../../pages/Record'
import Note from '../../pages/Note'
import Advance from '../../pages/Advance'

import PrivateRoute from './private'

export const Router = () => {
    return (
        <Routes>
            <Route element={<MainLayout />}>
                <Route path='/' element={<Home />} />
                <Route path='/login' element={<LoginMiCuenta />} />
                <Route path='/MiCuenta/recuperar-mi-contrasena/:id' element={<RecuperarContrasena />} />
                <Route path='/MiCuenta/Registro' element={<Register />} />
                <Route path='/verification' element={<Verification />} />
                <Route element={<PrivateRoute />}>
                    <Route path='/debit-automatic' element={<DExternal />} />
                    <Route element={<QueryLayout />}>
                        <Route path='query'>
                            <Route path='prepayment' element={<Prepayment />} />
                            <Route path='record' element={<Record />} />
                            <Route path='note' element={<Note />} />
                            <Route path='request'>
                                <Route path='' element={<RList />} />
                                <Route path=':id' element={<RDetail />} />
                                <Route path='new' element={<RNew />} />
                            </Route>
                            <Route path='historic'>
                                <Route path='' element={<AList />} />
                                <Route path=':id' element={<ADetail />} />
                            </Route>
                        </Route>
                    </Route>
                    <Route element={<AdminLayout />}>
                        <Route path='property'>
                            <Route path=':id/payment' element={<Payment />} />
                            <Route path=':id/debit' element={<DInternal />} />
                            <Route path=':id/historic' element={<Historic />} />
                            <Route path=':id/advance' element={<Advance />} />
                            <Route path=':id/documents' element={<Document />} />
                            <Route path=':id/notices' element={<Notice />} />
                        </Route>
                    </Route>
                    <Route path='checkout'>
                        <Route path=':id' element={<Checkout />} />
                    </Route>
                    <Route element={<DebitLayout />}>
                        <Route path='debit'>
                            <Route path=':id/confirmation' element={<DConfirmation />} />
                            <Route path=':id/payment' element={<DPayment />} />
                        </Route>
                    </Route>
                    <Route path='success'>
                        <Route path='debit' element={<DSuccess />} />
                    </Route>
                </Route>
            </Route>
        </Routes>
    )
}