import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { RootState } from '../../../store/modules'

import Header from '../../../components/Welcome'
import Collapse from '../../../components/Collapse'

import { fetchProject, fetchProduct } from '../../../store/modules/home/actions'
import { fetchAuthUserSuccess } from '../../../store/modules/user/actions'
import { fetchProductReset } from '../../../store/modules/product/actions'
import { fetchHideLoading, fetchShowLoading } from '../../../store/modules/layout/actions'

import { projectServices } from '../../../services/projectServices'

import { mediaQuery as media } from '../../../utils/mediaQuery'
import { normalizeProject } from '../../../utils/schemas/home'

import house from '../../../assets/images/svg/ic-house.svg'

const HomeWrapper = styled.div``

const HomeContainer = styled.div``

const HomeHeader = styled.div``

const HomeBody = styled.div`
    padding: 60px 16px;
    ${media.lg`
        padding: 60px 140px;
    `}
`

const HomePage = () => {
    const history = useNavigate()
    const dispatch = useDispatch()
    const tipoDocumento = useSelector((state: RootState) => state.user.tipoDocumento)
    const nroDocumento = useSelector((state: RootState) => state.user.nroDocumento)
    const nombreCliente = useSelector((state: RootState) => state.user.nombreCliente)
    const ubications = useSelector((state: RootState) => state.location.ubications)

    const [projectList, setProjectList] = React.useState<any>([])

    React.useEffect(() => {
        dispatch(fetchShowLoading())
        projectServices.getLetter(tipoDocumento, nroDocumento).then(response => {
            const { ZWS_CONSULTA_CLIENTEResponse } = response.data
            setProjectList(normalizeProject(ZWS_CONSULTA_CLIENTEResponse, ubications))
            dispatch(fetchProductReset())
            dispatch(fetchProject(ZWS_CONSULTA_CLIENTEResponse.t_ESTACCField))
            dispatch(fetchProduct(ZWS_CONSULTA_CLIENTEResponse.t_PRODUCTOSField))
            dispatch(fetchAuthUserSuccess(ZWS_CONSULTA_CLIENTEResponse.e_GENERALField))
            dispatch(fetchHideLoading())
        }).catch(_ => {
            dispatch(fetchHideLoading())
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleDetail = (code) => {
        history(`/property/${code}/payment`)
    }

    const handleQuery = () => {
        history('/query/request')
    }

    const handleDebitAutomatic = () => {
        history('/debit-automatic')
    }

    return (
        <React.Fragment>
            <HomeWrapper>
                <HomeContainer>
                    <HomeHeader>
                        <Header
                            onQuery={handleQuery}
                            onDebit={handleDebitAutomatic}
                            name={nombreCliente}
                        />
                    </HomeHeader>
                    <HomeBody>
                        <Collapse
                            icon={house}
                            title='PAGO DE LETRAS'
                            subtitle=''
                            options={projectList}
                            onClick={(e) => handleDetail(e)}
                        />
                    </HomeBody>
                </HomeContainer>
            </HomeWrapper>
        </React.Fragment>
    )
}

export default HomePage