import React from 'react'
import styled from 'styled-components'

interface ICollage {
    width: Array<string>;
    height?: string;
    layout: Array<number>;
    layoutPhotoMaps: any;
    layoutNum: number;
    remainingNum: number;
    showRemaining: boolean;
    openLightbox: any;
}

interface IPhoto {
    width: string;
    photos: any;
    openLightbox: any;
    layoutNum: number;
    remainingNum: number;
    showRemaining: boolean;
}


const PhotoCollage = styled.div<{
    rowHeight: string
}>`
    width: 100%;
    height: ${props => props.rowHeight};
    display: flex;
`

const Row = styled.div<{
    width: string
}>`
    width: ${props => props.width};
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    box-sizing: border-box;
    & + & {
        margin-left: 12px;
        & > div:last-child {
            margin-top: 12px;
        }
    }
`;

const PhotoGrid = styled.div`
    display: flex;
    position: relative;
    flex: 1;
    cursor: pointer;
    border-radius: 8px;
`;

const PhotoThumb = styled.div<{
    thumb: string
}>`
    flex: 1;
    background-image: url(${props => props.thumb});
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    border-radius: 8px;
`;

const PhotoMask = styled.div`
    display: block;
    background-color: #284FAE;
    mix-blend-mode: normal;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
    cursor: pointer;
    border-radius: 8px;
`;

const Remaining = styled.div`
    position: absolute;
    color: #fff;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -120%);
    &:before {
        content: '+';
    }
`;

const Button = styled.div`
    position: absolute;
    color: #fff;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 10px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, 0%);
`

const ViewMore = styled.div`
    width: 100%; 
    height: 100%; 
    position: absolute; 
    z-index: 1; 
    cursor: pointer;
`;


const Photo = (props: IPhoto) => {
    const { width, photos, layoutNum, remainingNum, showRemaining, openLightbox } = props;
    return (
        <Row width={width}>
            {
                photos.map((data, i) => {
                    return (
                        <PhotoGrid key={i} data-id={data.id} onClick={e => openLightbox(e.currentTarget.dataset.id)}>
                            {
                                showRemaining && remainingNum > 0 && data.id === (layoutNum - 1) ?
                                    (
                                        <React.Fragment>
                                            <PhotoMask />
                                            <ViewMore>
                                                <Remaining>{remainingNum}</Remaining>
                                                <Button>Ver más fotos</Button>
                                            </ViewMore>
                                        </React.Fragment>
                                    ) : null
                            }
                            <PhotoThumb thumb={data.source}></PhotoThumb>
                        </PhotoGrid>
                    )
                })
            }
        </Row>
    )
}

const Collage = (props: ICollage) => {
    const { width, height, layout, layoutPhotoMaps, layoutNum, remainingNum, showRemaining, openLightbox } = props;
    return (
        <PhotoCollage rowHeight={height ?? '200px'}>
            {
                layout.map((data, i) => {
                    return (
                        <Photo
                            key={i}
                            width={width[i]}
                            photos={layoutPhotoMaps[i]}
                            openLightbox={openLightbox}
                            layoutNum={layoutNum}
                            remainingNum={remainingNum}
                            showRemaining={showRemaining}
                        />
                    )
                })
            }
        </PhotoCollage>
    )
}

export default Collage