export const LOADING_SHOW = 'vivienda/layout/LOADING_SHOW'
export const LOADING_HIDE = 'vivienda/layout/LOADING_HIDE'
export const NOTIFICATION_SHOW = 'vivienda/layout/NOTIFICATION_SHOW'
export const NOTIFICATION_HIDE = 'vivienda/layout/NOTIFICATION_HIDE'

export function fetchShowLoading() {
  return {
    type: LOADING_SHOW
  }
}

export function fetchHideLoading() {
  return {
    type: LOADING_HIDE
  }
}

export function fetchShowNotification() {
  return {
    type: NOTIFICATION_SHOW
  }
}

export function fetchHideNotification() {
  return {
    type: NOTIFICATION_HIDE
  }
}