import React from 'react'
import PropTypes from 'prop-types'
import Page from '../../containers/DocumentPage'

const Document = () => (
    <React.Fragment>
        <Page />
    </React.Fragment>
)

Document.propTypes = {
    children: PropTypes.node
}

export default Document