import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { RootState } from '../../store/modules'

import FileList from '../../components/File'

import { fetchHideLoading, fetchShowLoading } from '../../store/modules/layout/actions'

import { schema } from './validations'
import { mediaQuery as media } from '../../utils/mediaQuery'
import { normalizeIncidence } from '../../utils/schemas/note'

import arrow from '../../assets/images/arrow-down-blue.png'
import close from '../../assets/images/svg/ic-close-green.svg'

import { projectServices } from '../../services/projectServices'

const MySwal = withReactContent(Swal)

const NoteWrapper = styled.div``

const NoteContainer = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 8px 24px rgba(42, 55, 67, 0.12);
    border-radius: 8px;
    padding: 19px;
    ${media.md`
        padding: 24px 24px 52px;
    `}
`

const HeaderContainer = styled.div``

const TitleContainer = styled.div``

const Title = styled.div`
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    color: #284FAE;
`

const BodyWrapper = styled.div`
    margin-top: 15px;
    ${media.md`
        background: #FFFFFF;
        box-shadow: 0px 8px 24px rgba(42, 55, 67, 0.12);
        border-radius: 8px;
        margin-top: 24px;
        padding: 10px 41px 18px;
    `}
`

const BodyContainer = styled.div`
    min-height: 395px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const InputGroupContainer = styled.div`
    margin-top: 10px;
    ${media.md`
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 17px;
    `}
`

const LabelContainer = styled.div`
    width: 100%;
    max-width: 195px;
    margin-bottom: 12px;
    ${media.md`
        margin-bottom: 0px;
    `}
`

const Label = styled.label`
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #3D61B3;
`

const DropdownContainer = styled.div`
    width: 100%;
`

const Dropdown = styled.select`
    width: 100%;
    height: 55px;
    border: 1px solid #284FAE;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 15px 46px 15px 12px;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #284FAE;
    position: relative;
    appearance: none;
    cursor: pointer;
    background: url(${arrow}) no-repeat;
    background-position: calc(100% - 15px) center;
    &:focus {
        outline: none;
    }
`

const Option = styled.option``

const FormContainer = styled.div`
    margin-top: 20px;
    ${media.md`
        margin-top: 33px;
    `}
`

const ProjectContainer = styled.div``

const Project = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #284FAE;
`

const InputContainer = styled.div`
    width: 100%;
`

const Input = styled.input<{
    error: boolean
}>`
    width: 100%;
    height: 55px;
    border: 1px solid ${props => props.error ? '#970C0C' : '#284FAE'};
    background: ${props => props.error ? '#F6EEEC' : '#FFFFFF'};
    box-sizing: border-box;
    border-radius: 4px;
    padding: 15px 12px;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #284FAE;
    position: relative;
    &:focus {
        outline: none;
    }
`

const TextAreaContainer = styled.div`
    width: 100%;
`

const TextArea = styled.textarea<{
    error: boolean
}>`
    width: 100%;
    border: 1px solid ${props => props.error ? '#970C0C' : '#284FAE'};
    background: ${props => props.error ? '#F6EEEC' : '#FFFFFF'};
    box-sizing: border-box;
    border-radius: 4px;
    padding: 15px 12px;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #284FAE;
    position: relative;
    &:focus {
        outline: none;
    }
`

const ButtonContainer = styled.div`
    text-align: center;
    margin-top: 20px;
    ${media.md`
        margin-top: 57px;
    `}
`

const Button = styled.button`
    width: 100%;
    height: 48px;
    background: #CC26BA;
    border: 1px solid #CC26BA;
    border-radius: 4px;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    cursor: pointer;
    &:focus {
        outline: none;
    }
    ${media.md`
        max-width: 364px;
    `}
`

const MessageContainer = styled.div`
    background: #EBF4EC;
    border: 0.5px solid #C1D5C3;
    box-sizing: border-box;
    border-radius: 3px;
    margin-top: 20px;
    position: relative;
    padding: 16px 44px 16px 16px;
    ${media.md`
        width: 50%;
        max-width: 364px;
    `}
`

const Message = styled.div`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: #2D7738;
`

const Close = styled.a`
    position: absolute;
    right: 10px;
    top: calc(50% - 8px);
    cursor: pointer;
`


const MessageContainerError = styled.div`
    background: #F8D8D8;
    border: 0.5px solid #E97070;
    box-sizing: border-box;
    border-radius: 3px;
    margin-top: 20px;
    position: relative;
    padding: 16px 44px 16px 16px;
    ${media.md`
        width: 50%;
        max-width: 364px;
    `}
`
const MessageError = styled.div`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: #E40D0D;
`

const NotePage = () => {
    const dispatch = useDispatch()
    const tipoDocumento = useSelector((state: RootState) => state.user.tipoDocumento)
    const nroDocumento = useSelector((state: RootState) => state.user.nroDocumento)
    const productList = useSelector((state: RootState) => state.home.productList)
    const user = useSelector((state: RootState) => state.user)
    const [errorMessage, setErrorMessage] = React.useState('')
    const [error, setError] = React.useState(false)
    const config = localStorage.getItem('config') ? JSON.parse(localStorage.getItem('config')!) : { 'pesoMaximoArchivoMB': 10 }

    const methods = useForm({
        mode: 'onChange',
        reValidateMode: 'onSubmit',
        resolver: yupResolver(schema)
    })
    const { watch, reset, setValue, register, handleSubmit, formState: { errors } } = methods

    const [product, setProduct] = React.useState<any>(null)
    const [show, setShow] = React.useState(false)
    const [success, setSuccess] = React.useState(false)
    const [files, setFiles] = React.useState<any>([
        { base64: '', fileName: '' },
        { base64: '', fileName: '' },
        { base64: '', fileName: '' },
        { base64: '', fileName: '' },
        { base64: '', fileName: '' },
    ])

    React.useEffect(() => {
        const value = watch('project')
        if (value) {
            let nroCellPhone = user.tELEFONOField
            if (user.tELEFONOField) {
              nroCellPhone =  user.tELEFONOField.trim()
              if(nroCellPhone > 9 && nroCellPhone.startsWith("51")) {
                nroCellPhone = nroCellPhone.substring(2)
              }
            }
            setValue('cellphone', nroCellPhone)
            setValue('email', user.eMAILField)
            setProduct(productList.find(x => x.pROFORMAField === watch('project')))
        }
        setShow(value)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch('project')])

    const onSubmit = (data) => {
        dispatch(fetchShowLoading())
        projectServices.postSendIncidence(normalizeIncidence(tipoDocumento, nroDocumento, data, product, files)).then(response => {
            if(response.data.response.error!=null){
                console.log("SendIncidentes");
                console.log(response.data);
                setErrorMessage(response.data.response.error);
                setError(true);
                setTimeout(() => {
                    setError(false)
                }, 4000)     
            }else{
                reset()
                setSuccess(true)
                setTimeout(() => {
                    setSuccess(false)
                }, 3000)
            }
            dispatch(fetchHideLoading())
        }).catch(error => {
            if (error.response && error.response.status === 400 ) {                
                MySwal.fire({
                    title: <strong>Error!</strong>,
                    html: <i>{error.response.data && typeof error.response.data === 'string' ? error.response.data : "Los datos enviados no son válidos" }</i>,
                    icon: 'error'
                  });
                dispatch(fetchHideLoading());
                return;
              }

            if (error.message === 'Cannot read properties of undefined (reading \'status\')') {
                MySwal.fire({
                    title: <strong>Error!</strong>,
                    html: <i>{`No se pudo enviar la consulta. Es posible que el archivo cargado supere el límite permitido de ${config.pesoMaximoArchivoMB}MB` }</i>,
                    icon: 'error'
                  });
                dispatch(fetchHideLoading());
                return;
              }
            
              MySwal.fire({
                title: <strong>Error!</strong>,
                html: <i>{`Sucedió un error inesperado. Contacte con soporte técnico` }</i>,
                icon: 'error'
              });
              dispatch(fetchHideLoading());
        })
    }

    const onChangeFile = (index, value) => {
        if (value.length) {
            var reader = new FileReader()
            reader.readAsDataURL(value[0])
            reader.onload = function (upload) {
                const file = upload.target?.result
                files[index] = { base64: file, fileName: value[0].name }
                setFiles([...files])
            };
        } else {
            files[index] = { base64: '', fileName: '' }
            setFiles([...files])
        }
    }

    return (
        <NoteWrapper>
            <NoteContainer>
                <HeaderContainer>
                    <TitleContainer>
                        <Title>Reclamo sobre acabados en inmueble</Title>
                    </TitleContainer>
                </HeaderContainer>
                <BodyWrapper>
                    <FormProvider {...methods}>
                        <form onSubmit={handleSubmit(onSubmit)} encType='multipart/form-data'>
                            <BodyContainer>
                                <InputGroupContainer>
                                    <LabelContainer>
                                        <Label>Elegir proforma:</Label>
                                    </LabelContainer>
                                    <DropdownContainer>
                                        <Dropdown
                                            {...register('project')}
                                        >
                                            <Option value=''>Seleccionar</Option>
                                            {productList.map(item => (
                                                <Option key={item.pROFORMAField} value={item.pROFORMAField}>{item.pROFORMAField} - {item.pROYECTOField}</Option>
                                            ))}
                                        </Dropdown>
                                    </DropdownContainer>
                                </InputGroupContainer>
                                {show && (
                                    <FormContainer>
                                        <ProjectContainer>
                                            <Project>{product.pROYECTOField}</Project>
                                        </ProjectContainer>
                                        <InputGroupContainer>
                                            <LabelContainer>
                                                <Label>Celular de contacto:</Label>
                                            </LabelContainer>
                                            <InputContainer>
                                                <Input
                                                    error={errors.cellphone}
                                                    {...register('cellphone')}
                                                />
                                            </InputContainer>
                                        </InputGroupContainer>
                                        <InputGroupContainer>
                                            <LabelContainer>
                                                <Label>Email de contacto:</Label>
                                            </LabelContainer>
                                            <InputContainer>
                                                <Input
                                                    error={errors.email}
                                                    {...register('email')}
                                                />
                                            </InputContainer>
                                        </InputGroupContainer>
                                        <InputGroupContainer style={{ alignItems: 'flex-start' }}>
                                            <LabelContainer>
                                                <Label>Detalle de observación:</Label>
                                            </LabelContainer>
                                            <TextAreaContainer>
                                                <TextArea
                                                    rows={4}
                                                    error={errors.observation}
                                                    {...register('observation')}
                                                />
                                            </TextAreaContainer>
                                        </InputGroupContainer>
                                        <InputGroupContainer style={{ alignItems: 'flex-start' }}>
                                            <LabelContainer>
                                                <Label>Fotos adjuntas:</Label>
                                                <Label>(Máximo 5 fotos)</Label>
                                            </LabelContainer>
                                            <FileList onChangeFile={onChangeFile} />
                                        </InputGroupContainer>
                                        <ButtonContainer>
                                            <Button type='submit'>Enviar</Button>
                                        </ButtonContainer>
                                    </FormContainer>
                                )}
                                {success && (
                                    <MessageContainer>
                                        <Message>Se registro la consulta correctamente.</Message>
                                        <Close onClick={() => setSuccess(false)}><img src={close} alt='' /></Close>
                                    </MessageContainer>
                                )}
                                {error && (
                                    <MessageContainerError>
                                        <MessageError>{errorMessage}</MessageError>
                                        <Close onClick={() => setError(false)}><img src={close} alt='' /></Close>
                                    </MessageContainerError>
                                )}
                            </BodyContainer>
                        </form>
                    </FormProvider>
                </BodyWrapper>
            </NoteContainer>
        </NoteWrapper>
    )
}

export default NotePage