import React from 'react'
import moment from 'moment'
import NumberFormat from 'react-number-format'
import styled from 'styled-components'

import { mediaQuery as media } from '../../../utils/mediaQuery'

import document from '../../../assets/images/svg/ic-document.svg'

interface IProps {
    type: string
    symbol: string
    documentNumber: string
    amount: number
    expiration: string
    onClick: () => void
}

const BodyWrapper = styled.div`
    border: 1px solid rgba(0, 104, 172, 0.2);
    border-radius: 8px;
    padding: 16px 18px;
    &:not(:last-child) {
        margin-bottom: 16px;
    }
    ${media.sm`
        padding: 0px;
    `}
`

const BodyContainer = styled.div`
    ${media.sm`
        display: flex;
        align-items: center;
        justify-content: space-between;
    `}
`

const InfoContainer = styled.div`
    width: 100%;
    ${media.sm`
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 16px;
    `}
`

const DocumentContainer = styled.div`
    display: flex;
    align-items: center;
`

const IconContainer = styled.div`
    display: flex;
`

const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    ${media.sm`
        flex-direction: row;
        align-items: center;
    `}
`

const TypeContainer = styled.div<{
    value: string
}>`
    width: fit-content;
    margin-top: 8px;
    padding: 4px 8px;
    background: ${props => props.value === 'R1' ? '#F6A41A' : '#C200AE'};
    border: 1px solid ${props => props.value === 'R1' ? '#F6A41A' : '#C200AE'};
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 16px;
    margin-right: 20px;
    ${media.sm`
        margin-top: 0px;
        margin-left: 25px;
        margin-bottom: 0px;
    `}
`

const Type = styled.div`
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
`

const Icon = styled.img``

const CodeContainer = styled.div`
    margin-left: 8px;
`

const TextContainer = styled.div``

const Text = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;  
    color: #82949E;  
`

const NumberContainer = styled.div``

const Number = styled.div`
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: #36393D;
`

const DetailContainer = styled.div``

const DateContainer = styled.div`
    padding-bottom: 16px;
    ${media.sm`
        padding-bottom: 0px;
    `}
`

const Dates = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #82949E;
    margin-top: 4px;
    ${media.sm`
        text-align: right;
    `}
`

const AmountContainer = styled.div`
    display: flex;
    ${media.sm`
        padding-bottom: 0px;
        justify-content: flex-end;
    `}
`

const Amount = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #36393D;
    &::before {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 8px;
        display: inline-block;
        background: #F6A41A;
    }
`

const PaymentContainer = styled.div`
    padding-top: 16px;
    border-top: 1px solid #82949E;
    ${media.sm`
        padding: 18px 16px;
        border-top: none;
        border-left: 1px solid #82949E;
    `}
`

const Payment = styled.a`
    display: block;
    padding: 10px;
    text-align: center;
    background: #C200AE;
    border-radius: 4px;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    text-decoration: none;
    color: #FFFFFF;
    cursor: pointer;
    ${media.sm`
        width: inherit;
        padding: 10px 20px;
    `}
`

const Body = ({
    type,
    symbol,
    amount,
    onClick,
    expiration,
    documentNumber
}: IProps) => {
    return (
        <BodyWrapper>
            <BodyContainer>
                <InfoContainer>
                    <InfoWrapper>
                        <TypeContainer value={type}>
                            <Type>{type === 'R1' ? 'Separa tu lote' : 'Pago Inicial'}</Type>
                        </TypeContainer>
                        <DocumentContainer>
                            <IconContainer>
                                <Icon src={document} />
                            </IconContainer>
                            <CodeContainer>
                                <TextContainer>
                                    <Text>N° documento</Text>
                                </TextContainer>
                                <NumberContainer>
                                    <Number>{documentNumber}</Number>
                                </NumberContainer>
                            </CodeContainer>
                        </DocumentContainer>                        
                    </InfoWrapper>
                    <DetailContainer>
                        <AmountContainer>
                            <Amount>
                                <NumberFormat
                                    prefix={symbol === 'USD' ? 'US$' : 'S/'}
                                    decimalSeparator='.'
                                    displayType='text'
                                    type='text'
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    value={amount}
                                />
                            </Amount>
                        </AmountContainer>
                        <DateContainer>
                            <Dates>
                                Fecha de vencimiento: {moment(expiration).format('DD/MM/YYYY')}
                            </Dates>
                        </DateContainer>
                    </DetailContainer>
                </InfoContainer>
                <PaymentContainer>
                    <Payment onClick={onClick}>Pagar</Payment>
                </PaymentContainer>
            </BodyContainer>
        </BodyWrapper>
    )
}

export default Body
