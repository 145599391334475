import {
    AUTH_USER_SUCCESS
} from './actions'

const initialState = {
    countryCode: '51',
    nroCellphone: '',
    nombreCliente: '',
    nroDocumento: '',
    tipoDocumento: ''
}

export function reducer(state = initialState, action: any) {
    switch (action.type) {
        case AUTH_USER_SUCCESS:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}