import moment from "moment"

export const normalizeAmountPending = (list, idProforma) => {
    return list
        .filter(x => x.pROFORMAField === idProforma && x.eSTADOLETRAField !== 'PAGADA')
        .map(x => x.iMPORTEField)
        .reduce((prev, next) => prev + next);
}

export const normalizePrepayment = (tipoDocumento, nroDocumento, project, data) => {
    return {
        tipoDocumento: tipoDocumento,
        nroDocumento: nroDocumento,
        proforma: project.pROFORMAField,
        fecha: moment(data.date).format('DD/MM/YYYY'),
        tipomoneda: project.wAERSField,
        montomoneda: data.amount,
        letras: `${data.fee}`
    }
}

export const normalizePrepaymentCuotas = (tipoDocumento, nroDocumento, project, data,cuotas,monto,productList,user) => {
    return {
        prepagoLetras:{
        tipoDocumento: tipoDocumento,
        nroDocumento: nroDocumento,
        proforma: project.pROFORMAField,
        fecha: moment(data.date).format('DD/MM/YYYY'),
        tipomoneda: project.wAERSField,
        montomoneda: data.amount,
        letras: `${data.fee}`,
        cuotas:cuotas,
        monto:monto,
        productList:productList,
        user:user
       }
    }
}

export const normalizeFilename = (cliente, proforma) => {
    return `${'constancia_de_cuotas_simuladas'}_${cliente}_${proforma}.pdf`.toLocaleLowerCase().replaceAll(' ', '_')
}

export const normalizeRefinancing = (list) => {
    const filter = list
        .filter(x => x.pAGADAField.toUpperCase() !== 'X')
    
    return {
        filterList: filter,
        amountFee: parseFloat(filter[1].iMP_CUOTAField.trim()) ?? parseFloat(filter[0].iMP_CUOTAField.trim())
    }
}