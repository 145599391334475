import axios from 'axios'

export const API_BASE_URL = process.env.REACT_APP_URL_GOOGLE_MAPS

// API Config
const api = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
})

export default api