import { css, CSSObject, FlattenSimpleInterpolation, SimpleInterpolation } from 'styled-components'

type ObjectMap<T> = { [key: string]: T }

const breakpoints: ObjectMap<number> = {
  xs: 320,
  sm: 768,
  md: 1024,
  lg: 1280,
  xl: 1920,
}

export const mediaQuery = Object.keys(breakpoints).reduce(
  (
    acc: {
      [key: string]: (
        first: TemplateStringsArray | CSSObject,
        ...interpolations: SimpleInterpolation[]
      ) => FlattenSimpleInterpolation
    },
    label
  ) => {
    acc[label] = (...args) => css`
      @media (min-width: ${breakpoints[label]}px) {
        ${css(...args)};
      }
    `
    return acc
  },
  {}
)