import {
  LOADING_SHOW,
  LOADING_HIDE,
  NOTIFICATION_SHOW,
  NOTIFICATION_HIDE
} from './actions'

const initialState = {
  notification: false,
  loading: false,
  error: false,
  errorMessage: ''
}

export function reducer(state = initialState, action: any) {
  switch (action.type) {
    case LOADING_SHOW:
      return {
        ...state,
        loading: true
      }
    case LOADING_HIDE:
      return {
        ...state,
        loading: false
      }
    case NOTIFICATION_SHOW:
      return {
        ...state,
        notification: true
      }
    case NOTIFICATION_HIDE:
      return {
        ...state,
        notification: false
      }
    default:
      return state
  }
}