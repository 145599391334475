import axios from 'axios'
//NOTE: 19/12/2022 se dejó de usa implementación en AWS, se pasó al propio backend de este desarrollo
export const API_BASE_URL = process.env.REACT_APP_URL_MICUENTA
// API Config
const api = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
})

export default api