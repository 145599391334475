import moment from "moment"
import 'moment/locale/es'

const normalizeDateCompleted = (list) => {
    const date = list
        .map(item => item.fECHAPAGORes)
        .filter((item, idx, array) => array.indexOf(item) === idx)
    return date
}

const normalizeDatePending = (list) => {
    const date = list
        .map(item => item.fECHAVENCIMIENTORes)
        .filter((item, idx, array) => array.indexOf(item) === idx)
    return date
}

const normalizeFilterCompleted = (list) => {
    moment.locale('es')
    const filter = list
        .map(item => {
            if(item.fECHAPAGOField === '0000-00-00') {
                item.fECHAPAGOField = moment(item.FechaOperacionIzi).format('YYYY-MM-DD')
            }
            return item
        })
        .sort((a, b) => {
            a = new Date(a.fECHAPAGOField);
            b = new Date(b.fECHAPAGOField);
            return a > b ? -1 : a < b ? 1 : 0;
        })
        .map(item => {
            return {
                ...item,
                fECHAPAGORes: moment(item.fECHAPAGOField).format('MMM YYYY').toUpperCase()
            }
        })
    return filter;
}

const normalizeFilterPending = (list) => {
    moment.locale('es')
    const filter = list
        .sort((a, b) => {
            a = new Date(a.fECHAVENCIMIENTOField);
            b = new Date(b.fECHAVENCIMIENTOField);
            return a < b ? -1 : a > b ? 1 : 0;
        })
        .map(item => {
            return {
                ...item,
                fECHAVENCIMIENTORes: moment(item.fECHAVENCIMIENTOField).format('MMM YYYY').toUpperCase()
            }
        })
    return filter;
}

export const normalizeGallery = (gallery) => {
    if(gallery) {
        return gallery
            .map(item => { return { source: `${item}` } })
    }
    return []
}

export const normalizeLocation = (location) => {
    if(location) {
        return {
            lat: parseFloat(location.split(',')[0]),
            lng: parseFloat(location.split(',')[1]),
        }
    }
    return null
}

export const normalizePending = (list) => {
    const filter = normalizeFilterPending(list)
    const projects = normalizeDatePending(filter)
    const header = projects.map(item => {
        return {
            name: item,
            symbol: filter[0].mONEDAField,
            amount: filter
                .filter(x => x.fECHAVENCIMIENTORes === item)
                .map(x => x.iMPORTEField)
                .reduce((prev, next) => prev + next),
            items: filter
                .filter(x => x.fECHAVENCIMIENTORes === item)
        }
    })
    return header
}

export const normalizeCompleted = (list) => {
    const filter = normalizeFilterCompleted(list)
    const projects = normalizeDateCompleted(filter)
    const header = projects.map(item => {
        return {
            name: item,
            symbol: filter[0].MonedaPago,
            amount: filter
                .filter(x => x.fECHAPAGORes === item)
                .map(x => x.ImportePago)
                .reduce((prev, next) => prev + next),
            items: filter
                .filter(x => x.fECHAPAGORes === item)
        }
    })
    return header
}