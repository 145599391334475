import React from 'react'
import { GoogleMap, withScriptjs, withGoogleMap, Marker } from 'react-google-maps'

const Map = (props: any) => (
    <GoogleMap
        defaultZoom={16}
        defaultCenter={props.location}
        center={props.location}
    >
        <Marker position={props.location} />
    </GoogleMap>
)

export default withScriptjs(withGoogleMap(Map))