import React from 'react'
import PropTypes from 'prop-types'
import Page from '../../containers/VerificationPage'

const Verification = () => (
    <React.Fragment>
        <Page />
    </React.Fragment>
)

Verification.propTypes = {
    children: PropTypes.node
}

export default Verification