import { 
    RESET_PRODUCT,
    SELECTED_PRODUCT 
} from "./actions"

const initialState = {
    BannerEmbed: null,
    GalleryItems: null,
    GoogleMap: null,
    Map: null,
    PropertyChanged: null,
    cODPLAZAField: null,
    cOD_PROYECTOField: null,
    fECHAField: null,
    pRODUCTOField: null,
    pROFORMAField: null,
    pROYECTOField: null,
    wAERSField: null,
}

export function reducer(state = initialState, action: any) {
    switch(action.type) {
        case SELECTED_PRODUCT:
            return {
                ...state,
                ...action.payload
            }
        case RESET_PRODUCT:
            return {
                BannerEmbed: null,
                GalleryItems: null,
                GoogleMap: null,
                Map: null,
                PropertyChanged: null,
                cODPLAZAField: null,
                cOD_PROYECTOField: null,
                fECHAField: null,
                pRODUCTOField: null,
                pROFORMAField: null,
                pROYECTOField: null,
                wAERSField: null,
            }
        default:
            return state
    }
}