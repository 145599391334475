import React from 'react'
import styled from 'styled-components'

import error from '../../assets/images/svg/ic-alert.svg'

interface IProps {
    type: 'error',
    message: string
}

const Component = styled.div<{
    type: string
}>`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 18px;
    background: #F6EEEC;
    border: 0.5px solid #CEA7A4;
    box-sizing: border-box;
    border-radius: 3px;
`

const Img = styled.img`
    margin-right: 15px;
`

const Paragraph = styled.p`
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: #970C0C;
    margin: 0px;
`

const Alert = ({
    type,
    message
}: IProps) => {
    return (
        <Component type={type}>
            <Img src={error} />
            <Paragraph>{message}</Paragraph>
        </Component>
    )
}

export default Alert