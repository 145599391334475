import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { RootState } from '../../../store'
import { fetchProject } from '../../../store/modules/home/actions'
import { fetchHideLoading, fetchShowLoading } from '../../../store/modules/layout/actions'

import { projectServices } from '../../../services/projectServices'

import { mediaQuery as media } from '../../../utils/mediaQuery'
import { normalizeReceipt2 } from '../../../utils/schemas/cash'

import ConfirmationPage from './ConfirmationPage'
import PaymentPage from './PaymentPage'
import SuccessPage from './SuccessPage'

const LayoutWrapper = styled.div`
    background: #C200AE;
`

const LayoutContainer = styled.div`
    padding-top: 34px;
    padding-bottom: 24px;
    ${media.md`
        padding-top: 64px;
        padding-bottom: 32px;
    `}
`

const StepperWrapper = styled.div`
    width: 100%;
    max-width: 520px;
    margin: 0px auto;
    margin-top: auto;
    display: flex;
    justify-content: space-between;
`

const StepperItem = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    &:not(:first-child)::before {
        content: '';
        position: absolute;
        border-bottom: 2px solid #EDB2E7;
        width: 100%;
        top: 35px;
        left: -50%;
        z-index: 2;
    }
`

const StepperName = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 4px;
`

const StepperCounter = styled.div`
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #C200AE;
    border: 2px solid #EDB2E7;
    margin-bottom: 6px;
    &.active {
        background: #F6A41A;
        border: 2px solid #F6A41A;
    }
    &.completed {
        background: #F6A41A;
        border: 2px solid #F6A41A;
        &:after {
            content: '';
            width: 5px;
            height: 2px;
            display: block;
            border: 2px solid #fff;
            -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
            border-right: 0;
            border-top: 0;
            position: absolute;
            left: 0;
            top: -3px;
            right: 1px;
            bottom: 0;
            margin: auto;
        }
    }
`

const LayoutBody = styled.div``

const CheckoutLayout = () => {
    const { id } = useParams()
    const history = useNavigate()
    const dispatch = useDispatch()
    const user = useSelector((state: RootState) => state.user)
    const tipoDocumento = useSelector((state: RootState) => state.user.tipoDocumento)
    const nroDocumento = useSelector((state: RootState) => state.user.nroDocumento)
    const projectList = useSelector((state: RootState) => state.home.projectList)
    const productList = useSelector((state: RootState) => state.home.productList)

    const [file, setFile] = React.useState<any>()
    const [step, setStep] = React.useState(1)
    const [document, setDocument] = React.useState<any>()
    const [transaction, setTransaction] = React.useState<any>()
    const [affiliation, setAffiliation] = React.useState<any>()
    const [exchange, setExchange] = React.useState<any>()

    React.useEffect(() => {
        dispatch(fetchShowLoading())
        const item = projectList.find(x => x.nLETRAField === id)
        const producto = productList.find(x => x.pROFORMAField === item.pROFORMAField)
        projectServices.postLetter(nroDocumento, [item]).then(result => {
            const newItem = result.data[0]
            projectServices.getAffiliation(tipoDocumento, nroDocumento).then(response => {
                const result = response.data
                setAffiliation(result.find(x => x.proforma === newItem.pROFORMAField))
                projectServices.getExchangeRate(tipoDocumento, nroDocumento, newItem.nLETRAField, `${newItem.bELNRField}${newItem.bUKRSField}${newItem.gJAHRField}`).then(response => {
                    const result = response.data
                    setExchange(result)
                    setDocument({ ...producto, ...newItem })
                    dispatch(fetchHideLoading())
                }).catch(_ => {
                    dispatch(fetchHideLoading())
                })
            }).catch(_ => {
                dispatch(fetchHideLoading())
            })
        }).catch(_ => {
            dispatch(fetchHideLoading())
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSubmit = (data) => {
        setDocument({ ...document, ...data })
        setStep(step + 1)
    }

    const handlePayment = (data) => {
        dispatch(fetchShowLoading())
        if (data.orderStatus === 'PAID') {
            projectServices.getReceipt(normalizeReceipt2(data, document, user)).then(response => {
                setFile(response.data)
                setTransaction(data)
                setStep(step + 1)
                dispatch(fetchHideLoading())
            }).catch(_ => {
                dispatch(fetchHideLoading())
            })
        }
    }

    const handleShared = async () => {
        const buffer = await file.arrayBuffer();
        const archive = new File([buffer], "Recibo_de_pago.pdf", { type: "application/pdf" });
        const filesArray = [archive];
        if (navigator.canShare && navigator.canShare({ files: filesArray })) {
            navigator
                .share({ files: filesArray, title: "Recibo_de_pago.pdf", text: 'Recibo de pago - Los Portales' })
                .then((response) => {
                    console.log('Descarga exitosa');
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            console.log(`Your system doesn't support sharing files.`);
        }
    }

    const handleReturn = () => {
        setStep(step - 1)
    }

    const handleBack = () => {
        history(-1)
    }

    const handleHome = () => {
        dispatch(fetchShowLoading())
        const item = projectList.find(x => x.nLETRAField === id)
        projectServices.postLetter(nroDocumento, [item]).then(result => {
            const list = [...projectList]
            const index = list.findIndex(x => x.nLETRAField === id)
            list[index] = result.data[0]
            dispatch(fetchProject(list))
            dispatch(fetchHideLoading())
            history(-1)
        }).catch(_ => {
            dispatch(fetchHideLoading())
        })
    }

    return (
        <React.Fragment>
            {step < 3 ? (
                <React.Fragment>
                    <LayoutWrapper>
                        <LayoutContainer>
                            <StepperWrapper>
                                <StepperItem>
                                    <StepperName>Confirma</StepperName>
                                    <StepperCounter className={`${step === 1 ? 'active' : 'completed'}`} />
                                </StepperItem>
                                <StepperItem>
                                    <StepperName>Paga</StepperName>
                                    <StepperCounter className={`${step === 2 ? 'active' : ''}`} />
                                </StepperItem>
                            </StepperWrapper>
                        </LayoutContainer>
                    </LayoutWrapper>
                    <LayoutBody>
                        {step === 1 && (
                            <ConfirmationPage
                                exchange={exchange}
                                document={document}
                                affiliation={affiliation}
                                onSubmit={handleSubmit}
                                onBack={handleBack}
                            />
                        )}
                        {step === 2 && (
                            <PaymentPage
                                user={user}
                                exchange={exchange}
                                document={document}
                                affiliation={affiliation}
                                onPayment={handlePayment}
                                onReturn={handleReturn}
                            />
                        )}
                    </LayoutBody>
                </React.Fragment>
            ) : (
                <SuccessPage
                    user={user}
                    exchange={exchange}
                    document={document}
                    transaction={transaction}
                    onHome={handleHome}
                    onShared={handleShared}
                />
            )}
        </React.Fragment>
    )
}

CheckoutLayout.propTypes = {
    children: PropTypes.node
}

export default CheckoutLayout