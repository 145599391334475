import React from "react";
import styled from "styled-components";
import { mediaQuery as media } from '../../utils/mediaQuery'

interface IProps {
    open: boolean
    onClose: () => void
    onClick: () => void
    children?: React.ReactNode
    title: string
}

const ModalOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 9;
  background: rgba(54, 57, 61, 0.7);
`;

const ModalContainer = styled.div`
    width: 100%;
    max-width: 318px;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 16px;
    box-sizing: border-box;
    ${media.md`
        max-width: 636px;
        padding: 40px 36px;
    `}
`;

const ModalHeader = styled.div`
  // Estilos para ModalHeader...
`;

const Title = styled.div`
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #36393D;
    ${media.md`
        font-size: 24px;
        line-height: 32px;
    `}
`;

const ModalBody = styled.div`
    margin-top: 16px;
`;

const CardContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 16px;
    ${media.md`
        margin-top: 20px;
    `}
`;

const ModalFooter = styled.div`
    margin-top: 24px;
    text-align: right;
    ${media.md`
        margin-top: 40px;
    `}
`;

const Cancel = styled.button`
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: #36393D;
    background: #FFFFFF;
    border: 1px solid #36393D;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
`

const Acept = styled.button`
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: white;
    background: #782cbf;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
`



const ModalRecovered = ({
  open,
  onClose,
  onClick,
  children,
  title,
}: IProps) => {
  return (
    <React.Fragment>
      {open && (
        <ModalOverlay>
          <ModalContainer>
            <ModalHeader>
              <Title>{ title }</Title>
            </ModalHeader>
            <ModalBody>
              <CardContainer>
                {children}
              </CardContainer>
            </ModalBody>
            <ModalFooter>
              <Cancel onClick={onClose} data-testid="recover-modal-close-button">Cancelar</Cancel>
              <Acept
                className="bg-purple-heart-500 text-white disabled:opacity-50 ml-4"
                onClick={onClick}
              >Enviar Correo</Acept>
            </ModalFooter>
          </ModalContainer>
        </ModalOverlay>
      )}
    </React.Fragment>
  );
};

export default ModalRecovered;
