import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react'
import { ReduxRouter } from '@lagunovsky/redux-react-router'

import createStore, { sagas } from './store'
import { sagaMiddleware } from './store/middleware'

import { Router } from './config/router'
import interceptor from './config/interceptor'
import { browserHistory } from './config/router/history'

import reportWebVitals from './reportWebVitals'

import './index.css'

const store = createStore()
sagaMiddleware.run(sagas)
interceptor.run(store)
export const persistor = persistStore(store)

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <React.StrictMode>
        <ReduxRouter history={browserHistory} store={store}>
          <Router />
        </ReduxRouter>
      </React.StrictMode>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
