import React from 'react'
import Collapsible from 'react-collapsible'
import { useNavigate, useParams } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { RootState } from '../../store/modules'
import { fetchHideLoading, fetchShowLoading } from '../../store/modules/layout/actions'

import Gallery from '../../components/Gallery'
import Pin from '../../components/Map'
import Empty from '../../components/Empty'
import Header from '../../components/Payment/Letter/Header'
import Completed from '../../components/Payment/Letter/Completed'
import Pending from '../../components/Payment/Letter/Pending'

import { API_KEY } from '../../utils/constants/payment'
import { mediaQuery as media } from '../../utils/mediaQuery'
import { normalizeCompleted, normalizeGallery, normalizeLocation, normalizePending } from '../../utils/schemas/admin'

import { projectServices } from '../../services/projectServices'

import cash from '../../assets/images/svg/ic-cash.svg'
import waze from '../../assets/images/bt-waze.png'
import gmap from '../../assets/images/bt-map.png'
import bill from '../../assets/images/svg/ic-bill.svg'
import credit from '../../assets/images/svg/ic-credit-card-yellow.svg'
import arrowDown from '../../assets/images/svg/ic-arrow-down-purple.svg'
import arrowUp from '../../assets/images/svg/ic-arrow-up-white.svg'
import map from '../../assets/images/svg/ic-map-purple.svg'
import mapWhite from '../../assets/images/svg/ic-map-white.svg'
import pin from '../../assets/images/svg/ic-pin-purple.svg'
import pinWhite from '../../assets/images/svg/ic-pin-white.svg'
import print from '../../assets/images/svg/ic-print.svg'
import printWhite from '../../assets/images/svg/ic-print-white.svg'

const InfoContainer = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 8px 24px rgba(42, 55, 67, 0.12);
    border-radius: 8px;
    padding: 24px;
    margin-top: 20px;
    ${media.md`
        margin-top: 0px;
    `}
`

const ProyectContainer = styled.div`
    ${media.sm`
        display: flex;
        align-items: center;
    `}
    ${media.md`
        margin-top: 0px;
    `}
`

const NameContainer = styled.div`
    ${media.sm`
        margin-top: 0px;
        border-right: 1px solid #284FAE;
        padding-right: 16px;
    `}
`

const Name = styled.h2`
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    color: #284FAE;
    margin: 0px;
    text-transform: capitalize;
`

const DirectionContainer = styled.div`
    ${media.sm`
        margin-left: 16px;
    `}
`

const Direction = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #284FAE;
    margin: 4px 0px 0px;
`

const OptionContainer = styled.div`
    margin-top: 12px;
    overflow: auto;
    white-space: nowrap;
`

const OptionWrapper = styled.div``

const ImageContainer = styled.div<{
    active: boolean
}>`
    padding: 10px 32px 10px 8px;
    background: ${props => props.active ? '#772CBD' : '#F1EAF8'};
    border: 1px solid ${props => props.active ? '#772CBD' : '#A06BD2'};
    box-sizing: border-box;
    border-radius: 4px;
    display: inline-block;
    position: relative;
    &:after {
        content: '';
        background: ${props => props.active ? `url(${arrowUp})` : `url(${arrowDown})`};
        width: 16px;
        height: 16px;
        position: absolute;
        top: calc(50% - 8px);
        right: 8px;
    }
`

const Image = styled.div<{
    active: boolean
}>`
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${props => props.active ? '#FFFFFF' : '#A06BD2'};
    & img {
        margin-right: 10px;
    }
`

const MapContainer = styled.div<{
    active: boolean
}>`
    padding: 10px 32px 10px 8px;
    background: ${props => props.active ? '#772CBD' : '#F1EAF8'};
    border: 1px solid ${props => props.active ? '#772CBD' : '#A06BD2'};
    box-sizing: border-box;
    border-radius: 4px;
    display: inline-block;
    margin-left: 24px;
    position: relative;
    &:after {
        content: '';
        background: ${props => props.active ? `url(${arrowUp})` : `url(${arrowDown})`};
        width: 16px;
        height: 16px;
        position: absolute;
        top: calc(50% - 8px);
        right: 8px;
    }
`

const Map = styled.div<{
    active: boolean
}>`
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${props => props.active ? '#FFFFFF' : '#A06BD2'};
    & img {
        margin-right: 10px;
    }
`

const GoogleContainer = styled.div<{
    active: boolean
}>`
    padding: 10px 32px 10px 8px;
    background: ${props => props.active ? '#772CBD' : '#F1EAF8'};
    border: 1px solid ${props => props.active ? '#772CBD' : '#A06BD2'};
    box-sizing: border-box;
    border-radius: 4px;
    display: inline-block;
    margin-left: 24px;
    position: relative;
    &:after {
        content: '';
        background: ${props => props.active ? `url(${arrowUp})` : `url(${arrowDown})`};
        width: 16px;
        height: 16px;
        position: absolute;
        top: calc(50% - 8px);
        right: 8px;
    }
`

const Google = styled.div<{
    active: boolean
}>`
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${props => props.active ? '#FFFFFF' : '#A06BD2'};
    & img {
        margin-right: 10px;
    }
`

const PaymentWrapper = styled.div`
    background: #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 8px 24px rgba(42, 55, 67, 0.12);
    border-radius: 8px;
    padding: 19px 12px;
    margin-top: 20px;
    ${media.md`
        padding: 24px;
        margin-top: 40px;
    `}
`

const PaymentContainer = styled.div``

const PaymentHeader = styled.div`
    ${media.sm`
        display: flex;
        align-items: center;
        justify-content: space-between;
    `}
`

const HeaderContainer = styled.div`
    ${media.sm`
        display: flex;
        align-items: center;
    `}
`

const TitleContainer = styled.div`
    ${media.sm`
        padding-right: 16px;
    `}
`

const Title = styled.div`
    display: flex;
    align-items: center;
`

const TitleText = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    color: #36393D;
    margin-left: 12px;
`

const LetterContainer = styled.div`
    ${media.sm`
        padding-left: 16px;
        border-left: 1px solid #36393D;
    `}
`

const Letter = styled.div`
    display: flex;
    align-items: center;
`

const LetterText = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #36393D;
    margin-left: 9px;
`

const ExportContainer = styled.div`
    margin-top: 24px;
    margin-bottom: 24px;
    text-align: right;
`

const Export = styled.button`
    padding: 10px 20px;
    border: 1px solid #F6A41A; 
    background: #F6A41A;
    border-radius: 4px;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    cursor: pointer;
`

const Icon = styled.img``

const PaymentBody = styled.div``

const TabContainer = styled.div`
    margin-top: 24px;
`

const CollapseContainer = styled.div`
    border: 1px solid #93A7D5;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px;
    margin-top: 24px;
    ${media.sm`
        padding: 16px;
    `}
`

const EmptyContainer = styled.div``

const NavigateContainer = styled.div`
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center;
    ${media.sm`
        & > a:last-child {
            margin-left: 10px;
        }
    `}
`

const PaymentPage = () => {
    const { id } = useParams()

    const history = useNavigate()
    const dispatch = useDispatch()
    const tipoDocumento = useSelector((state: RootState) => state.user.tipoDocumento)
    const nroDocumento = useSelector((state: RootState) => state.user.nroDocumento)
    const productList = useSelector((state: RootState) => state.home.productList)
    const projectList = useSelector((state: RootState) => state.home.projectList)
    const product = useSelector((state: RootState) => state.product)

    const [showPrint, setShowPrint] = React.useState(false)
    const [photos, setPhotos] = React.useState<any>([])

    const [showMap, setShowMap] = React.useState(false)
    const [maps, setMaps] = React.useState(null)

    const [showPin, setShowPin] = React.useState(false)
    const [location, setLocation] = React.useState<any>(null)

    const [project, setProject] = React.useState<any>(null)
    const [list, setList] = React.useState<any>([])
    const [filter, setFilter] = React.useState<any>([])
    const [pendingList, setPendingList] = React.useState<any>([])
    const [completedList, setCompletedList] = React.useState<any>([])

    React.useEffect(() => {
        const item = productList.find(x => x.pROFORMAField === id)
        setProject(item)
        setMaps(product.Map)
        setPhotos(normalizeGallery(product.GalleryItems?.split(';')))
        setLocation(normalizeLocation(product.GoogleMap))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        const filter = projectList.filter(x => x.pROFORMAField === id)
        setList(filter)
        setFilter(filter.filter(x => x.eSTADOLETRAField === 'PAGADA'))
        setPendingList(normalizePending(filter.filter(x => x.eSTADOLETRAField === 'PENDIENTE DE PAGO')))
        setCompletedList(normalizeCompleted(filter.filter(x => x.eSTADOLETRAField === 'PAGADA')))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleCheckout = (document) => {
        history(`/checkout/${document.nLETRAField}`)
    }

    const handleExport = () => {
        dispatch(fetchShowLoading())
        projectServices.getReport(tipoDocumento, nroDocumento, id ?? '').then(response => {
            const fileURL = window.URL.createObjectURL(new Blob([response.data], { type: "application/vnd.ms-excel" }))
            const a = document.createElement('a')
            a.download = "ReporteLetras.xlsx";
            a.href = fileURL
            a.click()
            a.parentNode?.removeChild(a)
            dispatch(fetchHideLoading())
        }).catch(_ => {
            dispatch(fetchHideLoading())
        })
    }

    const handlePrint = () => {
        if (photos.length) {
            setShowMap(false)
            setShowPin(false)
            setShowPrint(!showPrint)
        }
    }

    const handleMap = () => {
        if (maps) {
            setShowPin(false)
            setShowPrint(false)
            setShowMap(!showMap)
        }
    }

    const handlePin = () => {
        if (location) {
            setShowPrint(false)
            setShowMap(false)
            setShowPin(!showPin)
        }
    }

    return (
        <React.Fragment>
            <InfoContainer>
                <ProyectContainer>
                    <NameContainer>
                        <Name>{project?.pROYECTOField?.toLowerCase()}</Name>
                    </NameContainer>
                    <DirectionContainer>
                        <Direction>{project?.pRODUCTOField}</Direction>
                    </DirectionContainer>
                </ProyectContainer>
                <OptionContainer style={{ display: 'none' }}>
                    <ImageContainer active={showPrint} onClick={handlePrint} style={{ cursor: 'pointer' }}>
                        <Image active={showPrint}>
                            <Icon src={!showPrint ? print : printWhite} /> {' '} Fotos
                        </Image>
                    </ImageContainer>
                    <MapContainer active={showMap} onClick={handleMap} style={{ cursor: 'pointer' }}>
                        <Map active={showMap}>
                            <Icon src={!showMap ? map : mapWhite} /> Mapa
                        </Map>
                    </MapContainer>
                    <GoogleContainer active={showPin} onClick={handlePin} style={{ cursor: 'pointer' }}>
                        <Google active={showPin}>
                            <Icon src={!showPin ? pin : pinWhite} /> Google maps
                        </Google>
                    </GoogleContainer>
                </OptionContainer>
                <OptionWrapper>
                    {showPrint && (
                        <div style={{ width: '100%', marginTop: '20px' }}>
                            <Gallery
                                layout={[1, 2]}
                                photos={photos}
                                showRemaining={true}
                                width={["60%", "40%"]}
                                height='400px'
                            />
                        </div>
                    )}
                    {showMap && (
                        <div style={{ width: '100%', marginTop: '20px' }}>
                            <img style={{ width: '100%', borderRadius: '8px' }} alt='' src={maps ?? ''} />
                        </div>
                    )}
                    {showPin && (
                        <div style={{ width: '100%', marginTop: '20px', position: 'relative' }}>
                            <Pin
                                googleMapURL={`https://maps.googleapis.com/maps/api/js?v3.exp&key=${API_KEY}`}
                                containerElement={<div style={{ height: '400px' }} />}
                                mapElement={<div style={{ height: '100%' }} />}
                                loadingElement={<p>Cargando...</p>}
                                location={location}
                            />
                            <NavigateContainer>
                                <a rel='noreferrer' target="_blank" href={`https://www.waze.com/ul?ll=${location.lat},${location.lng}&navigate=yes&zoom=16`}>
                                    <img alt='' src={waze} />
                                </a>
                                <a rel='noreferrer' target="_blank" href={`http://www.google.com/maps/place/${location.lat},${location.lng}`}>
                                    <img alt='' src={gmap} />
                                </a>
                            </NavigateContainer>

                        </div>
                    )}
                </OptionWrapper>
            </InfoContainer>
            <PaymentWrapper>
                <PaymentContainer>
                    <PaymentHeader>
                        <HeaderContainer>
                            <TitleContainer>
                                <Title>
                                    <Icon src={cash} />
                                    <TitleText>Mis pagos</TitleText>
                                </Title>
                            </TitleContainer>
                            <LetterContainer>
                                <Letter>
                                    <Icon src={credit} />
                                    <LetterText>Letras pagadas: {filter.length} de {list.length}</LetterText>
                                </Letter>
                            </LetterContainer>
                        </HeaderContainer>
                    </PaymentHeader>
                    <PaymentBody>
                        <TabContainer>
                            <Tabs>
                                <TabList>
                                    <Tab>Pagos pendientes</Tab>
                                    <Tab>Pagos realizados</Tab>
                                </TabList>
                                <TabPanel>
                                    {pendingList.length ? (
                                        pendingList.map((pending, idx) => (

                                            <React.Fragment>
                                                {pending.items.map(item => (
                                                    <Pending
                                                        onClick={() => handleCheckout(item)}
                                                        letter={item.nLETRAField}
                                                        symbol={item.mONEDAField}
                                                        amount={item.iMPORTEField}
                                                        expirationDate={item.fECHAVENCIMIENTOField}
                                                        order={idx === 0 }
                                                    />
                                                ))}
                                            </React.Fragment>
                                            
                                            // <CollapseContainer key={Math.random()}>
                                            //     <Collapsible
                                            //         open={idx < 3}
                                            //         transitionTime={200}
                                            //         trigger={
                                            //             <Header
                                            //                 symbol={pending.symbol}
                                            //                 amount={pending.amount}
                                            //                 month={pending.name}
                                            //                 quantity={0}
                                            //             />
                                            //         }>
                                            //         <React.Fragment>
                                            //             {pending.items.map(item => (
                                            //                 <Pending
                                            //                     onClick={() => handleCheckout(item)}
                                            //                     letter={item.nLETRAField}
                                            //                     symbol={item.mONEDAField}
                                            //                     amount={item.iMPORTEField}
                                            //                     expirationDate={item.fECHAVENCIMIENTOField}
                                            //                 />
                                            //             ))}
                                            //         </React.Fragment>
                                            //     </Collapsible>
                                            // </CollapseContainer>
                                        ))
                                    ) : (
                                        <EmptyContainer>
                                            <Empty
                                                icon={bill}
                                                text={['No tienes ningún pago pendiente']}
                                            />
                                        </EmptyContainer>
                                    )}
                                </TabPanel>
                                <TabPanel>
                                    <ExportContainer>
                                        <Export onClick={handleExport}>Exportar</Export>
                                    </ExportContainer>
                                    {completedList.length ? (
                                        completedList.map((completed, idx) => (
                                            <CollapseContainer key={Math.random()}>
                                                <Collapsible
                                                    open={idx === 0}
                                                    transitionTime={200}
                                                    trigger={
                                                        <Header
                                                            month={completed.name}
                                                            quantity={completed.items.length}
                                                        />
                                                    }
                                                >
                                                    <React.Fragment>
                                                        {completed.items.map(item => (
                                                            <Completed
                                                                letter={item.nLETRAField}
                                                                type={item.sISTEMAPAGOField === 'IZIP' ? 'Pago online'
                                                                    : item.sISTEMAPAGOField === 'IZDA' ? 'Débito automático'
                                                                        : item.sISTEMAPAGOField === 'IZTC' ? 'Tipo de cambio preferencial'
                                                                            : 'Otros medios'
                                                                }
                                                                symbol={item.MonedaPago}
                                                                amount={item.ImportePago}
                                                                paymentDate={item.fECHAPAGOField}
                                                                expirationDate={item.fECHAVENCIMIENTOField}
                                                            />
                                                        ))}
                                                    </React.Fragment>
                                                </Collapsible>
                                            </CollapseContainer>
                                        ))
                                    ) : (
                                        <EmptyContainer>
                                            <Empty
                                                icon={bill}
                                                text={['No tienes ningún pago realizado']}
                                            />
                                        </EmptyContainer>
                                    )}
                                </TabPanel>
                            </Tabs>
                        </TabContainer>
                    </PaymentBody>
                </PaymentContainer>
            </PaymentWrapper>
        </React.Fragment>

    )
}

export default PaymentPage