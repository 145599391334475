import * as yup from "yup";

export const schema = yup.object().shape({
  user: yup
    .string()
    .email("Debe ser un correo electrónico válido")
    .required("El correo electrónico es requerido"),
  password: yup.string().required("La constraseña es requerida"),
  aceptaDatosPersonales: yup
    .bool()
    .oneOf([true], "el check de tratamiento es obligatorio"),
});
