import React from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import DatePicker, { registerLocale } from 'react-datepicker'
import NumberFormat from 'react-number-format'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { RootState } from '../../store/modules'

import { fetchHideLoading, fetchShowLoading } from '../../store/modules/layout/actions'

import { schema } from './validations'
import { mediaQuery as media } from '../../utils/mediaQuery'

import arrow from '../../assets/images/arrow-down-blue.png'
import calendar from '../../assets/images/svg/ic-calendar.svg'

import { normalizeAmountPending, normalizePrepayment, normalizeRefinancing,normalizePrepaymentCuotas,normalizeFilename } from '../../utils/schemas/prepayment'

import { projectServices } from '../../services/projectServices'

import 'react-datepicker/dist/react-datepicker.css'
import es from 'date-fns/locale/es'
import moment from 'moment'
import close from '../../assets/images/svg/ic-close-green.svg'
registerLocale('es', es)

const NoteWrapper = styled.div``

const NoteContainer = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 8px 24px rgba(42, 55, 67, 0.12);
    border-radius: 8px;
    padding: 19px;
    ${media.md`
        padding: 24px 24px 52px;
    `}
`

const HeaderContainer = styled.div``

const TitleContainer = styled.div``

const Title = styled.div`
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 26px;
    color: #284FAE;
    ${media.md`
        font-size: 24px;
        line-height: 32px;
    `}
`

const BodyWrapper = styled.div`
    margin-top: 15px;
    ${media.md`
        background: #FFFFFF;
        box-shadow: 0px 8px 24px rgba(42, 55, 67, 0.12);
        border-radius: 8px;
        margin-top: 24px;
        padding: 10px 41px 18px;
    `}
`

const BodyContainer = styled.div`
    min-height: 395px;
`

const InputGroupContainer = styled.div`
    margin-top: 10px;
    ${media.md`
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 17px;
    `}
`

const LabelContainer = styled.div`
    width: 100%;
    max-width: 267px;
    margin-bottom: 12px;
    ${media.md`
        margin-bottom: 0px;
        margin-right: 15px;
    `}
`

const Label = styled.label`
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #3D61B3;
`

const DropdownContainer = styled.div`
    width: 100%;
`

const Dropdown = styled.select`
    width: 100%;
    height: 55px;
    border: 1px solid #284FAE;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 15px 46px 15px 12px;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #284FAE;
    position: relative;
    appearance: none;
    cursor: pointer;
    background: url(${arrow}) no-repeat;
    background-position: calc(100% - 15px) center;
    &:focus {
        outline: none;
    }
`

const Option = styled.option``

const FormContainer = styled.div`
    margin-top: 20px;
    ${media.md`
        margin-top: 33px;
    `}
`

const ProjectContainer = styled.div``

const Project = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #284FAE;
`

const InputContainer = styled.div`
    width: 100%;
`

const Input = styled.input<{
    error: boolean
}>`
    width: 100%;
    height: 55px;
    border: 1px solid ${props => props.error ? '#970C0C' : '#284FAE'};
    background: ${props => props.error ? '#F6EEEC' : '#FFFFFF'};
    box-sizing: border-box;
    border-radius: 4px;
    padding: 15px 12px;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #284FAE;
    position: relative;
    &:focus {
        outline: none;
    }
`

const LocalContainer = styled.div`
    display: flex;
`

const Local = styled.div`
    width: 75px;
    height: 55px;
    border: 1px solid #284FAE;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #284FAE;
`

const Money = styled(NumberFormat) <{
    error: boolean
}>`
    width: 100%;
    height: 55px;
    border: 1px solid ${props => props.error ? '#970C0C' : '#284FAE'};
    background: ${props => props.error ? '#F6EEEC' : '#FFFFFF'};
    box-sizing: border-box;
    border-radius: 4px;
    padding: 15px 12px;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #284FAE;
    position: relative;
    &:focus {
        outline: none;
    }
`

const Date = styled(DatePicker) <{
    error: boolean
}>`
    width: 100%;
    height: 55px;
    border: 1px solid ${props => props.error ? '#970C0C' : '#284FAE'};
    background-color: ${props => props.error ? '#F6EEEC' : '#FFFFFF'};
    box-sizing: border-box;
    border-radius: 4px;
    padding: 15px 12px;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #284FAE;
    position: relative;
    background-image: url(${calendar});
    background-repeat: no-repeat;
    background-size: 160x 20px;
    background-position: calc(100% - 15px) center;
    background-size: 20px;
    &:focus {
        outline: none;
    }
`

const AmountContainer = styled.div`
    width: 100%;
`

const Amount = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #284FAE;
`

const ButtonContainer = styled.div`
    text-align: center;
    margin-top: 20px;
    ${media.md`
        margin-top: 57px;
    `}
`

const Button = styled.button`
    width: 100%;
    height: 48px;
    background: #CC26BA;
    border: 1px solid #CC26BA;
    border-radius: 4px;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    cursor: pointer;
    &:focus {
        outline: none;
    }
    ${media.md`
        max-width: 364px;
    `}
`

const Fee = styled.p`
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    margin: 10px 0px;
`

const TableWrapper = styled.div`
    border: 1px solid rgba(49,79,171,.1);
    background: #f8f9fb;
    color: #284fab;
    text-align: center;
    padding: 30px 35px;
    border-radius: 5px;
    margin: 50px 0px 30px;
`

const TableTitle = styled.div`
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: normal;
    color: #284fab;
    font-size: 20px;
    line-height: 24px;
`

const TableAmount = styled.div`
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 800;
    color: #284fab;
    font-size: 30px;
    line-height: 34px;
    margin-top: 10px;
`

const TableLink = styled.div`
    padding: 25px 0px;
    border-bottom: 1px solid rgba(40,79,171,.2);
`

const TableAction = styled.a`
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    font-weight: 800;
    color: #234798;
    text-decoration: none;
`

const TableContainer = styled.div`
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
`

const Table = styled.table`
    width: 100%;
    border-spacing: 0px;
`

const THead = styled.thead`
    & th {
        font-size: 16px;
        line-height: 24px;
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 800;
        color: #234798;
        text-align: center;
        padding: 17px 10px 15px;
        border-bottom: 1px solid rgba(40,79,171,.2);
        &:last-child {
            text-align: right;
        }
    }
`

const TBody = styled.tbody`
    & tr {
        &:nth-child(2n) {
            background: rgba(40,79,171,.03);
        }
    }
    & td {
        font-size: 14px;
        line-height: 22px;
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: normal;
        color: #284fab;
        overflow-wrap: break-word;
        text-align: center;
        padding: 17px 10px 15px;
        border-bottom: 1px solid rgba(40,79,171,.1);
        &:last-child {
            text-align: right;
        }
    }
`
const Submit = styled.button`
  border: none;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  font-family: "Nunito", sans-serif;
  font-style: normal;
  padding: 6px 12px;
  cursor: pointer;
  margin-left: 10px;
  color: #234798;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  font-weight: 800;
  text-decoration: none;
`
const MessageContainer = styled.div`
    background: #EBF4EC;
    border: 0.5px solid #C1D5C3;
    box-sizing: border-box;
    border-radius: 3px;
    margin-top: 20px;
    position: relative;
    padding: 16px 44px 16px 16px;
    ${media.md`
        width: 100%;
        max-width: 100%;
    `}
`
const Message = styled.div`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: #2D7738;
`

const Close = styled.a`
    position: absolute;
    right: 10px;
    top: calc(50% - 8px);
    cursor: pointer;
`
const PrepaymentPage = () => {
    const dispatch = useDispatch()
    const tipoDocumento = useSelector((state: RootState) => state.user.tipoDocumento)
    const nroDocumento = useSelector((state: RootState) => state.user.nroDocumento)
    const projectList = useSelector((state: RootState) => state.home.projectList)
    const productList = useSelector((state: RootState) => state.home.productList)
    const user = useSelector((state: RootState) => state.user)

    const [feeList, setFeeList] = React.useState<any>([])
    const [amountTotal, setAmountTotal] = React.useState(0)
    const [amountFee, setAmountFee] = React.useState(0.0)
    const [product, setProduct] = React.useState<any>(null)
    const [fee, setFee] = React.useState(0)
    const [showForm, setShowForm] = React.useState(false)
    const [showTable, setShowTable] = React.useState(false)
    const [prepagoLetras, setPrepagoLetras] = React.useState<any>(null)

    const [success, setSuccess] = React.useState(false)

    const methods = useForm({
        mode: 'onChange',
        reValidateMode: 'onSubmit',
        resolver: yupResolver(schema(fee))
    })
    const { control, watch, register, handleSubmit, formState: { errors } } = methods

    React.useEffect(() => {
        const value = watch('project')
        if (value) handleProjectSelected(value)
        else setShowForm(false)
        setShowTable(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch('project')])

    const onSubmit = (data) => {
        setShowTable(false)
        handlePrepayment(data)
    }
    
    const handleProjectSelected = (idProforma) => {
        dispatch(fetchShowLoading())
        projectServices.getMaximumFee(tipoDocumento, nroDocumento, idProforma).then(response => {
            console.log(response.data);
            const { nRO_CUOTMAXField,amountTotal } = response.data
            const selected = productList.find(x => x.pROFORMAField === idProforma)
            setFee(nRO_CUOTMAXField)
            setProduct(selected)
            setAmountTotal(amountTotal);
            setShowForm(true)
            dispatch(fetchHideLoading())
        }).catch(_ => {
            dispatch(fetchHideLoading())
        })
    }

    const handlePrepayment = (data) => {
        dispatch(fetchShowLoading())
        projectServices.postPrepagoCargarLetras(normalizePrepayment(tipoDocumento, nroDocumento, product, data)).then(response => {        
            const { zaC_INT_CALCULAR_REFINResponse } = response.data
            const { e_REFINANCIAMIENTOField } = zaC_INT_CALCULAR_REFINResponse
            const { nUEVA_PROFField } = e_REFINANCIAMIENTOField
            const { filterList, amountFee } = normalizeRefinancing(nUEVA_PROFField)
            const { prepagoLetras }=normalizePrepaymentCuotas(tipoDocumento, nroDocumento, product, data,filterList,amountFee,productList,user)
          console.log(prepagoLetras)
            setPrepagoLetras(prepagoLetras)
            setFeeList(filterList)
            setAmountFee(amountFee)
            setShowTable(true)
            dispatch(fetchHideLoading())
        }).catch(_ => {
            dispatch(fetchHideLoading())
        })
    }

    const Imprirmir = () => {
        dispatch(fetchShowLoading())
        projectServices.postPrepagoCargarLetrasImprimir(
            prepagoLetras
        ).then(response => {        
            const fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}))
            const a = document.createElement('a')
            a.download = normalizeFilename( user.cLIENTEField, prepagoLetras.proforma);
            a.href = fileURL
            a.click()
            a.parentNode?.removeChild(a)
            dispatch(fetchHideLoading())
        }).catch(_ => {
            dispatch(fetchHideLoading())
        })
    }
    
    const SendEmail = () => {
        dispatch(fetchShowLoading())
        projectServices.postPrepagoCargarLetrasSenEmail(prepagoLetras).then(response => {        
                console.log(response);
                setSuccess(true)
                setTimeout(() => {
                    setSuccess(false)
                },8000)
                dispatch(fetchHideLoading())
        }).catch(_ => {
            dispatch(fetchHideLoading())
        })
    }

    return (
        <NoteWrapper>
            <NoteContainer>
                <HeaderContainer>
                    <TitleContainer>
                        <Title>Prepago de letras - Simulador</Title>
                    </TitleContainer>
                </HeaderContainer>
                <BodyWrapper>
                    <FormProvider {...methods}>
                        <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
                            <BodyContainer>
                                <InputGroupContainer>
                                    <LabelContainer>
                                        <Label>Elegir proforma:</Label>
                                    </LabelContainer>
                                    <DropdownContainer>
                                        <Dropdown
                                            {...register('project')}
                                        >
                                            <Option value=''>Seleccionar</Option>
                                            {productList.map(item => (
                                                <Option value={item.pROFORMAField}>{item.pROFORMAField} - {item.pROYECTOField}</Option>
                                            ))}
                                        </Dropdown>
                                    </DropdownContainer>
                                </InputGroupContainer>
                                {showForm && (
                                    <FormContainer>
                                        <ProjectContainer>
                                            <Project>{product.pROYECTOField}</Project>
                                        </ProjectContainer>
                                        <InputGroupContainer>
                                            <LabelContainer>
                                                <Label>Deuda a la fecha</Label>
                                                <Label>(no incluye interés y gasto):</Label>
                                            </LabelContainer>
                                            <AmountContainer>
                                                <Amount>
                                                    <NumberFormat
                                                        prefix={`${product.wAERSField} `}
                                                        decimalSeparator='.'
                                                        displayType='text'
                                                        type='text'
                                                        thousandSeparator={true}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        value={amountTotal}
                                                    />
                                                </Amount>
                                            </AmountContainer>
                                        </InputGroupContainer>
                                        <InputGroupContainer>
                                            <LabelContainer>
                                                <Label>Ingrese el importe a amortizar</Label>
                                                <Label>(mayor al monto de la letra):</Label>
                                            </LabelContainer>
                                            <InputContainer>
                                                <LocalContainer>
                                                    <Local>{product.wAERSField}</Local>
                                                    <Controller
                                                        control={control}
                                                        name='amount'
                                                        render={({
                                                            field: { onChange, name, value } }) => (
                                                            <Money
                                                                name={name}
                                                                error={errors.amount}
                                                                decimalSeparator='.'
                                                                thousandSeparator={true}
                                                                decimalScale={2}
                                                                fixedDecimalScale={true}
                                                                onValueChange={(e) => onChange(e.value)}
                                                                value={value}
                                                            />
                                                        )}
                                                    />
                                                </LocalContainer>
                                            </InputContainer>
                                        </InputGroupContainer>
                                        <InputGroupContainer>
                                            <LabelContainer>
                                                <Label>Seleccione la fecha en que realizará el pago:</Label>
                                            </LabelContainer>
                                            <InputContainer>
                                                <Controller
                                                    control={control}
                                                    name='date'
                                                    render={({
                                                        field: { onChange, name, value } }) => (
                                                        <Date
                                                            name={name}
                                                            error={errors.date}
                                                            locale='es'
                                                            dateFormat='dd/MM/yyyy'
                                                            selected={value}
                                                            minDate={moment().toDate()}
                                                            maxDate={moment().toDate()}
                                                            showTimeSelect={false}
                                                            onChange={(e) => onChange(e)}
                                                        />
                                                    )}
                                                />
                                            </InputContainer>
                                        </InputGroupContainer>
                                        <InputGroupContainer style={{ alignItems: 'flex-start' }}>
                                            <LabelContainer>
                                                <Label>Seleccione número de letras en que desea pagar el saldo:</Label>
                                            </LabelContainer>
                                            <InputContainer>
                                                <Input
                                                    type='number'
                                                    error={errors.fee}
                                                    {...register('fee')}
                                                />
                                                <Fee>Máximo de letras a refinanciar: {fee}</Fee>
                                            </InputContainer>
                                        </InputGroupContainer>
                                        <ButtonContainer>
                                            <Button type='submit'>Calcular</Button>
                                        </ButtonContainer>
                                    </FormContainer>
                                )}
                                {showTable && (
                                    <TableWrapper>
                                        <TableTitle>Valor de la cuota</TableTitle>
                                        <TableAmount>
                                            <NumberFormat
                                                prefix={`${product.wAERSField} `}
                                                decimalSeparator='.'
                                                displayType='text'
                                                type='text'
                                                thousandSeparator={true}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                value={amountFee}
                                            />
                                        </TableAmount>
                                        <TableLink>
                                            <Submit onClick={SendEmail}>Enviar Correo</Submit>
                                            <Submit onClick={Imprirmir}>Imprimir</Submit>
                                            {success && (
                                                <MessageContainer>
                                                    <Message>Se envió la información solicitada a tu email registrado.</Message>
                                                    <Close onClick={() => setSuccess(false)}><img src={close} alt='' /></Close>
                                                </MessageContainer>
                                            )} 
                                        </TableLink>
                                        <TableContainer>
                                            <Table>
                                                <THead>
                                                    <tr>
                                                        <th>Cuota</th>
                                                        <th>Tipo</th>
                                                        <th>F. Vencimiento</th>
                                                        <th>Importe</th>
                                                    </tr>
                                                </THead>
                                                <TBody>
                                                    {feeList.map(item => (
                                                        <tr>
                                                            <td>{item.cUOTAField}</td>
                                                            <td>{item.tIPO_CUOTAField}</td>
                                                            <td>{moment(item.fECHA_VENCIMIENTOField).format('DD/MM/YYYY')}</td>
                                                            <td>
                                                                <NumberFormat
                                                                    prefix={`${product.wAERSField} `}
                                                                    decimalSeparator='.'
                                                                    displayType='text'
                                                                    type='text'
                                                                    thousandSeparator={true}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale={true}
                                                                    value={parseFloat(item.iMP_CUOTAField.trim())}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </TBody>
                                            </Table>
                                        </TableContainer>
                                    </TableWrapper>
                                )}
                            </BodyContainer>
                        </form>
                    </FormProvider>
                </BodyWrapper>
            </NoteContainer>
        </NoteWrapper>
    )
}

export default PrepaymentPage