import React from 'react'
import PropTypes from 'prop-types'
import Page from '../../containers/NoticePage'

const Notice = () => (
    <React.Fragment>
        <Page />
    </React.Fragment>
)

Notice.propTypes = {
    children: PropTypes.node
}

export default Notice