import React from 'react'
import PropTypes from 'prop-types'
import Page from '../../../containers/Debit/PaymentPage'

const Payment = () => (
    <React.Fragment>
        <Page />
    </React.Fragment>
)

Payment.propTypes = {
    children: PropTypes.node
}

export default Payment