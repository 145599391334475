/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import moment from 'moment'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import NumberFormat from 'react-number-format'
import styled from 'styled-components'

import { schema } from './validations'

import Radio from '../../../../components/Radio'
import Checkbox from '../../../../components/Checkbox'

import { mediaQuery as media } from '../../../../utils/mediaQuery'

import exclamation from '../../../../assets/images/svg/ic-exclamation.svg'
import alert from '../../../../assets/images/svg/ic-alert-circle.svg'

interface IProps {
    exchange: any
    affiliation: any
    document: any
    onSubmit: (e) => void
    onBack: () => void
}

const ConfirmationWrapper = styled.div``

const ConfirmationContainer = styled.div`
    width: 100%;
    max-width: 564px;
    margin: 0px auto;
    box-sizing: border-box;
    padding: 40px 16px 80px;
`

const TitleContainer = styled.div`
    padding-bottom: 24px;
`

const Title = styled.div`
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #36393D;
    text-align: center;
    ${media.md`
        font-size: 24px;
        line-height: 32px;
    `}
`

const DetailContainer = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 8px 24px rgba(40, 79, 171, 0.1);
    border-radius: 8px;
    padding: 12px;
    ${media.md`
        padding: 32px 24px 24px;
    `}
`

const InfoContainer = styled.div`
    padding-bottom: 12px;
    border-bottom: 1px solid #BECAE6;
    &:not(:first-child) {
        padding-top: 12px;
    }
    ${media.sm`
        display: flex;
        justify-content: space-between;
        padding-bottom: 8px;
        &:not(:first-child) {
            padding-top: 12px;
        }
    `}
`

const LabelContainer = styled.div``

const Label = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #6884C4;
    margin-bottom: 4px;
    ${media.md`
        font-size: 16px;
        line-height: 24px;
    `}
`

const ValueContainer = styled.div``

const Value = styled.div`
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: #36393D;
    text-transform: capitalize;
    ${media.md`
        font-size: 16px;
        line-height: 24px;
    `}
`

const ErrorContainer = styled.div`
    display: flex;
    align-items: center;
    background: #F6EEEC;
    border: 0.5px solid #CEA7A4;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 18px;
    margin-bottom: 24px;
`

const AlertContainer = styled.div``

const Alert = styled.img``

const ParagraphContainer = styled.div`
    max-width: 763px;
    margin-left: 15px;
`

const Paragraph = styled.div`
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: #970C0C;
`

const ExchangeContainer = styled.div`
    padding: 19px 12px;
    background: #FFFFFF;
    box-shadow: 0px 8px 24px rgba(40, 79, 171, 0.1);
    border-radius: 8px;
    margin-top: 24px;
    ${media.md`
        padding: 24px;
    `}
`

const RateContainer = styled.div`
    display: flex;
    align-items: center;
`

const Rate = styled.div`
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    color: #36393D;
`

const TooltipContainer = styled.div`
    display: flex;
    margin-left: 10px;
    position: relative;
    cursor: pointer;
    &::before {
        content: attr(data-tooltip);
        position: absolute;
        top: 33px;
        left: -170px;
        width: 320px;
        padding: 8px 12px;
        border-radius: 3px;
        background: #36393D;
        color: #fff;
        text-align: center;
        display: none;
        transition: .3s display;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #FFFFFF;
    }
    &::after {
        content: '';
        position: absolute;
        top: 17px;
        left: 50%;
        display: none;
        margin-left: -8px;
        border-width: 8px;
        border-style: solid;
        border-color: transparent transparent #36393D transparent;
    }
    &:hover::after {
        display: block;
    }
    &:hover::before {
        display: block;
    }
    ${media.sm`
        &::before {
            left: -10px;
        }
    `}
`

const Tooltip = styled.img``

const RadioGroupContainer = styled.div``

const RadioContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;
    &:first-child {
        border-bottom: 1px solid #BECAE6;
    }
`

const AmountContainer = styled.div``

const Amount = styled.div`
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #36393D;
`

const TermsContainer = styled.div`
    padding: 19px 12px;
    background: #FFFFFF;
    box-shadow: 0px 8px 24px rgba(40, 79, 171, 0.1);
    border-radius: 8px;
    margin-top: 24px;
    ${media.md`
        padding: 24px;
    `}
`

const CheckboxContainer = styled.div``

const ButtonContainer = styled.div`
    margin-top: 40px;
    ${media.md`
        max-width: 364px;
        margin: 0px auto;
        margin-top: 48px;
    `}
`

const Submit = styled.button`
    width: 100%;
    border: none;
    border: 1px solid #CC26BA;
    background: #CC26BA;
    border-radius: 4px;
    font-family: Nunito, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    padding: 12px 0px;
    cursor: pointer;
    &:disabled {
        color: #82949E;
        background: #E6EAEC;
        border: 1px solid #82949E;
    }
`

const Button = styled.button`
    width: 100%;
    border: 1px solid #36393D;
    border-radius: 4px;
    font-family: Nunito, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #36393D;
    padding: 12px 0px;
    margin-top: 16px;
    cursor: pointer;
`

const ConfirmationPage = ({
    exchange,
    document,
    affiliation,
    onSubmit,
    onBack
}: IProps) => {
    const methods = useForm({
        mode: 'onChange',
        defaultValues: {
            payment: document?.payment ?? 'USD',
            affiliate: document?.affiliate ?? false
        },
        reValidateMode: 'onSubmit',
        resolver: yupResolver(schema())
    })
    const { handleSubmit, formState: { isValid } } = methods
    return (
        <React.Fragment>
            <ConfirmationWrapper>
                <ConfirmationContainer>
                    <FormProvider {...methods}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <TitleContainer>
                                <Title>Revisa los detalles de tu pago</Title>
                            </TitleContainer>
                            {document?.eSTADOField === 'PAGADA' && (
                                <ErrorContainer>
                                    <AlertContainer>
                                        <Alert src={alert} />
                                    </AlertContainer>
                                    <ParagraphContainer>
                                        <Paragraph>El número de documento ya se encuentra pagado.</Paragraph>
                                    </ParagraphContainer>
                                </ErrorContainer>
                            )}
                            <DetailContainer>
                                <InfoContainer>
                                    <LabelContainer>
                                        <Label>Cód. de letra</Label>
                                    </LabelContainer>
                                    <ValueContainer>
                                        <Value>{document?.nLETRAField}</Value>
                                    </ValueContainer>
                                </InfoContainer>
                                <InfoContainer>
                                    <LabelContainer>
                                        <Label>Proyecto</Label>
                                    </LabelContainer>
                                    <ValueContainer>
                                        <Value>{document?.pROYECTOField?.toLowerCase()}</Value>
                                    </ValueContainer>
                                </InfoContainer>
                                <InfoContainer>
                                    <LabelContainer>
                                        <Label>Ubicación</Label>
                                    </LabelContainer>
                                    <ValueContainer>
                                        <Value>{document?.pRODUCTOField}</Value>
                                    </ValueContainer>
                                </InfoContainer>
                                <InfoContainer>
                                    <LabelContainer>
                                        <Label>Vencimiento</Label>
                                    </LabelContainer>
                                    <ValueContainer>
                                        <Value>{document?.fECHAVENCIMIENTOField ? moment(document?.fECHAVENCIMIENTOField).format('DD/MM/YYYY') : ''}</Value>
                                    </ValueContainer>
                                </InfoContainer>
                                <InfoContainer>
                                    <LabelContainer>
                                        <Label>Monto en dólares</Label>
                                    </LabelContainer>
                                    <ValueContainer>
                                        <Value>
                                            <NumberFormat
                                                prefix={document?.mONEDAField === 'USD' ? 'US$' : 'S/'}
                                                decimalSeparator='.'
                                                displayType='text'
                                                type='text'
                                                thousandSeparator={true}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                value={document?.iMPORTEField}
                                            />
                                        </Value>
                                    </ValueContainer>
                                </InfoContainer>
                            </DetailContainer>
                            {exchange?.tipoCambio !== 0 && (
                                <ExchangeContainer>
                                    <RateContainer>
                                        <Rate>¿Cómo quieres pagar tu letra?</Rate>
                                        <TooltipContainer data-tooltip='Debes tener una cuenta en soles o dólares según el tipo de moneda que quieras pagar'>
                                            <Tooltip src={exclamation} />
                                        </TooltipContainer>
                                    </RateContainer>
                                    <RadioGroupContainer>
                                        <RadioContainer>
                                            <Radio
                                                name='payment'
                                                value='USD'
                                            >
                                                En dólares
                                            </Radio>
                                            <AmountContainer>
                                                <Amount>
                                                    <NumberFormat
                                                        prefix='US$'
                                                        decimalSeparator='.'
                                                        displayType='text'
                                                        type='text'
                                                        thousandSeparator={true}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        value={document?.iMPORTEField}
                                                    />
                                                </Amount>
                                            </AmountContainer>
                                        </RadioContainer>
                                        <RadioContainer>
                                            <Radio
                                                name='payment'
                                                value='PEN'
                                                subtitle={`Tipo de cambio preferencial S/${exchange?.tipoCambio.toFixed(2)}`}
                                            >
                                                En soles
                                            </Radio>
                                            <AmountContainer>
                                                <Amount>
                                                    <NumberFormat
                                                        prefix='S/'
                                                        decimalSeparator='.'
                                                        displayType='text'
                                                        type='text'
                                                        thousandSeparator={true}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        value={exchange?.importePEN}
                                                    />
                                                </Amount>
                                            </AmountContainer>
                                        </RadioContainer>
                                    </RadioGroupContainer>
                                </ExchangeContainer>
                            )}
                            <TermsContainer>
                                {!affiliation?.estado && (
                                    <CheckboxContainer style={{ marginBottom: '15px' }}>
                                        <Checkbox name='affiliate'>
                                            Afiliar tarjeta al <a target='_blank' href='https://prdunvblobstorage.blob.core.windows.net/vivienda/sistemas/micuenta/micuenta.debitoautomatico.terminos.condiciones.pdf'>Débito automático</a>
                                        </Checkbox>
                                    </CheckboxContainer>
                                )}
                                <CheckboxContainer>
                                    <Checkbox name='terms'>
                                        Acepto las <a target='_blank' href='https://prdunvblobstorage.blob.core.windows.net/vivienda/sistemas/micuenta/micuenta.politica.privacidad.pdf'>Políticas de Privacidad</a> y <a target='_blank' href='https://prdunvblobstorage.blob.core.windows.net/vivienda/sistemas/micuenta/micuenta.terminos.condiciones.pdf'>Términos y condiciones</a>
                                    </Checkbox>
                                </CheckboxContainer>
                            </TermsContainer>
                            <ButtonContainer>
                                <Submit
                                    type='submit'
                                    disabled={!isValid}
                                >
                                    Realizar pago
                                </Submit>
                                <Button type='button' onClick={onBack}>Regresar</Button>
                            </ButtonContainer>
                        </form>
                    </FormProvider>
                </ConfirmationContainer>
            </ConfirmationWrapper>
        </React.Fragment>
    )
}

export default ConfirmationPage