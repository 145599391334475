export const LOCATION_LOGIN_REQUESTED = 'vivienda/login/LOCATION_LOGIN_REQUESTED'
export const LOCATION_LOGIN_SUCCESS = 'vivienda/login/LOCATION_LOGIN_SUCCESS'
export const DOCUMENTTYPE_LOGIN_REQUESTED = 'vivienda/login/DOCUMENTTYPE_REQUESTED'
export const COUNTRYCODE_LOGIN_REQUESTED = 'vivienda/login/COUNTRYCODE_LOGIN_REQUESTED'
export const COUNTRYCODE_LOGIN_SUCCESS = 'vivienda/login/COUNTRYCODE_LOGIN_SUCCESS'
export const ERROR_LOGIN = 'vivienda/login/ERROR_LOGIN'
export const AVAILABLE_LOGOUT_REQUESTED = 'vivienda/login/AVAILABLE_LOGOUT_REQUESTED'
export const LOAD_COUNTRYCODE_LOGIN = 'vivienda/login/LOAD_COUNTRYCODE_LOGIN'

export function fetchLocationLogin(params) {
    return {
        type: LOCATION_LOGIN_REQUESTED,
        payload: params
    }
}

export function fetchLocationLoginSuccess(data) {
    return {
        type: LOCATION_LOGIN_SUCCESS,
        payload: data
    }
}

export function fetchDocumentTypeLogin(data) {
    return {
        type: DOCUMENTTYPE_LOGIN_REQUESTED,
        payload: data
    }
}

export function fetchCountryCodeLogin(data) {
    return {
        type: COUNTRYCODE_LOGIN_REQUESTED,
        payload: data
    }
}

export function fetchCountryCodeLoginSuccess(data) {
    return {
        type: COUNTRYCODE_LOGIN_SUCCESS,
        payload: data
    }
}

export function fetchErrorLogin(data) {
    return {
        type: ERROR_LOGIN,
        payload: data
    }
}

export function fetchAvailableLogout() {
    return {
        type: AVAILABLE_LOGOUT_REQUESTED
    }
}

export function fetchLoadCountryCodeLogin(data) {
    return {
        type: LOAD_COUNTRYCODE_LOGIN,
        payload: data
    }
}