export const CODE_PER = '51'
export const CODE_DNI = '01'
export const CODE_CE = '04'
export const CODE_RUC = '06'
export const CODE_PS = '07'
export const CODE_NAC = '11'
export const ERROR_CEL = 'El número de celular es incorrecto'
export const ERROR_CEL_PER = 'El número de celular debe tener 9 digitos'
export const ERROR_CEL_EXT = 'El número de celular debe tener máximo 13 digitos'
export const ERROR_DNI = 'El número de DNI es incorrecto'
export const ERROR_CE = 'El número de C.E es incorrecto'
export const ERROR_RUC = 'El número de RUC es incorrecto'
export const ERROR_PS = 'El número de P.S es incorrecto'
export const ERROR_NAC = 'El número de P.NAC es incorrecto'
export const WEBORIGEN_DEPARTAMENTO = "DEPARTAMENTO"
export const PAGINA_LOGIN_MICUENTA_DEPARTAMENTO = "miCuentaDepartamento"