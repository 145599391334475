import { takeLatest } from 'redux-saga/effects'

import {
  RESET_PRODUCT,
  SELECTED_PRODUCT,
  fetchProductReset,
  fetchProductSelected
} from '../modules/product/actions'

export default function* productWatcherSaga() {
  yield [
    yield takeLatest(RESET_PRODUCT, fetchProductReset),
    yield takeLatest(SELECTED_PRODUCT, fetchProductSelected)
  ]
}