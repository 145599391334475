import React from 'react'
import NumberFormat from 'react-number-format'
import styled from 'styled-components'

import { mediaQuery as media } from '../../../utils/mediaQuery'

interface IProps {
    month: string
    quantity: number
}

const HeaderContainer = styled.div`
    padding-right: 25px;
    cursor: pointer;
    ${media.sm`
        display: flex;
        align-items: center;
        justify-content: space-between;
    `}
`

const InfoContainer = styled.div`
    ${media.sm`
        display: flex;
        align-items: center;
    `}
`

const MonthContainer = styled.div`
    padding-right: 8px;
    ${media.sm`
        display: flex;
        align-items: center;
    `}
`

const Month = styled.div`
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
`

const AmountContainer = styled.div`
    margin-top: 8px;
    ${media.sm`
        margin-top: 0px;
    `}
`

const Amount = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #36393D;
    ${media.sm`
        font-size: 16px;
        line-height: 24px;
    `}
`

const Header = ({
    month,
    quantity,
}: IProps) => {

    const handleTitle = (month, quantity) => {
        let title = month
        if(quantity) title += ` - ${quantity} pago${quantity > 1 ? 's' : ''}`
        return title
    }

    return (
        <HeaderContainer>
            <InfoContainer>
                <MonthContainer>
                    <Month>{handleTitle(month, quantity)}</Month>
                </MonthContainer>
            </InfoContainer>
        </HeaderContainer>
    )
}

export default Header