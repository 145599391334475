import moment from "moment"
import 'moment/locale/es'

export const getCardType = (card) => {
    if (card.match(new RegExp('^4')) != null) {
        return 'visa'
    } else if (card.match(new RegExp("^(4026|417500|4508|4844|491(3|7))")) != null) {
        return 'visa'
    } else if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(card)) {
        return 'mastercard'
    } else if (card.match(new RegExp('^3[47]')) != null) {
        return 'amex'
    } else if (card.match(new RegExp('^36')) != null) {
        return 'diners'
    } else if (card.match(new RegExp('^30[0-5]')) != null) {
        return 'diners'
    } else {
        return ''
    }
}

export const normalizeDebitAutomatic = (affiliations, letters) => {
    return affiliations.map(item => {
        const filter = letters.filter(x => x.pROFORMAField === item.proforma && x.eSTADOLETRAField === 'PENDIENTE DE PAGO')
        const overdue = filter.filter(x => moment(Date.now()).diff(moment(x.fECHAVENCIMIENTOField), 'days') > 0).length
        return { ...item, overdue }
    })
}

export const normalizeIzipay = (item, device) => {
    return {
        currency: item.codMoneda,
        orderId: moment().format('DDMMYYHHmmss'),
        customer: {
            billingDetails: {
                cellPhoneNumber: item.celularIngreso,
                firstName: item.nombreCliente,
                identityCode: item.nroDocumento,
                language: 'ES'
            },
            email: item.correoElectronico,
            reference: item.nroDocumento
        },
        metadata: {
            fechaVencimiento: item.fechaVencimiento,
            codProyecto: item.codProyecto,
            proyecto: item.nombreProyecto,
            idTipoDocumento: parseInt(item.idTipoDocumento),
            celular: item.celularIngreso,
            celularIngreso: item.celularIngreso,
            dispositivo: device ? 'Mobile' : 'PC',
            producto: item.producto,
            tipoDocumento: item.tipoDocumento
        }
    }
}

export const normalizeAffiliation = (item, device) => {
    return {
        tipoDocumento: item.tipoDocumento,
        nroDocumento: item.nroDocumento,
        nombreCliente: item.nombreCliente,
        correoElectronico: item.correoElectronico,
        codProyecto: item.codProyecto,
        nombreProyecto: item.nombreProyecto,
        proforma: item.proforma,
        producto: item.producto,
        fechaVenta: item.fechaVenta,
        codMoneda: item.codMoneda,
        montoCuotaAprox: item.montoCuotaAprox,
        fechaRegistro: item.fechaRegistro,
        token: item.token,
        estado: item.estado,
        celularIngreso: item.celularIngreso,
        dispositivo: device ? 'Mobile' : 'PC',
        tarjetaRegistrada: item.tarjetaRegistrada,
        fechaVencimiento: item.fechaVencimiento,
        idTipoDocumento: item.idTipoDocumento
    }
}

export const normalizeDesaffiliation = (item) => {
    return {
        tipoDocumento: item.tipoDocumento,
        nroDocumento: item.nroDocumento,
        nombreCliente: item.nombreCliente,
        correoElectronico: item.correoElectronico,
        codProyecto: item.codProyecto,
        nombreProyecto: item.nombreProyecto,
        proforma: item.proforma,
        producto: item.producto,
        fechaVenta: item.fechaVenta,
        codMoneda: item.codMoneda,
        montoCuotaAprox: item.montoCuotaAprox,
        fechaRegistro: item.fechaRegistro,
        token: item.token,
        estado: item.estado,
        celularIngreso: item.celularIngreso,
        dispositivo: item.dispositivo,
        tarjetaRegistrada: item.tarjetaRegistrada,
        fechaVencimiento: item.fechaVencimiento,
        idTipoDocumento: item.idTipoDocumento
    }
}