import React from 'react'
import classNames from 'classnames'
import styled from 'styled-components'

import Letter from '../Letter'

import { mediaQuery as media } from '../../utils/mediaQuery'

import arrow from '../../assets/images/svg/ic-arrow-blue.svg'

interface IOption {
    code: string
    name: string
    money: string
    image: string
    overdue: number
    proform: string
    location: string
    direction: string
    totalAmount: number
    totalLetter: number
    partialLetter: number
    partialAmount: number
    type: 'deuda' | 'pagando' | 'completado'
}

interface IProps {
    icon: string
    title: string
    subtitle: string
    options: IOption[]
    onClick: (e) => void
}

const CollapseWrapper = styled.div`
    padding: 12px;
    border-radius: 8px;
    background: #FFFFFF;
    box-shadow: 0px 12px 32px rgba(42, 55, 67, 0.16);
    ${media.lg`
        padding: 24px;
    `}
`

const CollapseHeader = styled.div`
    cursor: pointer;
`

const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    &::after {
        content: '';
        background: url(${arrow});
        width: 16px;
        height: 10px;
        position: absolute;
        top: calc(50% - 5px);
        right: 0px;
        transition: transform .2s ease-in;
        transform: rotate(180deg);
    }
    &.open::after {
        transform: rotate(0deg);
    }
`

const LogoContainer = styled.div`
    display: flex;
    padding-right: 12px;
`

const Icon = styled.img``

const InfoContainer = styled.div``

const TitleContainer = styled.div``

const Title = styled.div`
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: #284FAE;
    ${media.lg`
        font-size: 16px;
        line-height: 24px;
    `}
`

const SubtitleContainer = styled.div``

const Subtitle = styled.div`
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #36393D;
`

const CollapseBody = styled.div`
    margin-top: 36px;
    margin-bottom: 24px;
    transition: display .5s ease-out;
    display: none;
    &.open {
        display: block;
    }
`

const HomePage = ({
    icon,
    title,
    options,
    subtitle,
    onClick
}: IProps) => {
    const [open, isOpen] = React.useState(true)

    return (
        <CollapseWrapper>
            <CollapseHeader onClick={() => isOpen(!open)}>
                <HeaderContainer className={classNames({ open: open })}>
                    <LogoContainer>
                        <Icon src={icon} />
                    </LogoContainer>
                    <InfoContainer>
                        <TitleContainer>
                            <Title>{title}</Title>
                        </TitleContainer>
                        <SubtitleContainer>
                            <Subtitle>{subtitle}</Subtitle>
                        </SubtitleContainer>
                    </InfoContainer>
                </HeaderContainer>
            </CollapseHeader>
            <CollapseBody className={classNames({ open: open })}>
                {options?.map((item, idx) => (
                    <Letter
                        key={idx}
                        code={item.code}
                        name={item.name}
                        money={item.money}
                        image={item.image}
                        overdue={item.overdue}
                        location={item.location}
                        proform={item.proform}
                        direction={item.direction}
                        totalAmount={item.totalAmount}
                        totalLetter={item.totalLetter}
                        partialLetter={item.partialLetter}
                        partialAmount={item.partialAmount}
                        type={item.type}
                        onClick={onClick}
                    />
                ))}
            </CollapseBody>
        </CollapseWrapper>
    )
}

export default HomePage