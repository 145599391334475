import React from 'react'
import Collapsible from 'react-collapsible'
import { useNavigate } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { RootState } from '../../../store/modules'
import { fetchProject } from '../../../store/modules/home/actions'
import { fetchHideLoading, fetchShowLoading } from '../../../store/modules/layout/actions'

import Menu from '../../../components/Menu'
import Empty from '../../../components/Empty'
import Profile from '../../../components/Profile'
import Header from '../../../components/Payment/Cash/Header'
import Pending from '../../../components/Payment/Cash/Pending'
import Completed from '../../../components/Payment/Cash/Completed'

import { projectServices } from '../../../services/projectServices'

import { normalizeReceipt } from '../../../utils/schemas/cash'
import { mediaQuery as media } from '../../../utils/mediaQuery'
import { normalizeCompleted, normalizePending } from '../../../utils/schemas/home'

import cash from '../../../assets/images/svg/ic-cash.svg'
import creditCard from '../../../assets/images/svg/ic-credit-card.svg'
import bill from '../../../assets/images/svg/ic-bill.svg'
import { fetchAuthUserSuccess } from '../../../store/modules/user/actions'

const HomeWrapper = styled.div`
    padding: 24px 16px;
    ${media.md`
        padding: 40px 16px;
    `}
    ${media.lg`
        padding: 40px 140px;
    `}
`

const HomeContainer = styled.div`
    ${media.md`
        display: flex;
        align-items: flex-start;
    `}
`

const HomeHeader = styled.div`
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 8px 24px rgba(42, 55, 67, 0.12);
    border-radius: 8px;
    ${media.md`
        max-width: 264px;
    `}
`

const HeaderContainer = styled.div`
    padding: 12px 12px 0px;
    ${media.md`
        padding: 0px;
    `}
`

const ProfileContainer = styled.div`
    padding-bottom: 24px;
    ${media.md`
        display: flex;
        justify-content: center;
        padding-top: 24px;
    `}
`

const MenuContainer = styled.div`
    margin: 0px -12px;
    ${media.md`
        margin: 0px;
    `}
`

const HomeBody = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 8px 24px rgba(42, 55, 67, 0.12);
    border-radius: 8px;
    margin-top: 24px;
    padding: 19px 12px;
    ${media.md`
        width: 100%;
        margin-top: 0px;
        margin-left: 36px;
        padding: 24px;
    `}
`

const TitleContainer = styled.div``

const Title = styled.div`
    display: flex;
    align-items: center;
`

const Icon = styled.img``

const TitleText = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    color: #36393D;
    margin-left: 12px;
`

const TabContainer = styled.div`
    margin-top: 24px;
`

const CollapseContainer = styled.div`
    border: 1px solid #93A7D5;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px;
    margin-top: 24px;
    ${media.md`
        padding: 16px;
    `}
`

const NameContainer = styled.div`
    ${media.sm`
        margin-left: 25px;
        margin-bottom: 25px;
    `}
    h2 {
        color: #284fae;
        margin-bottom: 0px;
        font-weight: 900;        
    }
    h4 {
        color: #284fae;
        margin-top: 0px;
    }
`

const EmptyContainer = styled.div``

const HomePage = () => {
    const history = useNavigate()
    const dispatch = useDispatch()
    const user = useSelector((state: RootState) => state.user)
    const tipoDocumento = useSelector((state: RootState) => state.user.tipoDocumento)
    const nroDocumento = useSelector((state: RootState) => state.user.nroDocumento)
    const nombreCliente = useSelector((state: RootState) => state.user.nombreCliente)

    const [pendingList, setPendingList] = React.useState<any>([])
    const [completedList, setCompletedList] = React.useState<any>([])

    React.useEffect(() => {
        dispatch(fetchShowLoading())
        projectServices.getSeparationCash(tipoDocumento, nroDocumento).then(response => {
            const { ZWS_CONSULTA_CLIENTER1R2Response } = response.data
            const { e_GENERALField, t_ESTACCField } = ZWS_CONSULTA_CLIENTER1R2Response
            setPendingList(normalizePending(t_ESTACCField.filter(x => x.eSTADOField === 'PENDIENTE DE PAGO')))
            setCompletedList(normalizeCompleted(t_ESTACCField.filter(x => x.eSTADOField === 'PAGADA')))
            dispatch(fetchProject(t_ESTACCField))
            dispatch(fetchAuthUserSuccess(e_GENERALField))
            dispatch(fetchHideLoading())
        }).catch(_ => {
            dispatch(fetchHideLoading())
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleCheckout = (document) => {
        history(`/checkout/${document.bELNRField}`)
    }

    const handleDownload = (item) => {
        dispatch(fetchShowLoading())
        projectServices.getReceipt(normalizeReceipt(item, user)).then(response => {
            const fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}))
            const a = document.createElement('a')
            a.download = `Recibo_de_pago_${item.bELNRField}.pdf`;
            a.href = fileURL
            a.click()
            a.parentNode?.removeChild(a)
            dispatch(fetchHideLoading())
        }).catch(_ => {
            dispatch(fetchHideLoading())
        })
    }

    return (
        <React.Fragment>
            <HomeWrapper>
                <HomeContainer>
                    <HomeHeader>
                        <HeaderContainer>
                            <ProfileContainer>
                                <Profile name={nombreCliente} />
                            </ProfileContainer>
                            <MenuContainer>
                                <Menu
                                    options={[{
                                        icon: creditCard,
                                        name: 'Mis pagos',
                                        redirect: '/'
                                    }]}
                                />
                            </MenuContainer>
                        </HeaderContainer>
                    </HomeHeader>
                    <HomeBody>
                        <NameContainer>
                            <h2>Este es el primer paso</h2>
                            <h4>para cumplir el sueño de  la casa propia</h4>
                        </NameContainer>
                        <TitleContainer>
                            <Title>
                                <Icon src={cash} />
                                <TitleText>Mis pagos</TitleText>
                            </Title>
                        </TitleContainer>
                        <TabContainer>
                            <Tabs>
                                <TabList>
                                    <Tab>Pagos pendientes</Tab>
                                    <Tab>Pagos realizados</Tab>
                                </TabList>
                                <TabPanel>
                                    {pendingList.length ? (
                                        pendingList.map((pending, idx) => (                                            
                                            <CollapseContainer key={Math.random()}>
                                                <Collapsible
                                                    open={idx === 0}
                                                    transitionTime={200}
                                                    trigger={
                                                        <Header
                                                            proyect={pending.name}
                                                        />
                                                    }>
                                                    <React.Fragment>
                                                        {pending.items.map(item => (
                                                            <Pending
                                                                onClick={() => handleCheckout(item)}
                                                                symbol={item.mONEDAField}
                                                                amount={item.iMPORTEField}
                                                                type={item.bLARTField}
                                                                expiration={item.fECHAVENCIMIENTOField}
                                                                documentNumber={item.bELNRField}
                                                            />
                                                        ))}
                                                    </React.Fragment>
                                                </Collapsible>
                                            </CollapseContainer>
                                        ))
                                    ) : (
                                        <EmptyContainer>
                                            <Empty
                                                icon={bill}
                                                text={['No tienes ningún pago pendiente']}
                                            />
                                        </EmptyContainer>
                                    )}
                                </TabPanel>
                                <TabPanel>
                                    {completedList.length ? (
                                        completedList.map((completed, idx) => (
                                            <CollapseContainer key={Math.random()}>
                                                <Collapsible
                                                    open={idx === 0}
                                                    transitionTime={200}
                                                    trigger={
                                                        <Header
                                                            proyect={completed.name}
                                                        />
                                                    }>
                                                    <React.Fragment>
                                                        {completed.items.map((item, idx) => (
                                                            <Completed
                                                                id={`${idx + 1}`}
                                                                type={item.bLARTField}
                                                                expiration={item.fECHAVENCIMIENTOField}
                                                                date={item.FechaOperacionIzi}
                                                                symbol={item.mONEDAField}
                                                                amount={item.ImportePago}
                                                                documentNumber={item.bELNRField}
                                                                onClick={() => handleDownload(item)}
                                                            />
                                                        ))}
                                                    </React.Fragment>
                                                </Collapsible>
                                            </CollapseContainer>
                                        ))
                                    ) : (
                                        <EmptyContainer>
                                            <Empty
                                                icon={bill}
                                                text={['No tienes ningún pago realizado']}
                                            />
                                        </EmptyContainer>
                                    )}
                                </TabPanel>
                            </Tabs>
                        </TabContainer>
                    </HomeBody>
                </HomeContainer>
            </HomeWrapper>
        </React.Fragment>
    )
}

export default HomePage