import * as yup from 'yup'

export const schema = (max) => yup.object().shape({
    amount: yup.string()
        .required('El campo es requerido')
        .min(1, 'El monto ingresado es inferior al permitido'),
    date: yup.string()
        .required('La fecha es requerida'),
    fee: yup.number()
        .typeError('El campo es requerido')
        .required()
        .min(1, 'El monto ingresado es inferior al permitido')
        .max(max, 'El monto ingresado es superior al permitido')
})