export const normalizeConsulta = (tipoDocumento, nroDocumento, image, data) => {
    return {
        tipoDocumento: tipoDocumento,
        nroDocumento: nroDocumento,
        proyecto: data.project,
        asunto: data.subject,
        consulta: data.query,
        file: (image ? {
            base64: image.base64.replace(/^data:image\/[a-z]+;base64,/, ""),
            fileName: image.fileName
        } : null)
    }
}

export const normalizeHilo = (tipoDocumento, nroDocumento, image, data, nHilo, nConsulta) => {
    return {
        tipoDocumento: tipoDocumento,
        nroDocumento: nroDocumento,
        contenido: data.answer,
        nroHilo: nHilo,
        nroConsulta: nConsulta,
        file:(image ?{
            base64: image.base64.replace(/^data:image\/[a-z]+;base64,/, ""),
            fileName: image.fileName
        }: null)
    }
}