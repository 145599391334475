import React from 'react'
import { Outlet } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../store/modules'

import { fetchHideNotification } from '../../store/modules/layout/actions'
import { fetchAvailableLogout } from '../../store/modules/login/actions'

import Header from '../../components/Header'
import Spinner from '../../components/Spinner'
import Notification from '../../components/Notification'

import mail from '../../assets/images/svg/ic-mail.svg'

const MainLayout = () => {
    const dispatch = useDispatch()
    const loading = useSelector((state: RootState) => state.layout.loading)
    const notification = useSelector((state: RootState) => state.layout.notification)
    const token = useSelector((state: RootState) => state.user.token)

    const handleNotification = () => {
        dispatch(fetchHideNotification())
    }

    const handleLogout = () => {
        dispatch(fetchAvailableLogout())
    }

    return (
        <React.Fragment>
            <Spinner isShow={loading} />
            <Notification
                icon={mail}
                show={notification}
                onClose={handleNotification}
                text='Hemos enviado un correo con la confirmación de la desafiliación de tu tarjeta al débito automático'
            />
            <Header onClick={handleLogout} token={token} />
            <Outlet />
        </React.Fragment>
    )
}

export default MainLayout