import React from 'react'
import NumberFormat from 'react-number-format'
import styled from 'styled-components'

import { mediaQuery as media } from '../../../utils/mediaQuery'

import document from '../../../assets/images/svg/ic-document.svg'
import download from '../../../assets/images/svg/ic-download.svg'
import moment from 'moment'

interface IProps {
    id: string
    date: string
    type: string
    symbol: string
    amount: number
    expiration: string
    documentNumber: string
    onClick: () => void
}

const BodyWrapper = styled.div`
    border: 1px solid rgba(0, 104, 172, 0.2);
    border-radius: 8px;
    padding: 16px 18px;
    &:not(:last-child) {
        margin-bottom: 16px;
    }
    ${media.sm`
        padding: 0px;
    `}
`

const BodyContainer = styled.div`
    ${media.sm`
        display: flex;
        align-items: center;
        justify-content: space-between;
    `}
`

const InfoContainer = styled.div`
    width: 100%;
    ${media.sm`
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 16px;
    `}
`

const DocumentContainer = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    ${media.sm`
        text-align: right;
        padding-bottom: 0px;
    `}
`

const IconContainer = styled.div`
    display: flex;
`

const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    ${media.sm`
        flex-direction: row;
        align-items: center;
    `}
`

const Icon = styled.img``

const CodeContainer = styled.div`
    margin-left: 8px;
`

const TextContainer = styled.div``

const Text = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;  
    color: #82949E;  
`

const NumberContainer = styled.div``

const Number = styled.div`
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: #36393D;
`

const DetailContainer = styled.div``

const AmountContainer = styled.div`
    display: flex;
    align-items: center;
    ${media.sm`
        padding-bottom: 0px;
        justify-content: flex-end;
    `}
`

const DateContainer = styled.div`
    padding-bottom: 16px;
    ${media.sm`
        padding-bottom: 0px;
    `}
`

const DateWrapper = styled.div`
    ${media.sm`
        margin-left: 34px;
    `}
`

const Dates = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #82949E;
    margin-top: 4px;
    ${media.sm`
        text-align: right;
    `}
`

const TypeContainer = styled.div``

const Type = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;  
    color: #82949E;
    ${media.sm`
        text-align: left;
    `}
`

const Amount = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #36393D;
`

const Correlative = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    // padding-left: 10px;
    margin-left: 10px;
    margin-right: 20px;
    color: #82949E;
    position: relative;
    // &::before {
    //     content: '';
    //     width: 4px;
    //     height: 4px;
    //     position: absolute;
    //     background: #36393E;
    //     border-radius: 4px;
    //     left: -2px;
    //     top: calc(50% - 2px);
    // }
    & span {
        margin-left: 6px;
        padding: 4px 8px;
        background: #4074FE;
        border-radius: 4px;
        font-style: normal;
        font-weight: 800;
        font-size: 12px;
        line-height: 16px;
        color: #FFFFFF;
    }
`

const DownloadContainer = styled.div`
    display: flex;
    padding-top: 16px;
    justify-content: center;
    border-top: 1px solid #82949E;
    ${media.sm`
        padding: 18px 16px;
        border-top: none;
        border-left: 1px solid #82949E;
    `}
`

const Download = styled.a`
    display: block;
    cursor: pointer;
`

const Img = styled.img``

const Body = ({
    id,
    date,
    type,
    symbol,
    amount,
    onClick,
    expiration,
    documentNumber
}: IProps) => {
    return (
        <BodyWrapper>
            <BodyContainer>
                <InfoContainer>
                    <InfoWrapper>
                        <DocumentContainer>
                            <Correlative>
                                <span>{id.padStart(2, '0')}</span>
                            </Correlative>
                            <IconContainer>
                                <Icon src={document} />
                            </IconContainer>
                            <CodeContainer>
                                <TextContainer>
                                    <Text>N° documento</Text>
                                </TextContainer>
                                <NumberContainer>
                                    <Number>{documentNumber}</Number>
                                </NumberContainer>
                            </CodeContainer>
                        </DocumentContainer>
                        <DateContainer>
                            <DateWrapper>
                                <TypeContainer>
                                    <Type>{type === 'R1' ? 'Separa tu lote' : 'Pago Inicial'}</Type>
                                </TypeContainer>
                                <Dates>Día pagado: {moment(date).format('DD/MM/YYYY')}</Dates>
                            </DateWrapper>
                        </DateContainer>
                    </InfoWrapper>
                    <DetailContainer>
                        <AmountContainer>
                            <Amount>
                                <NumberFormat
                                    prefix={symbol === 'USD' ? 'US$' : 'S/'}
                                    decimalSeparator='.'
                                    displayType='text'
                                    type='text'
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    value={amount}
                                />
                            </Amount>                            
                        </AmountContainer>
                        <DateContainer>
                            <Dates>
                                Fecha de vencimiento: {moment(expiration).format('DD/MM/YYYY')}
                            </Dates>
                        </DateContainer>
                    </DetailContainer>
                </InfoContainer>
                <DownloadContainer>
                    <Download onClick={onClick}>
                        <Img src={download} />
                    </Download>
                </DownloadContainer>
            </BodyContainer>
        </BodyWrapper>
    )
}

export default Body