import React from 'react'
import { useNavigate, NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { RootState } from '../../../store/modules'

import { fetchHideLoading, fetchShowLoading } from '../../../store/modules/layout/actions'

import Empty from '../../../components/Empty'

import { mediaQuery as media } from '../../../utils/mediaQuery'

import empty from '../../../assets/images/svg/ic-empty-query.svg'

import { projectServices } from '../../../services/projectServices'

import close from '../../../assets/images/svg/ic-close-green.svg'

const AnotherWrapper = styled.div``

const AnotherContainer = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 8px 24px rgba(42, 55, 67, 0.12);
    border-radius: 8px;
    padding: 19px;
    ${media.md`
        padding: 24px 24px 52px;
    `}
`

const HeaderContainer = styled.div``

const TitleContainer = styled.div``

const Title = styled.div`
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 26px;
    color: #284FAE;
    ${media.md`
        font-size: 24px;
        line-height: 32px;
    `}
`

const BodyWrapper = styled.div`
    margin-top: 15px;
    ${media.md`
        background: #FFFFFF;
        box-shadow: 0px 8px 24px rgba(42, 55, 67, 0.12);
        border-radius: 8px;
        margin-top: 24px;
        padding: 10px 41px 18px;
    `}
`

const BodyContainer = styled.div``

const ButtonContainer = styled.div`
    margin-top: 24px;
    margin-bottom: 24px;
    text-align: right;
`

const Button = styled(NavLink)`
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    padding: 10px 20px;
    background: #F6A41A;
    border-radius: 4px;
    border: 1px solid #F6A41A;
    cursor: pointer;
    &:disabled {
        color: #82949E;
        cursor: not-allowed;
        background: #E6EAEC;
        border: 1px solid #82949E;
    }
`

const TableContainer = styled.div`
    width: 100%;
    overflow-x: auto;
`

const Table = styled.table`
    width: 100%;
`

const THead = styled.thead``

const Tr = styled.tr``

const Th = styled.th`
    font-style: normal;
    font-weight: 800;
    padding: 17px 10px 15px;
    color: #284FAE;
    text-align: left;
    border-top: 1px solid rgba(40,79,171,.2);
    border-bottom: 1px solid rgba(40,79,171,.2);
`

const TBody = styled.thead`
    & tr:nth-child(2n) {
        background: rgba(40,79,171,.03);
    }
`

const Td = styled.td`
    font-style: normal;
    font-weight: normal;
    padding: 17px 10px 15px;
    font-size: 14px;
    line-height: 22px;
`

const Link = styled(NavLink)`
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 20px;
    font-style: normal;
    font-weight: 700;
    color: #fff;
    background: #40af55;
    padding: 2px 10px 1px;
    border-radius: 3px;
    text-decoration: none;
`


const MessageContainerError = styled.div`
    background: #F8D8D8;
    border: 0.5px solid #E97070;
    box-sizing: border-box;
    border-radius: 3px;
    margin-top: 20px;
    position: relative;
    padding: 16px 44px 16px 16px;
    ${media.md`
        width: 50%;
        max-width: 364px;
    `}
`
const MessageError = styled.div`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: #E40D0D;
`

const Close = styled.a`
    position: absolute;
    right: 10px;
    top: calc(50% - 8px);
    cursor: pointer;
`

const RequestPage = () => {
    const history = useNavigate()
    const dispatch = useDispatch()
    const tipoDocumento = useSelector((state: RootState) => state.user.tipoDocumento)
    const nroDocumento = useSelector((state: RootState) => state.user.nroDocumento)

    const [recordList, setRecordList] = React.useState<any>([])
    const [errorMessage, setErrorMessage] = React.useState('')
    const [error, setError] = React.useState(false)

    React.useEffect(() => {
        dispatch(fetchShowLoading())
        projectServices.getHistoric(tipoDocumento, nroDocumento).then(response => {
            console.log("GetAllConsultaWebMovilListado");
            console.log(response.data);
            if(response.data.errorHttp!=null){
                setErrorMessage(response.data.errorHttp);
                setError(true);
                setTimeout(() => {
                    setError(false)
                }, 4000)     
            }else{
                const { getAllConsultaWebMovilListadoResult } = response.data.body
                setRecordList(getAllConsultaWebMovilListadoResult.filter(x => x.estadoConsulta !== 'CERRADO'))
            }

            dispatch(fetchHideLoading())
        }).catch(_ => {
            dispatch(fetchHideLoading())
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [])

    const handleNewRecord = () => {
        history(`/query/request/new`)
    }

    return (
        <AnotherWrapper>
            <AnotherContainer>
                <HeaderContainer>
                    <TitleContainer>
                        <Title>Consulta - Solicitudes</Title>
                    </TitleContainer>
                </HeaderContainer>
                {recordList.length ? (
                    <BodyWrapper>
                        <BodyContainer>
                            <ButtonContainer>
                                <Button to={'/query/request/new'}>Nueva consulta</Button>
                            </ButtonContainer>
                            <TableContainer>
                                <Table cellSpacing={0}>
                                    <THead>
                                        <Tr>
                                            <Th>Fecha de creación</Th>
                                            <Th>Fecha de última interacción</Th>
                                            <Th>Asunto</Th>
                                            <Th>Nombre del proyecto</Th>
                                            <Th>Estado</Th>
                                            <Th></Th>
                                        </Tr>
                                    </THead>
                                    <TBody>
                                        {recordList.map(item => (
                                            <Tr>
                                                <Td>{item.fechaCreacionConsulta.substring(0, 10)}</Td>
                                                <Td>{item.fechaCreacionHilo.substring(0, 10)}</Td>
                                                <Td>{item.asunto}</Td>
                                                <Td>{item.proyecto}</Td>
                                                <Td>{item.estadoConsulta}</Td>
                                                <Td>
                                                    <Link to={`/query/request/${item.nConsulta}`}>VER</Link>
                                                </Td>
                                            </Tr>
                                        ))}
                                    </TBody>
                                </Table>
                            </TableContainer>
                        </BodyContainer>
                    </BodyWrapper>
                ) : (
                    <Empty
                        icon={empty}
                        text={['No hay consultas abiertas.', 'Si deseas realizar una consulta, haz click en el botón']}
                        title='Nueva consulta'
                        onClick={handleNewRecord}
                    />
                )}
            </AnotherContainer>
            {error && (
                                    <MessageContainerError>
                                        <MessageError>{errorMessage}</MessageError>
                                        <Close onClick={() => setError(false)}><img src={close} alt='' /></Close>
                                    </MessageContainerError>
                                )}
        </AnotherWrapper>
    )
}

export default RequestPage