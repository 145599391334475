import React from 'react'
import PropTypes from 'prop-types'
import { useParams, useNavigate, NavLink, useLocation, Outlet } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { RootState } from '../../store/modules'
import { fetchProductSelected } from '../../store/modules/product/actions'

import Menu from '../../components/Menu'
import Profile from '../../components/Profile'

import useMedia from '../../hooks/useMedia'

import { projectServices } from '../../services/projectServices'

import { mediaQuery as media } from '../../utils/mediaQuery'

import creditCard from '../../assets/images/svg/ic-credit-card.svg'
import debit from '../../assets/images/svg/ic-debit.svg'
import file from '../../assets/images/svg/ic-file.svg'
import location from '../../assets/images/svg/ic-location.svg'
import folder from '../../assets/images/svg/ic-folder.svg'
import eye from '../../assets/images/svg/ic-eye.svg'
import close from '../../assets/images/svg/ic-close.svg'
import arrow from '../../assets/images/svg/ic-arrow-left-blue.svg'
import affiliation from '../../assets/images/svg/ic-affiliation.svg'

type IProps = {
    children?: React.ReactNode
}

const LayoutWrapper = styled.div``

const LayoutContainer = styled.div`
    padding: 24px 16px;
    ${media.md`
        padding: 40px 16px;
        display: flex;
        align-items: stretch;
    `}
    ${media.lg`
        padding: 40px 140px;
    `}
`

const LinkContainer = styled.div`
    filter: drop-shadow(0px 8px 24px rgba(42, 55, 67, 0.12));
    background: #ffffff;
    padding: 15px 16px;
    ${media.lg`
        padding: 15px 140px;
    `}
`

const Link = styled(NavLink)`
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-decoration: none;
    color: #284FAE;
    &::before {
        content: '';
        width: 9px;
        height: 16px;
        margin-right: 12px;
        background: url(${arrow})
    }
`

const LayoutHeader = styled.div`
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 8px 24px rgba(42, 55, 67, 0.12);
    border-radius: 8px;
    ${media.md`
        max-width: 324px;
        max-height: 420px;
    `}
`

const HeaderContainer = styled.div`
    padding: 12px 12px 0px;
    ${media.md`
        padding: 0px;
    `}
`

const ProfileContainer = styled.div`
    padding-bottom: 24px;
    ${media.md`
        display: flex;
        justify-content: center;
        padding-top: 24px;
    `}
`

const MenuContainer = styled.div`
    margin: 0px -12px;
    ${media.md`
        margin: 0px;
    `}
`

const LayoutBody = styled.div`
    margin-top: 24px;
    ${media.md`
        width: 100%;
        margin-top: 0px;
        margin-left: 36px;
    `}
`

const BodyContainer = styled.div`
    width: 100%;
    height: 100%;
`

const ModalContainer = styled.div`
    display: flex;
    position: fixed;
    bottom: 24px;
    left: 24px;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 16px 49px 16px 16px;
    filter: drop-shadow(0px 8px 24px rgba(42, 55, 67, 0.12));
`

const CloseContainer = styled.div`
    cursor: pointer;
    position: absolute;
    right: 17px;
    top: 20px;
`

const ImageContainer = styled.div``

const Image = styled.img``

const InfoContainer = styled.div`
    padding-left: 16px;
`

const TitleContainer = styled.div``

const Title = styled.div`
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #36393D;
`

const ParagraphContainer = styled.div`
    max-width: 325px;
    margin-top: 4px;
`

const Paragraph = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #36393D;

`

const ButtonContainer = styled.div`
    margin-top: 16px;
`

const Button = styled.a`
    display: inline-block;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    padding: 8px 10px;
    background: #772CBD;
    border-radius: 4px;
    text-decoration: none;
`

const AdminLayout = ({
    children
}: IProps) => {
    const { id } = useParams<any>()
    const { isMobile } = useMedia()
    const history = useNavigate()
    const dispatch = useDispatch()
    const { pathname } = useLocation()
    const nombreCliente = useSelector((state: RootState) => state.user.nombreCliente)
    const tipoDocumento = useSelector((state: RootState) => state.user.tipoDocumento)
    const nroDocumento = useSelector((state: RootState) => state.user.nroDocumento)
    const productList = useSelector((state: RootState) => state.home.productList)

    const [isOpen, setIsopen] = React.useState(true)

    const options = [
        {
            icon: creditCard,
            name: 'Mis pagos',
            redirect: `/property/${id}/payment`
        },
        // {
        //     icon: debit,
        //     name: 'Débito automático',
        //     redirect: `/property/${id}/debit`
        // },
        {
            icon: file,
            name: 'Historial de pago',
            redirect: `/property/${id}/historic`
        },
        {
            icon: location,
            name: 'Avances de obra',
            redirect: `/property/${id}/advance`
        },
        {
            icon: folder,
            name: 'Documentos',
            redirect: `/property/${id}/documents`
        },
        {
            icon: eye,
            name: 'Avisos',
            redirect: `/property/${id}/notices`
        }
    ]

    React.useEffect(() => {
        const product = productList.find(x => x.pROFORMAField === id)
        projectServices.postLetterProduct(tipoDocumento, nroDocumento, [{ ...product }]).then(result => {
            const newItem = result.data[0]
            dispatch(fetchProductSelected({ ...newItem }))
        }).catch(_ => { })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getInitialSlide = () => {
        return options.findIndex(x => x.redirect === pathname)
    }

    const onBeforeChange = (next) => {
        history(options[next].redirect)
    }

    const handleCloseModal = () => {
        setIsopen(false)
    }

    return (
        <React.Fragment>
            <LayoutWrapper>
                <LinkContainer>
                    <Link to='/'>
                        {!isMobile && 'Volver atrás'}
                    </Link>
                </LinkContainer>
                <LayoutContainer>
                    <LayoutHeader>
                        <HeaderContainer>
                            <ProfileContainer>
                                <Profile name={nombreCliente} />
                            </ProfileContainer>
                            <MenuContainer>
                                <Menu
                                    options={options}
                                    initialSlide={getInitialSlide()}
                                    onBeforeChange={(e) => onBeforeChange(e)}
                                />
                            </MenuContainer>
                        </HeaderContainer>
                    </LayoutHeader>
                    <LayoutBody>
                        <BodyContainer>
                            <Outlet />
                        </BodyContainer>
                    </LayoutBody>
                </LayoutContainer>
                {/* {(!isMobile && isOpen) && (
                    <ModalContainer>
                        <CloseContainer onClick={handleCloseModal}>
                            <Image width='16px' height='16px' src={close} />
                        </CloseContainer>
                        <ImageContainer>
                            <Image src={affiliation} />
                        </ImageContainer>
                        <InfoContainer>
                            <TitleContainer>
                                <Title>¿Quieres afiliarte al pago automático?</Title>
                            </TitleContainer>
                            <ParagraphContainer>
                                <Paragraph>Al afiliarte, el pago que selecciones se cargará automáticamente a la tarjeta de crédito o cuenta que elijas.</Paragraph>
                            </ParagraphContainer>
                            <ButtonContainer>
                                <Button href={`/property/${id}/debit`}>Me interesa</Button>
                            </ButtonContainer>
                        </InfoContainer>
                    </ModalContainer>
                )} */}
            </LayoutWrapper>
        </React.Fragment>
    )
}

AdminLayout.propTypes = {
    children: PropTypes.node
}

export default AdminLayout