import React from 'react'
import PropTypes from 'prop-types'
import Page from '../../containers/HistoricPage'

const Historic = () => (
    <React.Fragment>
        <Page />
    </React.Fragment>
)

Historic.propTypes = {
    children: PropTypes.node
}

export default Historic