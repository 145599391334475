import React from 'react'
import Collapsible from 'react-collapsible'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { RootState } from '../../store/modules'

import { fetchHideLoading, fetchShowLoading } from '../../store/modules/layout/actions'

import Empty from '../../components/Empty'
import Header from '../../components/Payment/Letter/Header'
import Completed from '../../components/Payment/Letter/Completed'

import { mediaQuery as media } from '../../utils/mediaQuery'
import { normalizeCompleted } from '../../utils/schemas/admin'

import { projectServices } from '../../services/projectServices'

import cash from '../../assets/images/svg/ic-cash.svg'
import bill from '../../assets/images/svg/ic-bill.svg'
import credit from '../../assets/images/svg/ic-credit-card-yellow.svg'

const HistoricWrapper = styled.div``

const HistoricContainer = styled.div`
    background: #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 8px 24px rgba(42, 55, 67, 0.12);
    border-radius: 8px;
    padding: 19px 12px;
    ${media.md`
        padding: 24px;
    `}
`

const HistoricHeader = styled.div`
    ${media.sm`
        display: flex;
        align-items: center;
        justify-content: space-between;
    `}
`

const HeaderContainer = styled.div`
    ${media.sm`
        display: flex;
        align-items: center;
    `}
`

const TitleContainer = styled.div`
    ${media.sm`
        padding-right: 16px;
    `}
`

const Title = styled.div`
    display: flex;
    align-items: center;
`

const TitleText = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    color: #36393D;
    margin-left: 12px;
`

const LetterContainer = styled.div`
    ${media.sm`
        padding-left: 16px;
        border-left: 1px solid #36393D;
    `}
`

const Letter = styled.div`
    display: flex;
    align-items: center;
`

const LetterText = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #36393D;
    margin-left: 9px;
`

const ExportContainer = styled.div`
    margin-top: 24px;
    text-align: right;
    ${media.sm`
        margin-top: 0px;
    `}
`

const Export = styled.button`
    padding: 10px 20px;
    border: 1px solid #F6A41A; 
    background: #F6A41A;
    border-radius: 4px;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    cursor: pointer;
`

const HistoricBody = styled.div``

const Icon = styled.img``

const CollapseContainer = styled.div`
    border: 1px solid #93A7D5;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px;
    margin-top: 24px;
    ${media.sm`
        padding: 16px;
    `}
`

const EmptyContainer = styled.div``

const HistoricPage = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const tipoDocumento = useSelector((state: RootState) => state.user.tipoDocumento)
    const nroDocumento = useSelector((state: RootState) => state.user.nroDocumento)
    const projectList = useSelector((state: RootState) => state.home.projectList)

    const [list, setList] = React.useState<any>([])
    const [filter, setFilter] = React.useState<any>([])
    const [completedList, setCompletedList] = React.useState<any>([])

    React.useEffect(() => {
        const filter = projectList.filter(x => x.pROFORMAField === id)
        setList(filter)
        setFilter(filter.filter(x => x.eSTADOLETRAField === 'PAGADA'))
        setCompletedList(normalizeCompleted(filter.filter(x => x.eSTADOLETRAField === 'PAGADA')))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleExport = () => {
        dispatch(fetchShowLoading())
        projectServices.getReport(tipoDocumento, nroDocumento, id ?? '').then(response => {
            const fileURL = window.URL.createObjectURL(new Blob([response.data], { type: "application/vnd.ms-excel" }))
            const a = document.createElement('a')
            a.download = "ReporteLetras.xlsx";
            a.href = fileURL
            a.click()
            a.parentNode?.removeChild(a)
            dispatch(fetchHideLoading())
        }).catch(_ => {
            dispatch(fetchHideLoading())
        })
    }

    return (
        <React.Fragment>
            <HistoricWrapper>
                <HistoricContainer>
                    <HistoricHeader>
                        <HeaderContainer>
                            <TitleContainer>
                                <Title>
                                    <Icon src={cash} />
                                    <TitleText>Mis pagos</TitleText>
                                </Title>
                            </TitleContainer>
                            <LetterContainer>
                                <Letter>
                                    <Icon src={credit} />
                                    <LetterText>Letras pagadas: {filter.length} de {list.length}</LetterText>
                                </Letter>
                            </LetterContainer>
                        </HeaderContainer>
                        <ExportContainer>
                            <Export onClick={handleExport}>Exportar</Export>
                        </ExportContainer>
                    </HistoricHeader>
                    <HistoricBody>
                        {completedList.length ? (
                            completedList.map((completed, idx) => (
                                <CollapseContainer key={Math.random()}>
                                    <Collapsible
                                        open={idx === 0}
                                        transitionTime={200}
                                        trigger={
                                            <Header
                                                month={completed.name}
                                                quantity={completed.items.length}
                                            />
                                        }
                                    >
                                        <React.Fragment>
                                            {completed.items.map(item => (
                                                <Completed
                                                    letter={item.nLETRAField}
                                                    type={item.sISTEMAPAGOField === 'IZIP' ? 'Pago online'
                                                        : item.sISTEMAPAGOField === 'IZDA' ? 'Débito automático'
                                                            : item.sISTEMAPAGOField === 'IZTC' ? 'Tipo de cambio preferencial'
                                                                : 'Otros medios'
                                                    }
                                                    symbol={item.MonedaPago}
                                                    amount={item.ImportePago}
                                                    paymentDate={item.fECHAPAGOField}
                                                    expirationDate={item.fECHAVENCIMIENTOField}
                                                />
                                            ))}
                                        </React.Fragment>
                                    </Collapsible>
                                </CollapseContainer>
                            ))
                        ) : (
                            <EmptyContainer>
                                <Empty
                                    icon={bill}
                                    text={['No tienes ningún pago realizado']}
                                />
                            </EmptyContainer>
                        )}
                    </HistoricBody>
                </HistoricContainer>
            </HistoricWrapper>
        </React.Fragment>

    )
}

export default HistoricPage