import React from "react";
import styled from "styled-components";
import { mediaQuery as media } from "../../utils/mediaQuery";
import { FormProvider, useForm } from "react-hook-form";
import Input from "../../components/Input";
import Button from "../../components/Button";
import AceptacionDatosPersonales from "../../components/AceptacionDatosPersonales";
import { schema } from "./validations";
import { yupResolver } from "@hookform/resolvers/yup";
import ModalGeneric from "../../components/ModalRecovered";
import { ErrorMessage } from "@hookform/error-message";
import Alert from "../../components/Alert";
import { useDispatch } from "react-redux";
import {
  fetchHideLoading,
  fetchShowLoading,
} from "../../store/modules/layout/actions";
import { authServices } from "../../services/authServices";
import { fetchAuthUserSuccess } from "../../store/modules/user/actions";
import { fetchUbication } from '../../store/modules/location/actions'
import { projectServices } from '../../services/projectServices'
import { useNavigate } from "react-router-dom";
import { fetchErrorLogin } from "../../store/modules/login/actions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import successIcon from "../../assets/images/svg/ic-success-green.svg";
import errorIcon from "../../assets/images/svg/ic-error-red.svg";
import { WEBORIGEN_DEPARTAMENTO } from "../../utils/constants/login";

const MySwal = withReactContent(Swal);

const LoginWrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background: #ffffff;
  ${media.lg`
        height: calc(100vh - 72px);
        min-height: auto;
    `}
`;

const LoginContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  -webkit-box-shadow: 0px 0px 2px 0px #000000;
  box-shadow: 0px 0px 2px 0px #000000;
  box-sizing: border-box;
  padding: 24px 16px 42px 16px;
  ${media.sm`
        padding: 24px 36px;
        max-width: 436px;
        border-radius: 8px;
    `}
`;

const ErrorContainer = styled.div`
  width: 100%;
  padding: 0px 16px;
  margin-top: 16px;
  box-sizing: border-box;
  ${media.sm`
        margin-top: 0px;
        margin-bottom: 16px;
        padding: 0px;
        max-width: 436px;
    `}
`;

const TitleContainer = styled.div`
  text-align: center;
`;

const Title = styled.h2`
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  color: #284fab;
  margin: 0px;
`;
const FormContainer = styled.div`
  margin-top: 24px;
`;

const DocumentContainer = styled.div`
  margin-bottom: 10px;
`;

const ButtonContainer = styled.div`
  margin-top: 18px;
`;

const Label = styled.label`
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  display: block;
  margin-bottom: 7px;
`;

const AceptacionContainer = styled.div`
  color: #fff;
  font-size: 12px;
`;

const OlvidoContrasenaContainer = styled.div`
  text-align: center;
  margin-top: 7px;
  font-size: 12px;
`;

const InputRecover = styled.input`
  width: 100%;
  height: 100%;
  max-height: 55px;
  font-family: "Nunito", sans-serif;
  font-style: normal;
  background: transparent;
  color: black;
  padding: 15px 12px;
  box-sizing: border-box;
  font-size: 16px;
  border-radius: 4px;
  line-height: 24px;
  border: 2px solid
    ${(props) => (props.value || props.defaultValue ? "#BECAE6" : "#BECAE6")};
  &:focus {
    outline: none;
    border: 1px solid #becae6;
  }
  &::placeholder {
    color: #bdb8b8;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
  }
`;

const RedirectLoginAnchor = styled.a`
  color: rgb(90, 133, 196);
  font-weight: bold;
`;

const Error = ({ errors, name }) => (
  <ErrorMessage
    errors={errors}
    name={name}
    render={({ message }) => (
      <ErrorContainer>
        <Alert type="error" message={message} />
      </ErrorContainer>
    )}
  />
);

const LoginMiCuentaPage = () => {
  const [isRecoverModalOpen, setIsRecoverModalOpen] = React.useState(false);
  const [emailForRecoverPassword, setEmailForRecoverPassword] =
    React.useState("");
  const [errorInRecoverEmail, setErrorInRecoverEmail] = React.useState(false);

  const dispatch = useDispatch();
  const history = useNavigate();

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    formState: { errors, isValid },
  } = methods;

  const onSubmit = (data) => {
    const dataForLogin = {
      correo: data.user,
      clave: data.password,
      aceptaTratamiento: data.aceptaTratamiento,
      aceptaDatosPersonales: data.aceptaDatosPersonales,
      origen: WEBORIGEN_DEPARTAMENTO
    };

    dispatch(fetchShowLoading());
    authServices
      .authenticationWithEmail(dataForLogin)
      .then((response) => {
        dispatch(
          fetchAuthUserSuccess({
            ...response.data,
            ...data,
            nroDocumento: response.data.numeroDocumento,
            tipoDocumento: "0" + response.data.idTipoDocumento
          })
        );
        window.localStorage.setItem("login", "letter");
        window.localStorage.setItem("token", response.data.token);

        projectServices.getDestinations(`0${response.data.idTipoDocumento}`, response.data.numeroDocumento).then(responseUbicacion => {
          dispatch(fetchUbication(responseUbicacion.data))
        }).finally(() => {
          dispatch(fetchHideLoading())
          history("/");
        })
      })
      .catch((e) => {
        dispatch(fetchHideLoading());
        dispatch(
          fetchErrorLogin({ error: true, errorMessage: "Datos no encontrados" })
        );
        MySwal.fire({
          title: <strong>{e.response.data.title}</strong>,
          html: <i>{ `Revise sus datos e intente de nuevo.` }</i>,
          iconHtml: <img src={errorIcon} alt="Error Icon" />,
        });
      });
  };

  const handleOpenRecoverPassword = (e) => {
    e.preventDefault();
    setIsRecoverModalOpen(true);
  };

  const handleRecoverModalClose = () => {
    setIsRecoverModalOpen(false);
  };

  const handleSendRecoverPassword = () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (!emailForRecoverPassword || !emailRegex.test(emailForRecoverPassword)) {
      setErrorInRecoverEmail(true);
      return;
    }

    setErrorInRecoverEmail(false);

    dispatch(fetchShowLoading());
    authServices
      .sendEmailForRecoverPassword({
        correo: emailForRecoverPassword,
        origen: WEBORIGEN_DEPARTAMENTO
      })
      .then((response) => {
        dispatch(fetchHideLoading());
        MySwal.fire({
          title: <strong>Correo enviado</strong>,
          html: (
            <i>
              Revise su bandeja de correo para seguir las instrucciones para
              restablecer su contraseña.
            </i>
          ),
          iconHtml: <img src={successIcon} alt="Success Icon" />,
        });
      })
      .catch((e) => {
        dispatch(fetchHideLoading());
        MySwal.fire({
          title: <strong>Error al enviar el correo</strong>,
          html: <i>{e.response.data.title}</i>,
          iconHtml: <img src={errorIcon} alt="Error Icon" />,
        });
      });

    setEmailForRecoverPassword("");
    setIsRecoverModalOpen(false);
  };

  return (
    <React.Fragment>
      <LoginWrapper>
        <LoginContainer>
          <TitleContainer>
            <Title className="text-3xl font-bold">¡Bienvenido!</Title>
          </TitleContainer>
          <Error errors={errors} name="user" />
          <Error errors={errors} name="password" />
          <ModalGeneric
            title="Recupera tu contraseña"
            open={isRecoverModalOpen}
            onClose={handleRecoverModalClose}
            onClick={handleSendRecoverPassword}
          >
            <DocumentContainer className="w-full">
              {errorInRecoverEmail && (
                <div className="mb-4">
                  <Alert
                    type="error"
                    message={
                      "Error en el email de recuperación, por favor escriba un email válido"
                    }
                  />
                </div>
              )}
              <div className="w-full">
                <Label>Correo</Label>
                <InputRecover
                  placeholder="Correo"
                  type="normal"
                  value={emailForRecoverPassword}
                  onChange={(value) =>
                    setEmailForRecoverPassword(value.target.value)
                  }
                />
              </div>
            </DocumentContainer>
          </ModalGeneric>
          <FormContainer>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <DocumentContainer>
                  <div>
                    <Label>Correo</Label>
                    <Input showCount={false} name="user" placeholder="Correo" />
                  </div>
                </DocumentContainer>
                <DocumentContainer>
                  <div className="mb-3">
                    <Label>Contraseña</Label>
                    <Input
                      name="password"
                      placeholder="Contraseña"
                      type="password"
                    />
                  </div>
                </DocumentContainer>
                <AceptacionContainer>
                  <AceptacionDatosPersonales
                    nameAceptacionDatosPersonales="aceptaDatosPersonales"
                    nameTratamiento="aceptaTratamiento"
                  ></AceptacionDatosPersonales>
                </AceptacionContainer>
                <ButtonContainer>
                  <Button
                    label="Ingresar"
                    type="submit"
                    className="bg-purple-heart-500 text-white disabled:opacity-50 disabled:bg-pigeon-post-400 disabled:text-chicago-500"
                    disabled={!isValid}
                  />
                </ButtonContainer>
              </form>
              <div className="text-center mt-3">
                ¿No tienes una cuenta?{" "}
                <RedirectLoginAnchor href="/micuenta/registro">
                  Registrate
                </RedirectLoginAnchor>
              </div>
              <OlvidoContrasenaContainer>
                <div 
                  className="text-tory-blue-500 font-black underline"
                  onClick={handleOpenRecoverPassword}
                  data-testid="recovered-password"
                >
                  Recuperar contraseña
                </div>
              </OlvidoContrasenaContainer>
            </FormProvider>
          </FormContainer>
        </LoginContainer>
      </LoginWrapper>
    </React.Fragment>
  );
};

export default LoginMiCuentaPage;
