import moment from 'moment'

export const normalizeIPN = (clientAnswer, nroDocumento, tipoDocumento) => {
    return {
        tipoDocumento: tipoDocumento,
        nroDocumento: nroDocumento,
        krAnswer: clientAnswer
    }
}

export const normalizeReceipt2 = (clientAnswer, item, user) => {
    return {
        tipoDocumento: user.tipoDocumento,
        nroDocumento: user.nRODOCUMENTOField,
        email: user.eMAILField,
        nombreCompleto: user.cLIENTEField,
        documento: item.bELNRField,
        tipoPago: `${item.bLARTField === 'R1' ? 'Separación de lote' : 'Pago Inicial'}`,
        proyecto: `${item.pROYECTOField} - ${item.pRODUCTOField}`,
        fechaVencimiento: moment(item.fECHAVENCIMIENTOField).format('DD/MM/YYYY'),
        montoCancelado: formatCurrency(clientAnswer.transactions[0]?.currency, clientAnswer.transactions[0]?.amount / 100),
        nroTarjeta: `${clientAnswer?.transactions[0]?.transactionDetails.cardDetails.pan.replace('XXXXXX', '******')}`,
        nroOperacion: clientAnswer?.orderDetails.orderId,
        fechaPago: moment(clientAnswer.transactions[0]?.transactionDetails.cardDetails.authorizationResponse.authorizationDate).format('DD/MM/YYYY'),
        horaPago: moment(clientAnswer.transactions[0]?.transactionDetails.cardDetails.authorizationResponse.authorizationDate).format('HH:mm A')
    }
}

export const normalizeReceipt = (item, user) => {
    return {
        tipoDocumento: user.tipoDocumento,
        nroDocumento: user.nRODOCUMENTOField,
        email: user.eMAILField,
        nombreCompleto: user.cLIENTEField,
        documento: item.bELNRField,
        tipoPago: `${item.bLARTField === 'R1' ? 'Separación de lote' : 'Pago Inicial'}`,
        proyecto: `${item.pROYECTOField} - ${item.pRODUCTOField}`,
        fechaVencimiento: moment(item.fECHAVENCIMIENTOField).format('DD/MM/YYYY'),
        montoCancelado: formatCurrency(item.MonedaPago, item.ImportePago),
        nroTarjeta: `${item.TarjetaPagoIzi.replace('XXXXXX', '******')}`,
        nroOperacion: item.NroAutorizacionIzi,
        fechaPago: moment(item.FechaOperacionIzi).format('DD/MM/YYYY'),
        horaPago: moment(item.FechaOperacionIzi).format('HH:mm A')
    }
}

const formatCurrency = (symbol, money) => {
    return new Intl.NumberFormat('es-PE', 
        { style: 'currency', currency: symbol }
    ).format(money)
}