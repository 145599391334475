import React from 'react'
import Slider from 'react-slick'
import Carousel, { Modal, ModalGateway } from 'react-images'
import styled from 'styled-components'

import Collage from '../Collage'

import useMedia from '../../hooks/useMedia'

interface IGallery {
    width?: Array<string>;
    height?: string;
    layout: Array<number>;
    photos: any;
    showRemaining?: boolean
}

const Slick = styled(Slider)`
    & .slick-dots {
        bottom: 15px;
        & li {
            margin: 0px 2px;
            &.slick-active {
                & button {
                    &::before {
                        color: #F6A41A;
                    }
                }
            }
            & button {
                padding: 0px;
                &::before {
                    color: #FFFFFF;
                    opacity: 1;
                    font-size: 9px;
                }
            }
        }
    }
`

const initializeProps = (props: IGallery) => {
    const defaultProps = {
        width: new Array(props.layout.length),
        layout: [],
        photos: [],
        showRemaining: false
    }
    const newProps = { ...defaultProps, ...props };
    if (newProps.width.length < newProps.layout.length) {
        for (let i = 0; i < newProps.layout.length; i++) {
            newProps.width[i] = '200px';
        }
    }
    return newProps;
}

const createPhotoIds = (photos) => {
    return photos.map((data, i) => {
        return { ...data, id: i }
    });
}

const createLayoutPhotoMaps = (layout, photos) => {
    const newPhotos = createPhotoIds(photos);
    const newMaps = {};
    layout.reduce((accumulator, currentValue, currentIndex) => {
        newMaps[currentIndex] = newPhotos.slice(accumulator, accumulator + currentValue);
        return accumulator + currentValue
    }, 0);

    return newMaps

}

const Gallery = (props: IGallery) => {
    const { isMobile } = useMedia()
    const current = initializeProps(props)
    const { width, height, layout, photos, showRemaining } = current
    const layoutNum = layout.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    const remainingNum = photos.length - layoutNum
    const [allowRender, setAllowRender] = React.useState<boolean>(false)
    const [layoutPhotoMaps, setLayoutPhotoMaps] = React.useState<any>({})
    const [isOpen, setIsOpen] = React.useState<boolean>(false)
    const [currentImage, setCurrentImage] = React.useState<number>(0)

    React.useEffect(() => {
        setLayoutPhotoMaps(createLayoutPhotoMaps(layout, photos))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        Object.keys(layoutPhotoMaps).length ? setAllowRender(true) : setAllowRender(false);
    }, [layoutPhotoMaps]);

    const openLightbox = React.useCallback((id) => {
        setCurrentImage(parseInt(id));
        setIsOpen(true);
    }, [])

    const closeLightbox = React.useCallback(() => {
        setCurrentImage(0);
        setIsOpen(false);
    }, []);

    if (!allowRender) return null

    return (
        <React.Fragment>
            {isMobile ? (
                <React.Fragment>
                    <Slick
                        dots={true}
                        slidesToShow={1}
                        slidesToScroll={1}
                        arrows={false}
                    >
                        {photos.map((item, i) => (
                            <div style={{ cursor: 'pointer' }} onClick={() => openLightbox(i)}>
                                <img
                                    alt=''
                                    width='100%'
                                    src={item.source}
                                    style={{ borderRadius: '8px' }}
                                />
                            </div>
                        ))}
                    </Slick>
                    <ModalGateway>
                        {
                            isOpen ? (
                                <Modal onClose={closeLightbox}>
                                    <Carousel views={photos} currentIndex={currentImage} />
                                </Modal>
                            ) : null
                        }
                    </ModalGateway>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Collage
                        width={width}
                        height={height}
                        layout={layout}
                        layoutPhotoMaps={layoutPhotoMaps}
                        layoutNum={layoutNum}
                        remainingNum={remainingNum}
                        showRemaining={showRemaining}
                        openLightbox={openLightbox}
                    />
                    <ModalGateway>
                        {
                            isOpen ? (
                                <Modal onClose={closeLightbox}>
                                    <Carousel views={photos} currentIndex={currentImage} modalProps={{ allowFullscreen: false }} />
                                </Modal>
                            ) : null
                        }
                    </ModalGateway>
                </React.Fragment>
            )}
        </React.Fragment >

    )
}

export default Gallery