import React from 'react'
import { useNavigate } from 'react-router-dom';
import NumberFormat from 'react-number-format'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { schema } from './validations'

import { RootState } from '../../../store/modules'

import Checkbox from '../../../components/Checkbox'

import { mediaQuery as media } from '../../../utils/mediaQuery'

const ConfirmationWrapper = styled.div``

const ConfirmationContainer = styled.div`
    width: 100%;
    max-width: 564px;
    margin: 0px auto;
    box-sizing: border-box;
    padding: 40px 16px 80px;
`

const TitleContainer = styled.div`
    padding-bottom: 24px;
`

const Title = styled.div`
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #36393D;
    text-align: center;
    ${media.md`
        font-size: 24px;
        line-height: 32px;
    `}
`

const DetailContainer = styled.div`
    background: #FFFFFF;
    /* box-shadow: 0px 8px 24px rgba(40, 79, 171, 0.1); */
    border-radius: 8px;
    padding: 12px 12px 24px;
    margin-bottom: 24px;
    ${media.md`
        padding: 32px 24px 24px;
    `}
`

const InfoContainer = styled.div`
    padding-bottom: 12px;
    border-bottom: 1px solid #BECAE6;
    &:not(:first-child) {
        padding-top: 12px;
    }
    ${media.sm`
        display: flex;
        justify-content: space-between;
        padding-bottom: 8px;
        &:not(:first-child) {
            padding-top: 12px;
        }
    `}
`

const LabelContainer = styled.div``

const Label = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #36393D;
    margin-bottom: 4px;
    ${media.md`
        font-size: 16px;
        line-height: 24px;
    `}
`

const ValueContainer = styled.div``

const Value = styled.div`
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: #36393D;
    text-transform: capitalize;
    ${media.md`
        font-size: 16px;
        line-height: 24px;
    `}
`

const MessageContainer = styled.div`
    padding: 8px 12px;
    background: #E9EDF7;
    border-radius: 2px;
    border-left: 4px solid #4074FE;
`

const Message = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #36393D;
`

const TermsContainer = styled.div`
    padding: 19px 12px;
    background: #FFFFFF;
    /* box-shadow: 0px 8px 24px rgba(40, 79, 171, 0.1); */
    border-radius: 8px;
    margin-top: 24px;
    ${media.md`
        padding: 24px;
    `}
`

const CheckboxContainer = styled.div``

const ButtonContainer = styled.div`
    margin-top: 40px;
    ${media.md`
        max-width: 364px;
        margin: 0px auto;
        margin-top: 48px;
    `}
`

const Submit = styled.button`
    width: 100%;
    border: none;
    border: 1px solid #CC26BA;
    background: #CC26BA;
    border-radius: 4px;
    font-family: Nunito, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    padding: 12px 0px;
    cursor: pointer;
    &:disabled {
        color: #82949E;
        background: #E6EAEC;
        border: 1px solid #82949E;
    }
`

const Button = styled.button`
    width: 100%;
    border: 1px solid #36393D;
    border-radius: 4px;
    font-family: Nunito, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #36393D;
    padding: 12px 0px;
    margin-top: 16px;
    cursor: pointer;
`
const Link = styled.a`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #00C9C9;
  text-decoration: underline;
  cursor: pointer;
  ${media.md`
    font-size: 16px;
    line-height: 24px;
  `}
`

const ConfirmationPage = () => {
    const history = useNavigate()
    const nombreProyecto = useSelector((state: RootState) => state.debit.nombreProyecto)
    const codMoneda = useSelector((state: RootState) => state.debit.codMoneda)
    const proforma = useSelector((state: RootState) => state.debit.proforma)
    const montoMaximo = useSelector((state: RootState) => state.debit.montoCuotaAprox)

    const methods = useForm({
        mode: 'onChange',
        reValidateMode: 'onSubmit',
        resolver: yupResolver(schema())
    })
    const { handleSubmit, formState: { isValid } } = methods

    const onSubmit = () => {
        history(`/debit/${proforma}/payment`)
    }

    const handleGoBack = () => {
        history(-1)
    }

    return (
        <React.Fragment>
            <ConfirmationWrapper>
                <ConfirmationContainer>
                    <FormProvider {...methods}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <TitleContainer>
                                <Title>Revisa los detalles del proyecto para afiliar</Title>
                            </TitleContainer>
                            <DetailContainer>
                                <InfoContainer>
                                    <LabelContainer>
                                        <Label>Proyecto</Label>
                                    </LabelContainer>
                                    <ValueContainer>
                                        <Value>{nombreProyecto.toLowerCase()}</Value>
                                    </ValueContainer>
                                </InfoContainer>
                                <InfoContainer>
                                    <LabelContainer>
                                        <Label>Proforma</Label>
                                    </LabelContainer>
                                    <ValueContainer>
                                        <Value>{proforma}</Value>
                                    </ValueContainer>
                                </InfoContainer>
                                <InfoContainer>
                                    <LabelContainer>
                                        <Label>Importa a debitar mensual</Label>
                                    </LabelContainer>
                                    <ValueContainer>
                                        <Value>
                                            <NumberFormat
                                                prefix={codMoneda === 'USD' ? 'US$' : 'S/'}
                                                decimalSeparator='.'
                                                displayType='text'
                                                type='text'
                                                thousandSeparator={true}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                value={montoMaximo}
                                            />
                                        </Value>
                                    </ValueContainer>
                                </InfoContainer>
                            </DetailContainer>
                            <MessageContainer>
                                <Message>El importe será debitado automáticamente el día 24 de cada mes previo al vencimiento de la cuota</Message>
                            </MessageContainer>
                            <TermsContainer>
                                <CheckboxContainer>
                                    <Checkbox name='terms'>
                                        Acepto las <Link href='https://prdunvblobstorage.blob.core.windows.net/vivienda/sistemas/micuenta/micuenta.politica.privacidad.pdf' target='_blank'>Políticas de Privacidad</Link> y <Link href='https://prdunvblobstorage.blob.core.windows.net/vivienda/sistemas/micuenta/micuenta.terminos.condiciones.pdf' target='_blank'>Términos y condiciones</Link>
                                    </Checkbox>
                                </CheckboxContainer>
                            </TermsContainer>
                            <ButtonContainer>
                                <Submit
                                    type='submit'
                                    disabled={!isValid}
                                >
                                    Continuar afiliación
                                </Submit>
                                <Button type='button' onClick={handleGoBack}>Regresar</Button>
                            </ButtonContainer>
                        </form>
                    </FormProvider>
                </ConfirmationContainer>
            </ConfirmationWrapper>
        </React.Fragment>
    )
}

export default ConfirmationPage