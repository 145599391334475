import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import KRGlue from '@lyracom/embedded-form-glue'

import { fetchHideLoading, fetchShowLoading } from '../../../../store/modules/layout/actions'

import { izipayServices } from '../../../../services/izipayServices'

import { mediaQuery as media } from '../../../../utils/mediaQuery'
import { normalizeIzipay } from '../../../../utils/schemas/checkout'
import { URL_STATIC, APIKEY_AD } from '../../../../utils/constants/checkout'

interface IProps {
    document: any
    user: any
    onPayment: (e) => void
    onReturn: () => void
}

const PaymentWrapper = styled.div``

const PaymentContainer = styled.div`
    width: 100%;
    max-width: fit-content;
    margin: 0px auto;
    box-sizing: border-box;
    padding: 40px 16px 0px;
`

const TitleContainer = styled.div`
    padding-bottom: 24px;
`

const Title = styled.div`
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #36393D;
    text-align: center;
    ${media.md`
        font-size: 24px;
        line-height: 32px;
    `}
`

const ButtonContainer = styled.div``

const Button = styled.button`
    width: 100%;
    border: 1px solid #36393D;
    border-radius: 4px;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #36393D;
    padding: 12px 0px;
    margin-top: 16px;
    cursor: pointer;
`

const DetailContainer = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 8px 24px rgba(40, 79, 171, 0.1);
    border-radius: 8px;
    padding: 12px 12px 32px;
    ${media.md`
        padding: 32px 24px 44px;
    `}
`

const FormContainer = styled.div`
    & .kr-payment-button {
        border: none;
        border: 1px solid #CC26BA !important;
        background: #CC26BA !important;
        border-radius: 4px;
        padding: 12px 0px;
        & span {
            width: 100%;
            font-family: 'Nunito', sans-serif !important;
            font-style: normal;
            font-weight: 800;
            font-size: 16px;
            line-height: 24px;
            color: #FFFFFF;
            cursor: pointer;
            text-transform: capitalize;
        }
    }
`

const PaymentPage = ({
    user,
    document,
    onReturn,
    onPayment
}: IProps) => {
    const dispatch = useDispatch()

    React.useEffect(() => {
        const link  = window.document.createElement('link')
        link.type = 'text/css'
        link.rel = 'stylesheet'
        link.href = 'https://static.micuentaweb.pe/static/js/krypton-client/V4.0/ext/classic-reset.css'
        const script = window.document.createElement('script')
        script.src = 'https://static.micuentaweb.pe/static/js/krypton-client/V4.0/ext/classic.js'
        window.document.body.appendChild(link)
        window.document.body.appendChild(script)

        return () => {
            window.document.body.removeChild(link)    
            window.document.body.removeChild(script)    
        }
    }, [])

    React.useEffect(() => {
        dispatch(fetchShowLoading())
        izipayServices.generateToken(normalizeIzipay(user, document)).then(response => {
            const { answer } = response.data
            const { formToken } = answer
            initializeForm(formToken)
            dispatch(fetchHideLoading())
        }).catch(_ => {
            dispatch(fetchHideLoading())
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const initializeForm = async (formToken) => {
        await KRGlue.loadLibrary(URL_STATIC, APIKEY_AD)
            .then(({ KR }) => KR.setFormConfig({
                formToken: formToken
            }))
            .then(({ KR }) => KR.addForm('#myPaymentForm'))
            .then(({ KR, result }) => KR.showForm(result.formId))
            .then(({ KR }) => {
                return KR.onFormCreated(() => {
                    let register = window.document.querySelectorAll<HTMLElement>('.kr-do-register')[0]
                    register.style.display = 'none'
                });
            })
            .then(({ KR }) => {
                return KR.onFormReady(() => {
                    dispatch(fetchHideLoading())
                });
            })
            .then(({ KR }) => {
                return KR.onSubmit((response: any) => {
                    onPayment(response.clientAnswer)
                });
            })
            .catch(_ => dispatch(fetchHideLoading()))
    }

    return (
        <React.Fragment>
            <PaymentWrapper>
                <PaymentContainer>
                    <TitleContainer>
                        <Title>Realiza tu pago</Title>
                    </TitleContainer>
                    <DetailContainer>
                        <FormContainer id="myPaymentForm" />
                        <ButtonContainer>
                            <Button onClick={onReturn}>Regresar</Button>
                        </ButtonContainer>
                    </DetailContainer>
                </PaymentContainer>
            </PaymentWrapper>
        </React.Fragment>
    )
}

export default PaymentPage