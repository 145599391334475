import React from "react";
import styled from "styled-components";
import { mediaQuery as media } from "../../utils/mediaQuery";
import { FormProvider, useForm } from "react-hook-form";
import Input from "../../components/Input";
import Button from "../../components/Button";
import AceptacionDatosPersonales from "../../components/AceptacionDatosPersonales";
import { schema } from "./validations";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
import Alert from "../../components/Alert";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchHideLoading,
  fetchShowLoading,
} from "../../store/modules/layout/actions";
import { authServices } from "../../services/authServices";
import { useNavigate } from "react-router-dom";
import {
  fetchCountryCodeLogin,
  fetchDocumentTypeLogin,
  fetchErrorLogin,
  fetchLoadCountryCodeLogin,
} from "../../store/modules/login/actions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import successIcon from "../../assets/images/svg/ic-success-green.svg";
import arrow from "../../assets/images/arrow-down-purple.png";
import errorIcon from "../../assets/images/svg/ic-error-red.svg";
import Select from "../../components/Select";
import documentType from "../../assets/json/documentType.json";
import { RootState } from "../../store/modules";
import { normalizeCountry } from "../../utils/schemas/login";
import { CODE_PER, PAGINA_LOGIN_MICUENTA_DEPARTAMENTO } from "../../utils/constants/login";

const MySwal = withReactContent(Swal);

const LoginWrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background: #ffffff;
  padding: 20px 0px;
  ${media.lg`
        min-height: auto;
    `}
`;

const LoginContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  -webkit-box-shadow: 0px 0px 2px 0px #000000;
  box-shadow: 0px 0px 2px 0px #000000;
  box-sizing: border-box;
  padding: 24px 16px 42px 16px;
  ${media.sm`
        padding: 24px 36px;
        max-width: 436px;
        border-radius: 8px;
    `}
`;

const ErrorContainer = styled.div`
  width: 100%;
  padding: 0px 16px;
  margin-top: 16px;
  box-sizing: border-box;
  ${media.sm`
        margin-top: 0px;
        margin-bottom: 16px;
        padding: 0px;
        max-width: 436px;
    `}
`;

const TitleContainer = styled.div`
  text-align: center;
`;

const Title = styled.h2`
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  color: #284fab;
  margin: 0px;
`;
const FormContainer = styled.div`
  margin-top: 24px;
`;

const DocumentContainer = styled.div``;

const ButtonContainer = styled.div`
  margin-top: 18px;
`;

const Label = styled.label`
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  display: block;
  margin-bottom: 7px;
`;

const AceptacionContainer = styled.div`
  color: #fff;
  font-size: 12px;
`;

const InputGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, auto);
  row-gap: 24px;

  ${media.sm`
        grid-template-columns: 132px 216px;
        column-gap: 16px;
        grid-template-rows: auto;
        box-sizing: border-box;
    `}
`;

const RedirectLoginAnchor = styled.a`
  color: rgb(90, 133, 196);
  font-weight: bold;
`

const TelephoneContainer = styled.div``;

const Error = ({ errors, name }) => (
  <ErrorMessage
    errors={errors}
    name={name}
    render={({ message }) => (
      <ErrorContainer>
        <Alert type="error" message={message} />
      </ErrorContainer>
    )}
  />
);

const RegisterPage = () => {
  const documentSelected = useSelector(
    (state: RootState) => state.login.document
  );
  const listCountryLoadBD = useSelector(
    (state: RootState) => state.login.listCountry
  );
  const countrySelected = useSelector(
    (state: RootState) => state.login.country
  );

  const dispatch = useDispatch();
  const history = useNavigate();

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const {
    getValues,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
  } = methods;

  const changeDocumentType = (e) => {
    const filter = documentType.find((item) => item.value === e);
    dispatch(fetchDocumentTypeLogin(filter));
    setValue(
      "nroDocumento",
      getValues("nroDocumento").substr(0, filter?.maxLength)
    );
  };

  const changeCountry = (e) => {
    dispatch(fetchCountryCodeLogin(e));
  };

  const onSubmit = (data) => {
    const dataForRegister = {
      ...data,
      numeroDocumento: data.nroDocumento,
      idTipoDocumento: data.tipoDocumento,
      origen: PAGINA_LOGIN_MICUENTA_DEPARTAMENTO,
      telefono: data.countryCode + data.nroCellphone,
      aceptaComunicaciones: data.aceptaComunicaciones,
      aceptaTratamiento: data.aceptaTratamiento,
      apellidoPaterno: data.apellidoPaterno,
      apellidoMaterno: data.apellidoMaterno
    };

    dispatch(fetchShowLoading());
    authServices
      .registerUser(dataForRegister)
      .then((response) => {
        dispatch(fetchHideLoading());

        MySwal.fire({
          title: <strong>Usuario creado con éxito</strong>,
          html: <i>{response.data.response}</i>,
          iconHtml: <img src={successIcon} alt="Success Icon" />,
        });
        history("/login");
      })
      .catch((e) => {
        dispatch(fetchHideLoading());
        MySwal.fire({
          title: <strong>No se pudo registrar al usuario</strong>,
          html: <i>{e.response.data.title}</i>,
          iconHtml: <img src={errorIcon} alt="Error Icon" />,
        });
      });
  };

  React.useEffect(() => {
    dispatch(fetchShowLoading());
    authServices
      .getCountry()
      .then((response) => {
        const optionList = response.data;
        dispatch(fetchLoadCountryCodeLogin(optionList));
        dispatch(fetchHideLoading());
      })
      .catch((_) => {
        dispatch(fetchHideLoading());
        dispatch(
          fetchErrorLogin({ error: true, errorMessage: "Datos no encontrados" })
        );
      });
  }, []);

  return (
    <React.Fragment>
      <LoginWrapper>
        <LoginContainer>
          <TitleContainer>
            <Title className="text-3xl font-bold">¡Regístrate!</Title>
          </TitleContainer>
          <Error errors={errors} name="nroCellphone" />
          <Error errors={errors} name="nroDocumento" />
          <Error errors={errors} name="nombres" />
          <Error errors={errors} name="apellidoPaterno" />
          <Error errors={errors} name="apellidoMaterno" />
          <Error errors={errors} name="correo" />
          <Error errors={errors} name="clave" />
          <Error errors={errors} name="confirmarClave" />
          <FormContainer>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <TelephoneContainer className="mb-2">
                  <Label>Número de celular</Label>
                  <InputGroup>
                    <div>
                      <Select
                        icon={arrow}
                        name="countryCode"
                        onChange={changeCountry}
                        options={normalizeCountry(listCountryLoadBD)}
                        defaultValue={countrySelected.dial_code}
                      />
                    </div>
                    <Input
                      name="nroCellphone"
                      placeholder="000 000 000"
                      showCount={countrySelected.dial_code === CODE_PER}
                      {...(countrySelected.dial_code === CODE_PER ? { maxLength: 9} : { maxLength: 13 })}
                    />
                  </InputGroup>
                </TelephoneContainer>
                <DocumentContainer>
                  <InputGroup>
                    <div>
                      <Label>Tipo de documento</Label>
                      <div>
                        <Select
                          icon={arrow}
                          label={{
                            name: "labelDocumento",
                            value: documentSelected.abrev,
                          }}
                          name="tipoDocumento"
                          options={documentType}
                          onChange={changeDocumentType}
                          defaultValue={documentSelected.value}
                        />
                      </div>
                    </div>
                    <div>
                      <Label>Número de documento</Label>
                      <Input
                        showCount={true}
                        name="nroDocumento"
                        placeholder="Nro de documento"
                        maxLength={documentSelected.maxLength}
                      />
                    </div>
                  </InputGroup>
                </DocumentContainer>
                <DocumentContainer>
                  <div className="mb-3">
                    <Label>Nombres</Label>
                    <Input
                      showCount={false}
                      name="nombres"
                      placeholder="Nombre completo"
                    />
                  </div>
                </DocumentContainer>
                <DocumentContainer>
                  <div className="mb-3">
                    <Label>Apellido paterno</Label>
                    <Input
                      showCount={false}
                      name="apellidoPaterno"
                      placeholder="Apellido paterno"
                    />
                  </div>
                </DocumentContainer>
                <DocumentContainer>
                  <div className="mb-3">
                    <Label>Apellido materno</Label>
                    <Input
                      showCount={false}
                      name="apellidoMaterno"
                      placeholder="Apellido materno"
                    />
                  </div>
                </DocumentContainer>
                <DocumentContainer>
                  <div className="mb-3">
                    <Label>Correo</Label>
                    <Input
                      showCount={false}
                      name="correo"
                      placeholder="Correo"
                    />
                  </div>
                </DocumentContainer>
                <DocumentContainer>
                  <div className="mb-3">
                    <Label>Contraseña</Label>
                    <Input
                      showCount={false}
                      name="clave"
                      placeholder="Contraseña"
                      type="password"
                    />
                  </div>
                </DocumentContainer>
                <DocumentContainer>
                  <div className="mb-3">
                    <Label>Repite tu contraseña</Label>
                    <Input
                      showCount={false}
                      name="confirmarClave"
                      placeholder="Contraseña"
                      type="password"
                    />
                  </div>
                </DocumentContainer>
                <AceptacionContainer>
                  <AceptacionDatosPersonales
                    nameAceptacionDatosPersonales="aceptaDatosPersonales"
                    nameTratamiento="aceptaTratamiento"
                  ></AceptacionDatosPersonales>
                </AceptacionContainer>
                <ButtonContainer>
                  <Button
                    label="Registrate"
                    type="submit"
                    className="bg-purple-heart-500 text-white disabled:opacity-50 disabled:bg-pigeon-post-400 disabled:text-chicago-500"
                    disabled={!isValid}
                  />
                </ButtonContainer>
              </form>
              <div className="text-center mt-3">¿Tienes una cuenta? Ir a <RedirectLoginAnchor href="/login">{" "}Iniciar sesión</RedirectLoginAnchor></div>
            </FormProvider>
          </FormContainer>
        </LoginContainer>
      </LoginWrapper>
    </React.Fragment>
  );
};

export default RegisterPage;
