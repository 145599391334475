import { push } from '@lagunovsky/redux-react-router'
import {
    ERROR_LOGIN,
    LOCATION_LOGIN_REQUESTED,
    fetchLocationLoginSuccess,
    DOCUMENTTYPE_LOGIN_REQUESTED,
    COUNTRYCODE_LOGIN_REQUESTED,
    fetchCountryCodeLoginSuccess,
    AVAILABLE_LOGOUT_REQUESTED
} from '../modules/login/actions'
import { call, put, take, takeLatest, all } from 'redux-saga/effects'

import { googleServices } from '../../services/googleServices'
import { getCountryCode, getCountryDial } from '../../utils/schemas/login'

import country from '../../assets/json/country.json'

const isBrowser = () => typeof window !== 'undefined'
const login = isBrowser() && window.localStorage.getItem('login')

export function* getLocation(action) {
    try {
        const response = yield call(googleServices.getLocation, action.payload)
        yield put(fetchLocationLoginSuccess(getCountryCode(country, response.data)))
    } catch (error) {
        console.log(error)
    }
}

export function* getCountry(action) {
    try {
        yield put(fetchCountryCodeLoginSuccess(getCountryDial(country, action.payload)))
    } catch (error) {
        console.log(error)
    }
}

export function* logout() {
    try {
        yield put(push(`/login?type=${login}`))
    } catch (error) {
        console.log(error)
    }
}

export default function* loginWatcherSaga() {
    yield all([
        take(ERROR_LOGIN),
        take(DOCUMENTTYPE_LOGIN_REQUESTED),
        yield takeLatest(AVAILABLE_LOGOUT_REQUESTED, logout),
        yield takeLatest(COUNTRYCODE_LOGIN_REQUESTED, getCountry),
        yield takeLatest(LOCATION_LOGIN_REQUESTED, getLocation),
    ])
}